import './ProductDetails.css';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Related from '../../Components/RelatedItems/RelatedItems';
import Skeleton from '@mui/material/Skeleton';
import ImageGallery from 'react-image-gallery';
import { useHistory, useParams } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMinusSm } from "react-icons/hi";
import {
    addToCart, adjustItemQty, refresh
} from "../../redux/Shopping/shopping-actions";
import MuiAlert from '@mui/material/Alert';
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import { FiCheck } from "react-icons/fi";
import img from '../../Assets/Images/noimages.png';
import Loader from '../../Components/Loader/Loader';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ProductDetails({ addToCart, cart, item, adjustQty, refresh }) {
    console.log("ADD TO CART", addToCart)
    console.log(cart, "cart product details1")


    console.log(cart, "cart peoductdetails1")
    let history = useHistory();
    const { id, batch } = useParams();
    const [open, setOpen] = React.useState(false);

    const [cartList, setCartList] = useState([])
    const [comboCartList, setComboCartList] = useState([]);

    const [value, setValue] = React.useState('1');
    const [relatedLists, setRelatedlists] = useState([])
    const [sliderloading, setSliderloading] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const [brand, setBrand] = useState('');
    const [productName, setProductName] = useState('');
    const [modalCode, setModalCode] = useState('');
    const [sellingPrice, setSellingPrice] = useState('');
    const [actuallPrice, setActualPrice] = useState('');
    const [imageList, setImagelist] = useState([]);
    const [imageList1, setImagelist1] = useState('');

    const [img1, setImg1] = useState([]);
    const [productId, setProductId] = useState('');
    const [sizeLists, setSizelist] = useState([]);
    const [color, setColor] = useState();
    const [getColor, setGetcolor] = useState('');
    const [prodctId1, setProductId1] = useState('');
    const [batch1, setBatch1] = useState();
    const [loader, setLoader] = useState(false);
    const [batchRelate, setBatchRelate] = useState('');
    const [productColor, setProductColor] = useState('');
    const [input, setInput] = useState(1);
    const [stock, setStock] = useState('0');
    const [currency, setCurrency] = useState('');
    const [data1, setData1] = useState({});
    const [batchids, setBatchid] = useState();
    const [logtoken, setLogtoken] = useState('');
    const [cartView, setCartview] = React.useState(false)
    const [message, setMessage] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [details, setDetails] = useState({});
    const [cartQty, setCartQty] = useState('');
    const [colorlist, setColorlist] = useState([]);
    const [total, setTotal] = useState(0);

    // useEffect(()=>{
    //     cart.forEach((item)=>{
    //         const cartdup = Array.isArray(item.id)?item.id[0]:item.id

    //     })
    // })


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
        setLogtoken(logged)
        // getCartData()
    }, [])

    useEffect(() => {
        //    console.log("decodeURIComponent", atob(id, batch).replace("productid","batchid"));
        console.log("change")
        getData();
        getRelatedProduct();

    }, [batch1, prodctId1])

    useEffect(() => {
        setProductId1(id)
        setBatch1(batch)
    }, [id, batch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const contHome = () => {
        history.push('/')
        setCartview(false)
    }
    const closeviewCart = () => {
        setCartview(false)
    }

    const viewCart = () => {
        setCartview(true)
    }

    const getData = () => {
        setLoader(true)
        if (logtoken == true) {
            var user = JSON.parse(localStorage.getItem("userdata"))
            console.log('user', user)
            var data = {
                'product_id': prodctId1,
                'batch_id': batch1,
                'user_id': user.user_id
            }
            console.log("getData", data)
            fetch(global.url + "viewProductLatest", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((responseJson) => {
                    console.log('productdetails 121', responseJson)
                    if (responseJson.error == false) {
                        setLoader(false)
                        setDetails(responseJson.data.details)
                        console.log(responseJson.data.details, "respo 154154")
                        setSizelist(responseJson.data.details.size)
                        setProductId(responseJson.data.details.product_description);
                        setColorlist(responseJson.data.colors)
                        console.log(responseJson.data.details.image, "15336");
                        if (responseJson.data.details.image) {
                            setImagelist(responseJson.data.details.image)
                            // setImagelist1(responseJson.data.details.images[0].pei_image)
                            // console.log(responseJson.data.details.image, "15336");
                        } else {
                            setImagelist([])
                        }
                    } else {
                        setLoader(false);

                    }

                })
                .catch((error) => {
                    console.log(error, "errror");
                })
        } else {
            var data = {
                'product_id': prodctId1,
                'batch_id': batch1,
            }
            console.log("getData", data)
            fetch(global.url + "viewProductLatest", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
                body: JSON.stringify(data)
            }).then((response) => response.json())
                .then((responseJson) => {
                    console.log('productdetails 123', responseJson)
                    if (responseJson.error == false) {
                        setLoader(false)
                        setDetails(responseJson.data.details)
                        console.log(responseJson.data.details,"respo details")
                        setSizelist(responseJson.data.details.size)
                        setColorlist(responseJson.data.colors)
                        // console.log(responseJson.data.details.images, "15337");
                        // console.log(responseJson.data.details.images[0].pei_image, "15338");

                        if (responseJson.data.details.image) {
                            // console.log("true condition ")

                            setImagelist(responseJson.data.details.image)
                            // setImagelist1(responseJson.data.details.images[0].pei_image)


                            // console.log(responseJson.data.details.images[0].pei_image, "15339");

                        } else {
                            console.log("false condition ")

                            setImagelist([])
                        }
                    } else {
                        setLoader(false);
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }



    // const images1 = [
    //     {
    //         original: global.images +key1+ "/ecommerce_photos/" + imageList1,
    //         thumbnail: global.images +key1+ "/ecommerce_photos/" + imageList1,
    //     },
    // ];
    // console.log(images1, "image1")
    // console.log(imageList1, "1223312")






    // const getCartData = () => {
    //     var token = JSON.parse(localStorage.getItem("token"))
    //     fetch(global.url + "displayCartfrontend", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'APPKEY': 'erp6129',
    //             'Authorization': 'Bearer ' + token
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             console.log("responseJson cart123", responseJson)
    //             if (responseJson.error == false) {
    //                 setCartList(responseJson.singleCart);
    //                 console.log(responseJson.singleCart, "single cart");
    //                 setComboCartList(responseJson.ComboCart);
    //                 console.log(responseJson.ComboCart, "combo cart");
    //                 setCartQty(responseJson.cart_count);
    //                 setCartTotal(responseJson.total);
    //             } else {
    //                 setCartList([])
    //                 setComboCartList([])
    //                 setCartQty(0)
    //                 setCartTotal(0)
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    const getCartData = async () => {
        try {
            var token = JSON.parse(localStorage.getItem("token"));

            const response = await fetch(global.url + "displayCartfrontend", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129',
                    'Authorization': 'Bearer ' + token
                },
            });

            const responseJson = await response.json();

            console.log("responseJson cart123", responseJson);

            if (responseJson.error === false) {
                setCartList(responseJson.singleCart);
                console.log(responseJson.singleCart, "single cart");
                setComboCartList(responseJson.ComboCart);
                console.log(responseJson.ComboCart, "combo cart");
                setCartQty(responseJson.cart_count);
                setCartTotal(responseJson.total);
            } else {
                setCartList([]);
                setComboCartList([]);
                setCartQty(0);
                setCartTotal(0);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const getRelatedProduct = () => {
        setSliderloading(true)
        var data = {
            "product_id": prodctId1,
            "batch_id": batch1,
            "limit": 12
        }
        fetch(global.url + "viewRelatedProducts", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log('related', responseJson)
                if (responseJson.error == false) {
                    setSliderloading(false)
                    setRelatedlists(responseJson.data)
                }
                else {
                    setSliderloading(false)
                    setRelatedlists([])
                }
            })
    }

    const colorChange = (event) => {
        setBatch1(event.target.value);
        var data = {
            'batch_id': parseInt(event.target.value)
        }
        fetch(global.url + "viewProductbySize", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.error == false) {
                    var data = responseJson.data[0];
                    var batch = data.batchid
                    setBatchid(batch)
                    setData1(data[0])
                    setBatch1(data.pb_id)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const handleIncre = () => {
        if (input >= details.pb_stock) {
            console.log("ifinput", input)
        }
        else {
            setInput(prevCount => prevCount + 1)
        }
    }

    const handleDecre = () => {
        if (input > 1) {
            setInput(prevCount => prevCount - 1)
        }
    }

    const toClick = (data1) => {
        addToCart(data1)
        adjustQty(batchids, input);
        setMessage("Added to Cart")
        setOpen(true)
        viewCart()
    }

    const AddCart = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "productid": batch1,
            "quantity": input,
            "iscombo": 0
        }
        fetch(global.url + "addCartfrontend", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.error == false) {
                    setOpen(true)
                    setMessage("Added To Cart")
                    refresh("")
                    viewCart()
                    getCartData()
                } else {
                    refresh("")
                    setOpen(true)
                    setMessage(responseJson.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        let count = 0;
        var total = 0;
        let comboPrice = 0;
        let singlePrice = 0;
        cart.forEach((item) => {
            const currentItem = Array.isArray(item.id) ? item.id[0] : item.id
            if (currentItem.product_fetchiscombo == 1) {
                comboPrice += item.qty * currentItem.product_comboprice
            } else if (currentItem.pb_salesrate) {
                singlePrice += item.qty * currentItem.pb_salesrate
            }
            count += item.qty;
            total = comboPrice + singlePrice;
        })
        setCartCount(count);
        setTotal(total);
    }, [cart, cartCount.toExponential]);

    const images = [
        {
            original: 'https://homestaymatch.com/images/no-image-available.png',
            thumbnail: 'https://homestaymatch.com/images/no-image-available.png',
        },
    ];


    const colorClick = (id) => {
        setBatch1(id)
    }

    const AddWishlist = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        const data = {
            mode: 'add',
            batch_id: batch1,
        }

        fetch(global.url + 'AddtowishlistOrDelete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                window.location.reload()
                if (responseJson == false) {

                    setOpen(true)
                    setMessage("Added To Cart")
                }
                else {
                    setOpen(true)
                    setMessage(responseJson.message)
                }
            })
    }



    const [isFullScreen, setIsFullScreen] = useState(false);

    // const toggleFullScreen = () => {
    //     setIsFullScreen(!isFullScreen);
    // };
    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    return (
        <div className='prdalign' style={{ fontFamily: "'lato', sans-serif" }}>
            <Container>
                {loader ?
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div style={{ marginTop: 150 }}>
                            <Loader />
                        </div>
                    </div> :
                    <div>
                        <div className='detailsMargintop'>
                            <Row>
                                <Col xs={12} md={5}>
                                    {/* <div>
                               <Skeleton sx={{ height: 350, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" />
                                <Row>
                                    <Col xs={3}>
                                       <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                   </Col>
                                  <Col xs={3}>
                                      <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                  </Col>
                                    <Col xs={3}>
                                         <Skeleton sx={{ height: 100, marginTop: 2, width: '100%' }} animation="wave" variant="rectangular" />
                                   </Col>
                                <Col xs={3}>
                                   </Col>
                                 </Row>
                            </div> */}

                                    <div>

                                        <div
                                        // className={`image-container1 ${isFullScreen ? 'full-screen-image1' : ''}`} onClick={toggleFullScreen}
                                        >
                                            {/* {imageList && imageList.length > 0 ?

                                                <ImageGallery items={images1}
                                                    // items={imageList}
                                                    // src={src} alt={alt}
                                                    // showFullscreenButton={false}
                                                    // className = 'full-screenable-image1'
                                                    showPlayButton={false} /> :
                                                <ImageGallery items={images}
                                                    showPlayButton={false}
                                                    // src={src} alt={alt}
                                                    // showFullscreenButton={false}
                                                    // className = 'full-screenable-image1'
                                                     />
                                            } */}
                                            
                                            {imageList.length == 0 ? (
                                                <ImageGallery items={images} showPlayButton={false} />
                                            ) : (
                                                <ImageGallery items={imageList} showPlayButton={false} />
                                            )}
                                        </div>
                                        <div className="details-imgtext">
                                            <p style={{ color: '#959595', fontSize: 15, marginBottom: 'unset' }}>{details.brand_name}</p>
                                            <h2 style={{ fontWeight: 600 }} >{details.product_name} </h2>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={7}>
                                    <div className='product-left'>
                                        <div className="details-righttext">
                                            <div>
                                                <p style={{ color: '#959595', fontSize: 15, marginBottom: 'unset' }}>{details.brand_name}</p>
                                                <h5 style={{ fontWeight: 600 }}>{details.product_name} </h5>
                                            </div>
                                        </div>
                                        <p className="details-righttext1" style={{ fontSize: 14 }}>Product Code : {details.product_refcode}</p>
                                        <div style={{ display: 'flex' }}>
                                            <h5 className="details-price" style={{ marginBottom: 'unset' }}><span className='prd2-currency'>{details.currency}</span> {details.pb_salesrate}</h5>
                                            {/* <p style={{textDecorationLine: 'line-through', color: '#5c5c5c', fontSize: 14, marginTop: 3, marginLeft: 10, fontWeight: 'bold'}}>{currency} {actuallPrice}</p> */}
                                            {/* <div className="off-border">
                                        <p style={{marginBottom: 'unset', color: '#00ac66', fontSize: 12, fontWeight: 'bold'}}>73% OFF</p>
                                    </div> */}
                                        </div>
                                        <div style={{ marginTop: 20 }}>
                                            {/* <Row>
                                         <Col xs={12}>
                                                <Row>
                                                    <Col xs={3}>
                                                        <p style={{fontSize: 16, marginTop: 8}}>Size & Colour</p>
                                                    </Col>
                                                    <Col xs={8}>
                                                        {loader ?
                                                            <Skeleton sx={{ height: 30, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" /> :
                                                            <Form.Select 
                                                                aria-label="Default select example" 
                                                                style={{boxShadow: 'none'}}
                                                                value={batch1}
                                                                onChange={colorChange} >
                                                                <option value=''>Select Size & Colour</option>
                                                                {sizeLists.map((item, index) => (
                                                                    <option value={item.pb_id}>{item.color_name} - {item.size_name}</option>
                                                                ))}
                                                            </Form.Select>
                                                        }
                                                    </Col>                              
                                                </Row>
                                            </Col>
                                        </Row> */}
                                            <Row>
                                                <Col xs={12}>
                                                    <div style={{ display: 'flex', marginTop: 20 }}>
                                                        {/* <p style={{marginBottom: 'unset', marginTop: 7, color: '#000'}}>Colors</p> */}
                                                        {/* {
                                                colorlist.map((i, k) => {
                                                    return(
                                                    <div onClick={() => colorClick(i.pb_id)} className="size-border" style={{background: i.color_name, borderColor: '#0a003e'}}>
                                                        {
                                                            i.color_name ==  details.color_name ?
                                                            <FiCheck color="#fff" fontSize={20} style={{marginTop: -5}}/>:
                                                            null
                                                        }
                                                        
                                                    </div>
                                                    )
                                                })
                                            } */}


                                                        {
                                                            loader ?
                                                                <div>
                                                                    <Row>
                                                                        <Skeleton sx={{ height: 60, width: 60, marginLeft: 2 }} animation="wave" variant="rectangular" />
                                                                        <Skeleton sx={{ height: 60, width: 60, marginLeft: 2 }} animation="wave" variant="rectangular" />
                                                                        <Skeleton sx={{ height: 60, width: 60, marginLeft: 2 }} animation="wave" variant="rectangular" />
                                                                    </Row>
                                                                </div> :
                                                                colorlist.map((i, k) => {
                                                                    return (

                                                                        i.images == 0 ?
                                                                            null :
                                                                            <div onClick={() => colorClick(i.pb_id)} className={i.color_name == details.color_name ? 'activecolor' : 'notactive'} style={{ cursor: 'pointer', marginRight: 10 }}>

                                                                                <img alt="img" src={global.images  + "/ecommerce_photos/" + i.images[0].pei_image} style={{ width: 70, height: 70 }} />
                                                                            </div>
                                                                    )
                                                                })
                                                        }

                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="size" style={{ display: 'flex', marginTop: 20 }}>
                                                        <p style={{ marginBottom: 'unset', marginTop: 7, color: '#000', marginRight: 5 }}>Size</p>
                                                        {
                                                            sizeLists.map((i, k) => {
                                                                return (
                                                                    <div onClick={() => colorClick(i.pb_id)} className={i.pb_id == batch1 ? "size-border active" : "size-border"}>
                                                                        <p className="sizeText">{i.size_name}</p>
                                                                    </div>
                                                                )

                                                            })
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col xs={12} md={8} lg={4} style={{ marginTop: 20 }}>
                                                    <Row>
                                                        <Col xs={4} sm={2} md={4}>
                                                            <p style={{ fontSize: 16, marginTop: 8 }}>Qty</p>
                                                        </Col>
                                                        <Col xs={6} sm={5} md={8}>
                                                            {loader ? <Skeleton sx={{ height: 30, marginTop: 1, width: '100%' }} animation="wave" variant="rectangular" /> :
                                                                <div>
                                                                    {details.pb_stock == 0 ?
                                                                        <p style={{ fontSize: 12, color: 'red', marginTop: 13 }}>Out of Stock</p>
                                                                        :
                                                                        // <Form.Select aria-label="Default select example" style={{boxShadow: 'none'}}>
                                                                        //     {options}
                                                                        // </Form.Select>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div onClick={handleDecre} className='cart-decr'><HiOutlineMinusSm color="#000" size={15} /></div>
                                                                                <p style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, fontSize: 14, marginTop: 2 }}>{input}</p>
                                                                                <div onClick={handleIncre} className='cart-decr'><BsPlus color="#000" size={15} /></div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={12} lg={3} style={{ marginTop: 30 }}>
                                                    {logtoken ?
                                                        <Button onClick={() => AddCart()} variant="primary" style={{ boxShadow: 'none', width: '100%' }} className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button> :
                                                        <Button onClick={() => toClick(details)} variant="primary" style={{ boxShadow: 'none', width: '100%' }} className="btn-success home-cartbtn buttonStyledel">Add To Cart</Button>
                                                    }
                                                </Col>
                                                <Col xs={12} md={12} lg={3} style={{ marginTop: 20 }}>
                                                    {logtoken ?
                                                        <div>
                                                            {details.wishlist == true ? null :
                                                                // <Button style={{ fontFamily: "'lato', sans-serif", fontWeight: 600 }} onClick={AddWishlist}  className='wishbtn'>Add To Wishlist</Button>
                                                                <Button
                                                                    style={{
                                                                        fontFamily: "'lato', sans-serif",
                                                                        fontWeight: 600,
                                                                    }}
                                                                    onClick={() => {
                                                                        AddCart();
                                                                        setTimeout(() => {
                                                                            history.push("/checkout");
                                                                        }, 2000);
                                                                    }}
                                                                    // onClick={AddWishlist}
                                                                    className="wishbtn"
                                                                >
                                                                    Buy Now
                                                                </Button>
                                                            }
                                                        </div> :
                                                        // <Button style={{ fontFamily: "'lato', sans-serif", fontWeight: 400 }} onClick={() => history.push('/login')} className='wishbtn'>Add To Wishlist</Button>
                                                        <Button
                                                            style={{
                                                                fontFamily: "'lato', sans-serif",
                                                                fontWeight: 400,
                                                            }}
                                                            onClick={() => {
                                                                toClick(details)
                                                                history.push("/login")
                                                            }}
                                                            className="wishbtn"
                                                        >
                                                            Buy Now
                                                        </Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab style={{ fontFamily: "'lato', sans-serif", fontSize: '0.9rem', fontWeight: 500 }} label="Overview" value="1" />
                                            <Tab style={{ fontFamily: "'lato', sans-serif", fontSize: '0.9rem', fontWeight: 500 }} label="Description" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">

                                        <div>
                                            <p style={{ fontSize: 14 }}>{productId}</p>
                                        </div>

                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div>
                                            <p style={{ fontSize: 14 }}>{productId}</p>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                        <div style={{ marginTop: 30, marginBottom: 50 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h2 style={{ color: '#000', fontSize: 28, fontFamily: "'lato', sans-serif", fontWeight: 500 }}>Related Products</h2>
                            </div>
                            <div>
                                {
                                    relatedLists.length == 0 ?
                                        <p style={{ color: '#000', fontSize: 15, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>No Related Products</p> :
                                        <Related data={relatedLists} />}
                            </div>
                        </div>
                    </div>}

                {/* cart pop */}

                {cartView ?
                    <div className="cart-content">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <p style={{ fontWeight: 500, fontSize: 16, marginTop: 1 }}>Cart</p>
                            </div>
                            <a onClick={closeviewCart}>
                                <AiOutlineClose color="#000" size={18} />
                            </a>
                        </div>
                        {logtoken ?
                            <div className='innerdiv'>
                                {cartList.length === 0 && comboCartList.length === 0 ? <p style={{ fontSize: 14, fontWeight: 500, textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                                    <div>
                                        <div>
                                            {cartList.map((item, index) => (
                                                <div>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.image == null ?
                                                                        <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                                                        <img alt="img" src={global.images  + "/ecommerce_photos/" + item.image} style={{ width: 80, height: 80 }} />
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 500 }}>{item.quantity} x {item.price} {item.currency}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            ))}
                                            {comboCartList.map((item, index) => (
                                                <div>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.image == null ?
                                                                        <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                                                        <img alt="img" src={global.images  + "/combo_photos/" + item.image} style={{ width: 80, height: 80 }} />
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 500 }}>{item.quantity} x {item.price} {item.currency}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div> :
                            <div className='innerdiv'>
                                {cart.length === 0 ? (
                                    <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginTop: 30 }}>No Cart Added</p>
                                ) : (
                                    <div>
                                        {cart.map((item, index) => (
                                            Array.isArray(item.id) ? (
                                                <div key={item.id[0].products_id}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.id[0].product_comboimage == null ? (
                                                                        <img alt="img" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    ) : (
                                                                        <img alt="img" src={global.images  + "/combo_photos/" + item.id[0].product_comboimage} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.id[0].product_name}</p>

                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.qty} x {item.id[0].product_comboprice} <span>QAR</span></p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ) : (
                                                <div key={item.id.products_id}>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                {
                                                                    item.id.images[0].length === 0 ? (
                                                                        <img alt="img" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    ) : (
                                                                        <img alt="img" src={global.images  + "/ecommerce_photos/" + item.id.images[0].pei_image} className="order-img" style={{ width: '100%', height: 100 }} />
                                                                    )
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <div>
                                                                <p className='product-name' style={{ fontSize: 11 }}>{item.id.name}</p>
                                                                <p style={{ fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold' }}>{item.qty} x {item.id.pb_salesrate} {item.id.currency}</p>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                        {logtoken ?
                            <div style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ fontWeight: 500, fontSize: 14 }}>Sub total</p>
                                    <p style={{ fontWeight: 500, fontSize: 14 }}>QAR {cartTotal}</p>
                                </div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Button onClick={() => history.push('/checkout')} variant="primary" className='checkoutbtn'>Checkout</Button>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'>Continue Shopping</Button>
                                    </Col>
                                </Row>
                            </div> :
                            <div style={{ marginTop: 20 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p style={{ fontWeight: 500, fontSize: 14 }}>Sub total</p>
                                    <p style={{ fontWeight: 500, fontSize: 14 }}>QAR {total}</p>
                                </div>
                                <Row>
                                    <Col xs={6}>
                                        <Button onClick={() => history.push('/login')} variant="primary" className='checkoutbtn'>Checkout</Button>
                                    </Col>
                                    <Col xs={6}>
                                        <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'>Continue Shopping</Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div> : null}

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (item) => {
            dispatch(addToCart(item))
        },
        adjustQty: (id, value) =>
            dispatch(adjustItemQty(id, value)),
        refresh: (item) => dispatch(refresh(item)),
        // console.log(id,"id123",value,"value123")
    };
};
const mapStateToProps = (state) => {
    console.log(state, "state123")
    return {
        cart: state.shop.cart,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);