import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
  } from "react-router-dom";
import CommonLayout from './Components/Layout/CommonLayout';
import Cart from './Screens/Cart/Cart';
import Home from './Screens/Home/Home.js';
import Login from './Screens/Login/Login';
import Order from './Screens/Order/Order';
import Product from './Screens/Product/Product';
import ProductDetails from './Screens/ProductDetails/ProductDetails1';
import Profile from './Screens/Profile/Profile';
import Signup from './Screens/Signup/Signup';
import Track from './Screens/Track/Track';
import Checkout from './Screens/Checkout/Checkout';
import Forgot from './Screens/Forgot/Forgot';
import Guest from './Screens/Guest/GuestLogin';
import Success from './Screens/Success/Success';
import Wishlists from './Screens/Wishlists/Wishlist';
import CProductDetails from './Screens/combodetails/combodetails1';
import Comboproduct from './Screens/comboprd/comboprd'
import SubCategory from './Components/subcategory/subcategory.js';
import AllPrd from './Screens/Home/AllProduct.js'
import AllCprd from './Screens/Home/allCproduct.js'



function AppNavigator () {
    return(
        <Router>
            <Switch>     
                <CommonLayout exact path="/" component={Home} />
                <CommonLayout path='/product/:id/:type' component={Product} />
                <CommonLayout path ='/product/:id/type 'component={Comboproduct}/>
                <CommonLayout path="/details/:id/:batch" component={ProductDetails} />
                <CommonLayout path="/combodetails/:id" component ={CProductDetails}/>
                <CommonLayout path="/newarrival" component ={AllPrd}/>
                <CommonLayout path="/combo" component ={AllCprd}/>


                {/* <AllProduct exact path = "/Newarrival" component ={AllProduct} /> */}

                <CommonLayout exact path="/cart" component={Cart} />

                <CommonLayout path='/category/:categoryId' component={SubCategory}/>

                <CommonLayout exact path='/profile' component={Profile} />
                <Login exact path='/login' component={Login} /> 
                <Forgot exact path='/forgotpassword' component={Forgot} />
                <Guest exact path='/guestlogin' component={Guest} />
                <Signup exact path='/signup' component={Signup} />
                <CommonLayout exact path='/order' component={Order} />
                <CommonLayout exact path='/wishlists' component={Wishlists} />
                <CommonLayout exact path='/checkout' component={Checkout} />
                <CommonLayout exact path='/trackorder/:id' component={Track} />
                <Success exact path="/success" component={Success} />
            </Switch>
        </Router>
    )
}
export default AppNavigator;