import React, { useState, useEffect } from 'react';
import './Order.css';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { FaUserAlt, FaListAlt } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi"
import { MdDeliveryDining, MdLogout } from "react-icons/md";
import { AiFillHeart, AiFillStar } from "react-icons/ai";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader'

import Header from '../../Components/Header/header';
import Footer from '../../Components/Footer/Footer';
import img from '../../Assets/Images/noimages.png';
import { Box } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Order() {
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [logtoken, setLogtoken] = useState('')
  const [source, setSource] = useState([])
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = React.useState(false);
  const [date, setDate] = useState([]);
  const [time, setTime] = useState([]);
  // const ordersByInvoice = {};


  // source.forEach((order) => {
  //   if (!ordersByInvoice[order.invoice]) {
  //     ordersByInvoice[order.invoice] = [];
  //   }
  //   ordersByInvoice[order.invoice].push(order);
  // });

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
    console.log('logged', logged)
    setLogtoken(logged)
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    var token = JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "myOrdersfrontend", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },

    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoading(false)
        if (responseJson.error == false) {
          setSource(responseJson.data)
          setDate(responseJson.data.date);
          setTime(responseJson.data.datetime)

        }
        else {
          setSource([])
        }
        console.log("order response", responseJson)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteOrder = (id) => {
    var token = JSON.parse(localStorage.getItem("token"))
    var data = {
      "orderid": id
    }
    fetch(global.url + "CancelMyOrder", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          setOpen(true)
          setMessage(responseJson.message)
          getData()
        }
        else {
          setOpen(true)
          setMessage(responseJson.message)
          getData()
        }
        console.log("order cancel response", responseJson)

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const logoutClick = () => {
    setLoader(true)
    var token = JSON.parse(localStorage.getItem("token"))
    fetch(global.url + "logout", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("logout", responseJson)
        setLoader(false)
        if (responseJson.error == false) {
          localStorage.setItem("isAuthenticatedfront", false);
          localStorage.removeItem("token")
          localStorage.removeItem("userName")
          localStorage.removeItem("persist:root")
          localStorage.removeItem("userdata")
          history.push('/')
          window.location.reload()

        }
      })
      .catch((error) => {
        console.log(error)
      })
  }


  // function groupOrdersByInvoice(orders) {
  //     const groupedOrders = {};
  //     orders.forEach((order) => {
  //         if (!groupedOrders[order.invoice]) {
  //             groupedOrders[order.invoice] = [];
  //         }
  //         groupedOrders[order.invoice].push(order);
  //     });
  //     return Object.values(groupedOrders);
  // }


  // const groupOrdersByOrderId = (orders) => {
  //     const groupedOrders = {};
  //     orders.forEach((order) => {
  //       if (!groupedOrders[order.orderid]) {
  //         groupedOrders[order.orderid] = [];
  //       }
  //       groupedOrders[order.orderid].push(order);
  //     });
  //     return Object.values(groupedOrders);
  //   };

  //   const groupedOrders = groupOrdersByOrderId(source);
  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    const reversedDate = day + '-' + month + '-' + year;
    return reversedDate;
  };
  const formatTime = (timeString) => {
    const dateTime = new Date(timeString);
  
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
    
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; 
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  

  // const formatTime = (timeString) => {
  //   const parts = timeString.split(':');
  //   let hours = parseInt(parts[0], 10);
  //   const minutes = parts[1];
  //   const seconds = parts[2];
  //   let amOrPm = 'AM';

  //   if (hours >= 12) {
  //     amOrPm = 'PM';
  //     hours = hours === 12 ? 12 : hours - 12;
  //   }

  //   hours = hours === 0 ? 12 : hours;

  //   return `${hours}:${minutes}:${seconds} ${amOrPm}`;
  // };



  return (
    <div>
      <div className='cartTop' style={{ fontFamily: "'Poppins', sans-serif" }}>
        <Container>
          <div>
            <p style={{ fontSize: 23, fontWeight: 500 }}>My Orders</p>
          </div>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <div className="profilebox">
                <a onClick={() => history.push('/profile')}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FaUserAlt color="#000" size={13} style={{ marginRight: 7, marginTop: -2 }} /> My Profile</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                <a>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FaListAlt color="#000" size={15} style={{ marginRight: 5, marginTop: -2 }} /> My Orders</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#00a085' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                <a onClick={() => history.push('/wishlists')}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><AiFillHeart color="#000" size={15} style={{ marginRight: 5, marginTop: -2 }} /> My Wishlist</p>
                    </div>
                    <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                  </div>
                </a>
                <div style={{ width: '100%', height: 1, backgroundColor: '#d9d9d9' }}></div>
                {logtoken ?
                  <a onClick={logoutClick}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 15, paddingBottom: 1 }}>
                        <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><MdLogout color="#000" size={16} style={{ marginRight: 5, marginLeft: 3, marginTop: -2 }} /> Logout</p>
                      </div>
                      <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                    </div>
                  </a> :
                  <a onClick={() => history.push('/login')}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ padding: 15, paddingBottom: 1 }}>
                        <p style={{ fontSize: 14, cursor: 'pointer', fontWeight: 500 }}><FiLogIn color="#000" size={16} style={{ marginRight: 7, marginTop: -2 }} /> Login</p>
                      </div>
                      <div style={{ height: 53, width: 5, backgroundColor: '#d9d9d9' }}></div>
                    </div>
                  </a>
                }
              </div>
            </Col>

            <Col xs={12} md={8} lg={9}>
              {/* my orders */}

              <div>
                {loading ? <Loader /> :
                  <div>
                    {source.length == 0 ? <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center", }}> No Orders </p> :
                      <div>
                        {source.map((item) => (
                          <a style={{ cursor: 'pointer' }}  >
                            <div className='myordersdiv'>
                              <Row>
                                <Col xs={12} md={3} lg={2}>
                                  <div style={{ display: 'flex', justifyContent: "center" }}>
                                    {
                                      item.image == null ? (
                                        <img alt="img" src={img} className="order-img" style={{ width: '100%', height: 100 }} />
                                      ) : (
                                        (item.color == null && item.size == null) ? (
                                          <img alt="img" src={global.images + "/combo_photos/" + item.image} className="order-img" style={{ width: '100%', height: 100 }} />
                                        ) : (
                                          <img alt="img" src={global.images + "/ecommerce_photos/" + item.image} className="order-img" style={{ width: '100%', height: 100 }} />
                                        )
                                      )
                                    }

                                  </div>
                                </Col>
                                <Col xs={12} md={9} lg={10}>
                                  <div className='order-paradiv'>
                                    <p style={{ marginBottom: 'unset', fontSize: 14, fontWeight: 500 }}>{item.productname}</p>
                                    <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Order Id : {item.invoice}</p>
                                    <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Color and Size : {item.color} - {item.size}</p>
                                    <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Quantity : {item.quantity}</p>
                                    <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Date : {formatDate(item.date)}</p>
                                    <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a' }}>Time : {formatTime(item.datetime)}</p>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <div>
                                        {
                                          item.cancelled == true ?
                                            <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Cancelled</p> :
                                            item.delivered == true ?
                                              <p style={{ fontSize: 14, fontWeight: 500, color: '#18a314' }}>Delivered</p> :
                                              item.shipped == true ?
                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Shipped</p> :
                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#18a314' }}>Ordered</p>
                                        }
                                      </div>
                                      {item.delivered == false && item.cancelled == false ?
                                        <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => history.push('/trackorder/' + item.orderid)}>
                                          <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Track Order</p>
                                        </button> : null}
                                      {item.delivered == false && item.cancelled == false ?
                                        <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => deleteOrder(item.orderid)}>
                                          <p style={{ fontSize: 14, fontWeight: 500, color: 'red' }}>Order Cancel</p>
                                        </button> : null}
                                    </div>

                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </a>
                        ))}
                      </div>}
                  </div>}
              </div>
            </Col>

            {/* {Object.keys(ordersByInvoice).map((invoice, index) => (
  ordersByInvoice[invoice].length > 1 && (
    <div key={invoice} 
    style={{ border: '1px solid #ccc', padding: '120px', marginBottom: '20px'  }}
    >
      {ordersByInvoice[invoice].map((item, itemIndex) => (
        
        <div
          key={item.orderid}
          style={{
            // paddingBottom:100,
            position: 'absolute',
            left:'0',
            right: `${itemIndex * -60}px`, // Adjust the spacing as needed
            zIndex: ordersByInvoice[invoice].length - itemIndex, // Reverse z-index order
          }}
        >
          <Row>
          <Col  xs={12} md={8} lg={9}>
            <div style={{ marginLeft: '50px', display: 'flex', justifyContent: 'center' }}>
              {item.image == null ? (
                <img alt="img" src={img}  style={{ width: '80px', height: '80px' }} />
              ) : (
                <img alt="img" src={global.images +  '/ecommerce_photos/' + item.image}  style={{ width: '80px', height: '80px' }} />
              )}
            </div>
            
           
            
            </Col>
          </Row>
        </div>
      ))}
    </div>
  )
))} */}
            {/* <Col xs={12} md={8} lg={9}>
      my orders
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {source.length === 0 ? (
              <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
                No Orders
              </p>
            ) : (
              <div>
                {groupOrdersByInvoice(source).map((group) => (
                  <div  key={group[0].invoice}>
                    {group.length > 1 ? (
                      <div  style={{ boxSizing: 'border-box' }} >
                        <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
                          Orders with Invoice Number {group[0].invoice} have length greater than 1
                        </p>
                        <div className="row">
                          {group.map((item) => (
                            <div className="col-xs-12 col-md-6 col-lg-4" key={item.orderid}>
                              Render individual order details here
                              <div className='myordersdiv'>
                                <Row>
                                  <Col xs={12} md={3} lg={2}>
                                    <div style={{ marginLeft:50,display: 'flex', justifyContent: "center" }}>
                                      {
                                        item.image == null ?
                                          <img alt="img" src={img} className="order-img" style={{ width: 80, height: 80 }} /> :
                                          <img alt="img" src={global.images + "ecommerce_photos/" + item.image} className="order-img" style={{ width: 80, height: 80 }} />
                                      }
                                    </div>
                                  </Col>
                                  <Col xs={12} md={9} lg={10}>
                                    <div style={{ marginLeft:65}} className='order-paradiv'>
                                      <p style={{ marginBottom: 'unset', fontSize: 14, fontWeight: 500 }}>{item.productname}</p>
                                      <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Order Id : {item.invoice}</p>
                                      <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Color and Size : {item.color} - {item.size}</p>
                                      <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a' }}>Quantity : {item.quantity}</p>
                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                          {
                                            item.cancelled == true ?
                                              <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Cancelled</p> :
                                              item.delivered == true ?
                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Delivered</p> :
                                                item.shipped == true ?
                                                  <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Shipped</p> :
                                                  <p style={{ fontSize: 14, fontWeight: 500, color: '#18a314' }}>Ordered</p>
                                          }
                                        </div>
                                        {item.cancelled == false ?
                                          <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => history.push('/trackorder/' + item.orderid)}>
                                            <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Track Order</p>
                                          </button> : null}
                                        {item.cancelled == false ?
                                          <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => deleteOrder(item.orderid)}>
                                            <p style={{ fontSize: 14, fontWeight: 500, color: 'red' }}>Order Cancel</p>
                                          </button> : null}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      group.map((item) => (
                        <a style={{ cursor: 'pointer' }} key={item.orderid}>
                          Render individual order details here
                          <div className='myordersdiv'>
                            <Row>
                              <Col xs={12} md={3} lg={2}>
                                <div style={{ display: 'flex', justifyContent: "center" }}>
                                  {
                                    item.image == null ?
                                      <img alt="img" src={img} className="order-img" style={{ width: '100%', height: 100 }} /> :
                                      <img alt="img" src={global.images + "ecommerce_photos/" + item.image} className="order-img" style={{ width: '100%', height: 100 }} />
                                  }
                                </div>
                              </Col>
                              <Col xs={12} md={9} lg={10}>
                                <div className='order-paradiv'>
                                  <p style={{ marginBottom: 'unset', fontSize: 14, fontWeight: 500 }}>{item.productname}</p>
                                  <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Order Id : {item.invoice}</p>
                                  <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Color and Size : {item.color} - {item.size}</p>
                                  <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a' }}>Quantity : {item.quantity}</p>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                      {
                                        item.cancelled == true ?
                                          <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Cancelled</p> :
                                          item.delivered == true ?
                                            <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Delivered</p> :
                                            item.shipped == true ?
                                              <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Shipped</p> :
                                              <p style={{ fontSize: 14, fontWeight: 500, color: '#18a314' }}>Ordered</p>
                                      }
                                    </div>
                                    {item.cancelled == false ?
                                      <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => history.push('/trackorder/' + item.orderid)}>
                                        <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Track Order</p>
                                      </button> : null}
                                    {item.cancelled == false ?
                                      <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => deleteOrder(item.orderid)}>
                                        <p style={{ fontSize: 14, fontWeight: 500, color: 'red' }}>Order Cancel</p>
                                      </button> : null}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </a>
                      ))
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </Col> */}
          </Row>




        </Container>

      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
  // return (
  //     <div>
  //       <div className="cartTop">
  //         <Container>
  //           <div>
  //             <p style={{ fontSize: 23, fontWeight: 'bold' }}>My Orders</p>
  //           </div>
  //           <Row>
  //             <Col xs={12} md={4} lg={3}>
  //               {/* ... (Profile box content) ... */}
  //             </Col>
  //             <Col xs={12} md={8} lg={9}>
  //               <div>
  //                 {loading ? (
  //                   <Loader />
  //                 ) : (
  //                   <div>
  //                     {source.length === 0 ? (
  //                       <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
  //                         No Orders
  //                       </p>
  //                     ) : (
  //                       <div>
  //                         {groupedOrders.map((comboOrders, index) => (
  //                           <div key={index} className="myordersdiv">
  //                             {comboOrders.map((item) => (
  //                               <Row key={item.orderid}>
  //                                 <Col xs={12} md={3} lg={2}>
  //                                   <div style={{ display: 'flex', justifyContent: "center" }}>
  //                                     {
  //                                       item.image == null ?
  //                                         <img alt="img" src={img} className="order-img" style={{ width: '100%', height: 100 }} /> :
  //                                         <img alt="img" src={global.images + "ecommerce_photos/" + item.image} className="order-img" style={{ width: '100%', height: 100 }} />
  //                                     }
  //                                   </div>
  //                                 </Col>
  //                                 <Col xs={12} md={9} lg={10}>
  //                                   <div className='order-paradiv'>
  //                                     <p style={{ marginBottom: 'unset', fontSize: 14, fontWeight: 500 }}>{item.productname}</p>
  //                                     <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Order Id : {item.invoice}</p>
  //                                     <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a', marginBottom: 'unset' }}>Color and Size : {item.color} - {item.size}</p>
  //                                     <p style={{ fontSize: 12, fontWeight: 500, color: '#7a7a7a' }}>Quantity : {item.quantity}</p>
  //                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
  //                                       <div>
  //                                         {
  //                                           item.cancelled == true ?
  //                                             <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Cancelled</p> :
  //                                             item.delivered == true ?
  //                                               <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Delivered</p> :
  //                                               item.shipped == true ?
  //                                                 <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Shipped</p> :
  //                                                 <p style={{ fontSize: 14, fontWeight: 500, color: '#18a314' }}>Ordered</p>
  //                                         }
  //                                       </div>
  //                                       {item.cancelled == false ?
  //                                         <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => history.push('/trackorder/' + item.orderid)}>
  //                                           <p style={{ fontSize: 14, fontWeight: 500, color: '#7a7a7a' }}>Track Order</p>
  //                                         </button> : null}
  //                                       {item.cancelled == false ?
  //                                         <button style={{ cursor: 'pointer', background: 'none', border: 'none' }} onClick={() => deleteOrder(item.orderid)}>
  //                                           <p style={{ fontSize: 14, fontWeight: 500, color: 'red' }}>Order Cancel</p>
  //                                         </button> : null}
  //                                     </div>
  //                                   </div>
  //                                 </Col>
  //                               </Row>
  //                             ))}
  //                           </div>
  //                         ))}
  //                       </div>
  //                     )}
  //                   </div>
  //                 )}
  //               </div>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </div>
  //       <Snackbar
  //         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //         open={open} autoHideDuration={3000} onClose={handleClose}>
  //         <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
  //           {message}
  //         </Alert>
  //       </Snackbar>
  //     </div>
  //   );



}

export default Order;