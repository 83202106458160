import React from "react";
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

function Category(props) {
  let history = useHistory();


  return (
    // <Row  style={{fontFamily: "'lato', sans-serif"}}>
    //     {
    //         props.categoryloading ?
    //             <Row>
    //                 <Col xs={6} md={3} lg={3} style={{marginTop: 50}}>
    //                     <div>
    //                         <div style={{display: 'flex', justifyContent: 'center'}}>
    //                             <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
    //                         </div>
    //                         <Skeleton variant="text" />
    //                     </div>
    //                 </Col>
    //                 <Col xs={6} md={3} lg={3} style={{marginTop: 50 ,marginBottom:50}} >
    //                     <div>
    //                         <div style={{display: 'flex', justifyContent: 'center'}}>
    //                             <Skeleton sx={{ width: 130, height: 120 }} animation="wave" variant="circular" />
    //                         </div>
    //                         <Skeleton variant="text" />
    //                     </div>
    //                 </Col>
    //             </Row>
    //         :

    //         // <div
    //         // //  className="mob-category"
    //         //  >
    //             <div
    //             // className="mob-category-menu"
    //             >
    //                 {
    //                     props.subcatlist.map((item) => {
    //                         return(
    //                             <Col xs={4} lg={4} md={4}>
    //                             {/* <div
    //                             //  className="cate-list"
    //                              > */}
    //                                 <a onClick={() => history.push('/category/' + item.subCategory_category )} style={{cursor: "pointer"}}>
    //                                     <div className="categ-img">
    //                                         <img alt="img" src={global.images + 'ecommerce_photos/' + item.category_image} style={{height:50,width:50}}/>
    //                                     </div>
    //                                     <p className='categorytxt' >{item.category_name}</p>
    //                                 </a>
    //                             {/* </div> */}
    //                             </Col>

    //                         )
    //                     })
    //                 }
    //             </div>
    //         // </div>
    //     }
    // </Row>
    <div className="mobcatdiv">
      <Row>
        {
          props.categoryloading ? (
            <Row>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 20 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
              <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      sx={{ width: 130, height: 120 }}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                  <Skeleton variant="text" />
                </div>
              </Col>
            </Row>
          ) : Array.isArray(props.subcatlist) && props.subcatlist.length > 0 ? (
            props.subcatlist.map((item) => (
              item.category_name ===null ? null :
              <Col xs={3} md={3} lg={3} style={{ marginTop: 25 }}>
                <a
                  onClick={() =>
                    history.push("/category/" + item.subCategory_category)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="cat-imgview"
                  >
                    <img
                      alt="img"
                      src={
                        global.images 
                        +"/ecommerce_photos/" +
                        item.category_image
                      }
                      style={{ width: 90, height: 90 }}
                    />
                  </div>
                  <p className="catname"
                    // style={{
                    //   textAlign: "center",
                    //   marginTop: 18,
                    //   fontSize: 15,
                    //   fontWeight: 600,
                    //   marginBottom: "unset",
                    //   fontFamily: "'lato', sans-serif",
                    //   textTransform:'capitalize'
                    // }}
                  >
                    {item.category_name}
                  </p>
                </a>
              </Col>
            ))
          ) : null
          //     (
          //     // <p>No subcategories found</p>
          // )
        }
      </Row>
    </div>
  );
}
export default Category;
