import React, {useState, useEffect} from 'react';
import './Login.css';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import logo from '../../Assets/Images/snap qatar logo  1.png';
import {useHistory} from 'react-router-dom';
import { getCardActionAreaUtilityClass } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../Components/Loader/Loader';
import { connect } from "react-redux";
import {
    refresh
  } from "../../redux/Shopping/shopping-actions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function Login({cart,refresh}){
    console.log(cart,"frontendaddcart 12345")
    let history = useHistory();
    const vertical = "top"
    const horizontal = "center"
    const [email, setEmail] = useState('');
    const [password, setPassword]= useState('');
    const [emailErr, setEmailErr] = useState({});
    const [passwordErr, setPasswordErr] = useState({});

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrormessage] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        const isValid = formValidation();
    }
    useEffect(() => {
       
       console.log("cart", cart)
      },[])

    const formValidation = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const emailErr = {};
        const passwordErr = {};
        let isValid = true;

        if(email.trim().length == 0){
            emailErr.emailRequired = 'Email id is required';
            isValid = false;
        }
        if (!regex.test(email)){
            emailErr.emailRequired = 'Email id is not valid';
            isValid = false;
        }
        if(password.trim().length == 0){
            passwordErr.passwordRequired = 'Password is required';
            isValid = false;
        }
        if(email.trim().length != 0 && password.trim().length != 0 && regex.test(email) ){
            getData()
        }

        setEmailErr(emailErr);
        setPasswordErr(passwordErr);
        return isValid;

    };
    const getData = () => {
        setLoader(true)
        var data = {
            "emailid" : email,
            "password" : password,
        }
        console.log("data", data)
        fetch(global.url + "auth/login", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129'
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
            console.log("response signup", responseJson)  
            setLoader(false)
            if(responseJson.error == false){
                var data = responseJson.data
                var data1 = data.user_data
                console.log("data", data1)
                localStorage.setItem("isAuthenticatedfront", true);
                localStorage.setItem("token", JSON.stringify(data.api_token));
                localStorage.setItem("userName", JSON.stringify(data1.user_name));
                localStorage.setItem("userdata", JSON.stringify(data1));
                history.push('/');
                
                cartItems()
            }else{
                localStorage.setItem("isAuthenticatedfront", false);
                setOpen(true)
                setErrormessage(responseJson.message)
            }   
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    // add to cart after login success
    const cartItems = () => {
        console.log("f123 called")
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            'data': cart,
        }
        console.log("data1233", data)
        fetch(global.url + "frontEndaddCart", {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json',
                'APPKEY' : 'erp6129',
                'Authorization' : 'Bearer ' + token 
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("responseJson frontendaddcart", responseJson)
          refresh("")
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }
        setOpen(false);
    }

    return(
        <div className="App" style={{fontFamily: "'lato', sans-serif"}}>
            <Container fluid>
                <Row>
                    <Col xs={12} style={{marginTop: 20}}> 
                        <div className="login-box">
                            <div className="box-inside">
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10}}>
                                    <div className='login-company-logo'
                                    // alt="img" style={{height:'25%',width:'25%'}} src={logo}
                                     ></div>
                                </div>
                                <p className='loginpara' style={{fontSize:18}}>Login</p>
                                <div style={{marginTop: 30}}>
                                    <Form>
                                        <Form.Group className="mb-1" controlId="formBasicEmail">
                                            <Form.Label  style={{fontSize:15}} >Email</Form.Label>
                                            <Form.Control 
                                                type="email"
                                                className='shadow12' 
                                                value={email}
                                                onChange={(e) => {setEmail(e.target.value)}} 
                                                placeholder='Enter Your Email id '
                                                style={{fontSize:13}} />
                                        </Form.Group>
                                        {Object.keys(emailErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{emailErr[key]}</div>
                                        })}
                                        <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                            <Form.Label  style={{fontSize:15}} >Password</Form.Label>
                                            <Form.Control 
                                                type="password" 
                                                className='shadow12'
                                                value={password}
                                                onChange={(e) => {setPassword(e.target.value)}} 
                                                placeholder='Enter Your Password' 
                                                style={{fontSize:13}} />
                                        </Form.Group>
                                        {Object.keys(passwordErr).map((key) => {
                                            return <div style={{color: 'red', fontSize: 12}}>{passwordErr[key]}</div>
                                        })}
                                    </Form>
                                </div>
                                <div style={{marginTop: 50}}>
                                    {loader ? 
                                        <Loader />:
                                        <Button onClick={onSubmit} variant="primary" className='login-head'>Login</Button>
                                    }
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p style={{ fontSize: 14, fontWeight: 500, marginTop: 10}}><a onClick={() => history.push('/guestlogin')}><span className=' login-text'>Guest Login ?</span></a></p>
                                    <p style={{ fontSize: 14, fontWeight: 500, marginTop: 10}}><a onClick={() => history.push('/forgotpassword')}><span className=' login-text' >Forgot Password ?</span></a></p>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <p style={{textAlign: 'center', fontSize: 14, fontWeight: 500}}>Don't have an account? <a onClick={() => history.push('/signup')}><span className=' login-text'>Signup</span></a></p>
                                    <a onClick={() => history.push('/')}><p className='login-back'>Back to Home</p></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Snackbar anchorOrigin={{vertical, horizontal}} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    )
} 

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      refresh: (item) => dispatch(refresh(item))
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Login);