import React from "react";
import "./Footer.css";
import { Row, Col, Container } from "react-bootstrap";

import { FaYoutube } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";

import { FaFacebookSquare } from "react-icons/fa";

import { MdOutlineSecurity, MdHeadsetMic } from "react-icons/md";
import { GiRoundStar } from "react-icons/gi";

import { AiOutlineTwitter } from "react-icons/ai";

function Footer() {
  return (
    <div
      style={{
        // marginTop: 70,
        backgroundColor: "white",
        position: "absolute",
        width: "100%",
      }}
      className="textfont"
    >
      <Container style={{ paddingTop: "50px" }}>
        <Row>
          <Col sm={6} xs={12} md={3}>
            <p style={{ textAlign: "center" }}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                color="#000"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"></path>
              </svg>
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px;",
                fontWeight: 600,
              }}
            >
              Secure Payments
            </p>
            <p
              style={{
                textAlign: " center",
                fontSize: "14px",
                color: "rgb(110, 110, 110)",
              }}
            >
              Your money is yours! All refunds come with no question asked
              quarantee
            </p>
          </Col>
          <Col sm={6} xs={12} md={3}>
            <p style={{ textAlign: "center" }}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                color="#000"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <path fill="none" d="M0 0h24v24H0z" opacity=".1"></path>
                <path d="M12 1a9 9 0 00-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1h-7v2h6c1.66 0 3-1.34 3-3V10a9 9 0 00-9-9z"></path>
              </svg>
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              24x7 Help
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "rgb(110, 110, 110)",
              }}
            >
              Need Help? You can also talk to us on +974 31062060 to resolve
              your query
            </p>
          </Col>
          <Col sm={6} xs={12} md={3}>
            <p style={{ textAlign: "center" }}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                color="#000"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <path d="M256 38.013c-22.458 0-66.472 110.3-84.64 123.502-18.17 13.2-136.674 20.975-143.614 42.334-6.94 21.358 84.362 97.303 91.302 118.662 6.94 21.36-22.286 136.465-4.116 149.665 18.17 13.2 118.61-50.164 141.068-50.164 22.458 0 122.9 63.365 141.068 50.164 18.17-13.2-11.056-128.306-4.116-149.665 6.94-21.36 98.242-97.304 91.302-118.663-6.94-21.36-125.444-29.134-143.613-42.335-18.168-13.2-62.182-123.502-84.64-123.502z"></path>
              </svg>
            </p>
            <p
              style={{ textAlign: "center", fontSize: "16px", fontWeight: 600 }}
            >
              100% Assurance
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "rgb(110, 110, 110)",
              }}
            >
              At Nasshopy, we provide 100% assurance. If you have any issues,
              your money is immediately refunded
            </p>
          </Col>
          <Col sm={6} xs={12} md={3}>
            <p style={{ textAlign: "center" }}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                color="#000"
                height="25"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <path d="M352 160v-32C352 57.42 294.579 0 224 0 153.42 0 96 57.42 96 128v32H0v272c0 44.183 35.817 80 80 80h288c44.183 0 80-35.817 80-80V160h-96zm-192-32c0-35.29 28.71-64 64-64s64 28.71 64 64v32H160v-32zm160 120c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm-192 0c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"></path>
              </svg>
            </p>
            <p
              style={{ textAlign: "center", fontSize: "16px", fontWeight: 600 }}
            >
              Shop on the App
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: " 14px",
                color: "rgb(110, 110, 110)",
              }}
            >
              Coming Soon
            </p>
          </Col>
        </Row>

        <Row className="footer">
          <Col sm={6} xs={12} md={3} className="footer-col">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {/* <p  ><MdOutlineSecurity color="#000" size={25}/></p> */}
              <div>
                <h1 className="footerhead1">Explore</h1>
              </div>

              <div>
                <a className="footerhead2" href="">
                  Home
                </a>
              </div>
              {/* <div>
                <a className="footerhead2" href="">
                  Our Company
                </a>
              </div> */}
              <div>
                <a className="footerhead2" href="">
                  Privacy Policy
                </a>
              </div>
              {/* <div>
                <a className="footerhead2" href="">
                  Carriers
                </a>
              </div> */}
            </div>
          </Col>
          {/* <Col sm={6} xs={12} md={2}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
           
              <div>
                <h1 className="footerhead1">Services</h1>
              </div>

              <div>
                <a href="" className="footerhead2">
                  electronics
                </a>
              </div>
              <div>
                <a href="" className="footerhead2">
                  Accessories
                </a>
              </div>
              <div>
                <a href="" className="footerhead2">
                  mobile
                </a>
              </div>
              <div></div>
            </div>
          </Col> */}
          <Col sm={6} xs={12} md={3} className="footer-col">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {/* <p  ><GiRoundStar color="#000" size={25} /></p> */}
              <div>
                <h1 className="footerhead1">Follow Us</h1>
              </div>
              <div>
                <a className="footerhead2">
                  <FaFacebookSquare /> Facebook
                </a>
              </div>
              <div>
                <a className="footerhead2">
                  <FaInstagramSquare /> Instagram
                </a>
              </div>
              <div>
                <a className="footerhead2">
                  <FaWhatsappSquare /> What's App
                </a>
              </div>
              {/* <div>
                <a href="" className="footerhead2">
                  <FaYoutube /> You Tube
                </a>
              </div> */}
            </div>
          </Col>
          <Col sm={6} xs={12} md={3} className="footer-col">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {/* <p  ><FaShoppingBag color="#000" size={25} /></p> */}
              <div>
                <h1 className="footerhead1">Get In Touch </h1>
              </div>

              <div>
                <a className="footerhead2">+974 31062060</a>
              </div>
              <div>
                <a className="footerhead2">info@nasshopy.com</a>
              </div>
            </div>
          </Col>
          <Col sm={6} xs={12} md={3} className="footer-col">
            <div className="footer-company-logo"></div>
          </Col>
        </Row>
      </Container>
      <div className="copyright-div" style={{display:'flex',justifyContent:"center"}}>
        <p className="footer-copyright">
          Copyright © 2024 Nasshopy. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
