import React, { useState, useEffect } from "react";
import "./Cart.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { BsPlus } from "react-icons/bs";
import { HiOutlineMinusSm } from "react-icons/hi";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  adjustItemQty,
  removeFromCart,
} from "../../redux/Shopping/shopping-actions";

import img from "../../Assets/Images/noimages.png";

function CartItem({ item, adjustQty, removeFromCart }) {
  console.log(item, "cartitem item");
  let history = useHistory();

  const [input, setInput] = useState(item.qty);
  const [cartCount, setCartCount] = useState(0);
  const [image, setImage] = useState({});
  const [images, setImages] = useState("");

  const increment = () => {
    setInput((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    if (input > 1) {
      setInput((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    console.log("item cart", item);
  }, [item]);

  useEffect(() => {
    adjustQty(item.id.batchid, input);
  }, [input, item.id.batchid, image]);

  return (
    <div className="style" style={{ fontFamily: "'lato', sans-serif" }}>
      <div className="cartTop1">
        <div
          style={{ width: "100%", height: 1, backgroundColor: "#d3d3d3" }}
        ></div>
        {item.id.product_fetchiscombo == 0 ? (
          <div style={{ padding: 20 }}>
            <Row>
              <Col xs={12} md={6}>
                <div>
                  <Row>
                    <Col xs={12} lg={4}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {item.id.images == null ? (
                          <img
                            alt="img"
                            src={img}
                            style={{ width: 120, height: 120 }}
                          />
                        ) : (
                          <img
                            alt="img"
                            src={
                              global.images +
                              
                              "/ecommerce_photos/" +
                              item.id.images[0].pei_image
                            }
                            style={{
                              width: 120,
                              height: 120,
                              borderRadius: "15px",
                            }}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs={12} lg={8}>
                      <div className="cart-prodiv">
                        <p
                          className="item-name"
                          // style={{
                          //   fontSize: "19px",
                          //   marginTop: 15,
                          //   fontFamily: "'inter', sans-serif",
                          //   fontWeight: 500,
                          //   color: "#000",
                          //   lineHeight: "normal",
                          //   fontStyle: "normal",
                          // }}
                        >
                          {item.id.product_name}
                        </p>
                        <p
                          className="item-size"
                          // style={{
                          //   color: "#5a5a5a",
                          //   fontSize: "16px",
                          //   fontFamily: "'inter', sans-serif",
                          //   fontWeight: 400,
                          //   lineHeight: "normal",
                          //   fontStyle: "normal",
                          // }}
                        >
                          {" "}
                          {item.id.size_name}
                        </p>
                        <p
                          className="item-price"
                          // style={{
                          //   color: "#0b9547",
                          //   fontSize: "19px",
                          //   fontFamily: "'inter', sans-serif",
                          //   fontWeight: 600,
                          //   lineHeight: "normal",
                          //   fontStyle: "normal",
                          // }}
                        >
                          {item.id.currency} {item.id.pb_salesrate}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div>
                  <Row>
                    <Col xs={12} md={6}></Col>
                    <Col xs={12} md={6}>
                      <div className="cart-qty">
                        <div style={{ display: "flex" }}>
                          <div
                            className="qty"
                            style={{ fontFamily: "'inter', sans-serif" }}
                          >
                            QTY
                          </div>

                          <div className="decr" onClick={decrement}>
                            -
                          </div>
                          <p
                            style={{
                              fontFamily: "'inter', sans-serif",
                              marginLeft: 10,
                              marginRight: 20,
                              fontSize: 20,
                              color: "black",
                            }}
                          >
                            {input}
                          </p>
                          <div className="incr" onClick={increment}>
                            +
                          </div>
                        </div>
                      </div>
                      {/* </Col>
                                    <Col xs={12} md={6}> */}
                      <div>
                        <p
                          className="remove"
                          onClick={() => removeFromCart(item.id)}
                          style={{
                            fontSize: 14,
                            textAlign: "center",
                            cursor: "pointer",
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          {/* <RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} />  */}
                          Remove item
                        </p>
                      </div>
                    </Col>
                  </Row>
                  {/* {index !== item.id.length - 1 && <div className="line"></div>} */}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          item.id.map((product) => (
            <div key={product.products_id} style={{ padding: 20 }}>
              <Row>
                <Col xs={12} md={6}>
                  <div>
                    <Row>
                      <Col xs={12} lg={4}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {product.product_comboimage == null ? (
                            <img
                              alt="img"
                              src={img}
                              style={{
                                width: 120,
                                height: 120,
                                borderRadius: "15px",
                              }}
                            />
                          ) : (
                            <img
                              alt="img"
                              src={
                                global.images +
                                
                                "/combo_photos/" +
                                product.product_comboimage
                              }
                              style={{
                                width: 120,
                                height: 120,
                                borderRadius: "15px",
                              }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={8}>
                        <div className="cart-prodiv">
                          <p
                            style={{
                              fontSize: 15,
                              marginBottom: "unset",
                              marginTop: 15,
                              fontWeight: 500,
                            }}
                          >
                            {product.product_name}
                          </p>
                          {/* <p style={{fontSize: 13, color: '#a9a9a9',fontWeight:500}}>{item.id.color_name}, {item.id.size_name}</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="cart-qty">
                          <div style={{ display: "flex" }}>
                            <div onClick={decrement} className="cart-decr">
                              -
                            </div>
                            <p
                              style={{
                                marginBottom: 0,
                                marginLeft: 10,
                                marginRight: 10,
                                fontSize: 14,
                                marginTop: 2,
                              }}
                            >
                              {input}
                            </p>
                            <div onClick={increment} className="cart-decr">
                              +
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <p>QAR {product.product_comboprice}</p>
                        <div>
                          <p
                            className="remove"
                            onClick={() => removeFromCart(item.id)}
                            style={{
                              fontSize: 14,
                              textAlign: "center",
                              cursor: "pointer",
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            {/* <RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} />  */}
                            Remove item
                          </p>
                          {/* <p onClick={() => removeFromCart(item.id)} style={{ fontSize: 14, textAlign: 'center', cursor: 'pointer' }}><RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} /> Remove </p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          ))
        )}
      </div>
      {/* .............mobile view................... */}

      <div className="mobcartTop">
        <div
          style={{ width: "100%", height: 1, backgroundColor: "#d3d3d3" }}
        ></div>
        {item.id.product_fetchiscombo == 0 ? (
          <div style={{ padding: 20 }}>
            <Row>
              <Col xs={4}>
                <div className="cart-prodiv">
                  <p
                    className="mob-name"
                    // style={{
                    //   fontSize: "15px",
                    //   paddingTop: "8px",
                    //   fontFamily: "'inter', sans-serif",
                    //   fontWeight: 500,
                    //   color: "#000",
                    //   lineHeight: "normal",
                    //   fontStyle: "normal",
                    // }}
                  >
                    {item.id.product_name}
                  </p>
                  <p
                    className="mob-size"
                    // style={{
                    //   color: "#5a5a5a",
                    //   fontSize: "13px",
                    //   fontFamily: "'inter', sans-serif",
                    //   fontWeight: 400,
                    //   lineHeight: "normal",
                    //   fontStyle: "normal",
                    //   paddingTop: "8px",
                    // }}
                  >
                    {" "}
                    {item.id.size_name}
                  </p>
                  <p
                    className="mob-price"
                    // style={{
                    //   color: "#0b9547",
                    //   fontSize: "15px",
                    //   fontFamily: "'inter', sans-serif",
                    //   fontWeight: 600,
                    //   lineHeight: "normal",
                    //   fontStyle: "normal",
                    //   paddingTop: "8px",
                    // }}
                  >
                    {item.id.currency} {item.id.pb_salesrate}
                  </p>
                </div>
              </Col>
              <Col xs={4}>
                <div className="qtycol">
                  <div
                    className="qty"
                    style={{ fontFamily: "'inter', sans-serif" }}
                  >
                    QTY
                  </div>

                  <div className="cart-qty">
                    <div style={{ display: "flex" }}>
                      <div className="decr" onClick={decrement}>
                        -
                      </div>
                      <p
                        className="itemqty"
                        style={{
                          fontFamily: "'inter', sans-serif",
                          marginLeft: 10,
                          marginRight: 20,
                          fontSize: 20,
                          color: "black",
                        }}
                      >
                        {input}
                      </p>
                      <div className="incr" onClick={increment}>
                        +
                      </div>
                    </div>
                  </div>
                  {/* </Col>
                                    <Col xs={12} md={6}> */}
                </div>
              </Col>
              <Col xs={4}>
                <div
                  className="imgalign"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {item.id.images == null ? (
                    <img
                      alt="img"
                      src={img}
                      style={{ width: 85, height: 85 }}
                    />
                  ) : (
                    <img
                      alt="img"
                      src={
                        global.images +
                          
                        "/ecommerce_photos/" +
                        item.id.images[0].pei_image
                      }
                      style={{ width: 85, height: 85, borderRadius: "17px" }}
                    />
                  )}
                </div>
                <div>
                  <p
                    className="remove"
                    onClick={() => removeFromCart(item.id)}
                    style={{
                      fontSize: 13,
                      textAlign: "center",
                      cursor: "pointer",
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 400,
                      paddingTop: 10,
                    }}
                  >
                    {/* <RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} />  */}
                    Remove item
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          item.id.map((product) => (
            <div key={product.products_id} style={{ padding: 20 }}>
              <Row>
                <Col xs={12} md={6}>
                  <div>
                    <Row>
                      <Col xs={12} lg={4}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {product.product_comboimage == null ? (
                            <img
                              alt="img"
                              src={img}
                              style={{
                                width: 120,
                                height: 120,
                                borderRadius: "15px",
                              }}
                            />
                          ) : (
                            <img
                              alt="img"
                              src={
                                global.images +
                                 
                                "/combo_photos/" +
                                product.product_comboimage
                              }
                              style={{
                                width: 120,
                                height: 120,
                                borderRadius: "15px",
                              }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={8}>
                        <div className="cart-prodiv">
                          <p
                            className="item-name"
                            // style={{
                            //   fontSize: 15,
                            //   marginBottom: "unset",
                            //   marginTop: 15,
                            //   fontWeight: 500,
                            // }}
                          >
                            {product.product_name}
                          </p>
                          {/* <p style={{fontSize: 13, color: '#a9a9a9',fontWeight:500}}>{item.id.color_name}, {item.id.size_name}</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="cart-qty">
                          <div style={{ display: "flex" }}>
                            <div onClick={decrement} className="cart-decr">
                              -
                            </div>
                            <p
                              style={{
                                marginBottom: 0,
                                marginLeft: 10,
                                marginRight: 10,
                                fontSize: 14,
                                marginTop: 2,
                              }}
                            >
                              {input}
                            </p>
                            <div onClick={increment} className="cart-decr">
                              +
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <p
                          style={{
                            color: "#FF5722",
                            fontSize: 18,
                            fontWeight: 500,
                            textAlign: "center",
                            marginTop: 20,
                          }}
                        >
                          QAR {product.product_comboprice}
                        </p>
                        <div>
                          <p
                            className="remove"
                            onClick={() => removeFromCart(item.id)}
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                              cursor: "pointer",
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                              paddingTop: 10,
                            }}
                          >
                            {/* <RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} />  */}
                            Remove item
                          </p>
                          {/* <p onClick={() => removeFromCart(item.id)} style={{ fontSize: 14, textAlign: 'center', cursor: 'pointer' }}><RiDeleteBin5Line color="#000" size={17} style={{ marginTop: -4 }} /> Remove</p> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
    removeFromCart: (id) => dispatch(removeFromCart(id)),
  };
};

export default connect(null, mapDispatchToProps)(CartItem);
