import React, {useEffect} from 'react';
import './RelatedItems.css'
import {useHistory} from 'react-router-dom';
import img from '../../Assets/Images/noimages.png';
import {Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";

function Deals(props){
  let history = useHistory();
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      // autoplay: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1
    };


    return(
        <div style={{fontFamily: "'lato', sans-serif"}}>
            <Row>
                {props.data.map((item,index) => {
                    console.log(item,"item related");
                    return(
                        item.images.length == 0 ? null :
                    <Col xs={6} md={3} lg={2} style={{marginTop: 10}} className="spacereduce">
                        <a onClick={() => history.push('/details/' + item.products_id + '/' + item.pb_id )}>
                          <div className='img-border'>
                            <Slider {...settings} className="spclImg">
                                {item.images.length == 0 ?
                                      <div >
                                          <img alt="img" src={img} style={{width: '100%', height: 180}} />
                                      </div> :
                                    
                                    item.images.map((product) => {
                                    return(
                                        <img alt="img" src={global.images  + "/ecommerce_photos/" + product.pei_image} style={{width: '100%',height: 150}} />
                                    )
                                    })
                                     
                                  }              
                              </Slider>            
                              <div style={{marginTop: 10, position: 'relative'}}>
                                    
                                  <p className='product-name' style={{fontWeight:600}}>{item.product_name}</p>
                                  <p style={{fontSize: 15, fontWeight: 600, marginBottom: 'unset'}}><span style={{fontWeight:600 , color:'#00a085',fontSize: 14}}>QAR</span> {item.pb_salesrate}</p>
                                  {/* <p className='arrival-para3'><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>QAR {item.pb_salesrate}</span></p> */}
                              </div>
                          </div>
                        </a>
                    </Col>
                    )
                })}
            </Row>
        </div>
    );
}
export default Deals;