// import React, { useState, useEffect,useCallback} from "react";
// import "./Cart.css";
// import {Container, Row, Col, Button, Form } from "react-bootstrap";
// import {useHistory} from 'react-router-dom';
// import { connect } from "react-redux";
// import Skeleton from '@mui/material/Skeleton';
// import {
//     refresh
//   } from "../../redux/Shopping/shopping-actions";

// import Header from '../../Components/Header/header';
// import Footer from '../../Components/Footer/Footer';
// import Img from '../../Assets/Images/products/shoe1.png';
// import { RiDeleteBin5Line } from "react-icons/ri";
// import Cartitem from './Cartitem';
// import img from '../../Assets/Images/noimages.png';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/material/Alert';
// import Loader from '../../Components/Loader/Loader'

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// function Cart({cart, refresh}) {
//     let history = useHistory();
//     const [cartCount, setCartCount] = useState(0);
//     const [total, setTotal] = useState(0);
//     const [logtoken, setLogtoken] = useState(false)
//     const [cartList, setCartList] = useState([])
//     const [comboCartList,setComboCartList] = useState([]);
//     const [open, setOpen] = React.useState(false);
//     const [cartQty, setCartQty] = useState('');
//     const [cartTotal, setCartTotal] = useState('');
//     const [shippingCharge, setShippingcharge] = useState(10);
//     const [message, setMessage] = useState("");

//     const [loader, setLoader] = useState(false);

//       useEffect(() => {
//         let count = 0;
//         let total = 0;
//         cart.forEach((item) => {
//           count += item.qty;
//           total += item.qty * item.id.pb_salesrate;
//         });

//         setCartCount(count);
//         setTotal(total);

//       }, [cart, cartCount.toExponential]);

//       useEffect(() => {

//         var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
//         setLogtoken(logged)
//         console.log('loader', loader)
//       },[cartList, comboCartList,loader])

//     const increm = (id) => {
//         var token = JSON.parse(localStorage.getItem("token"))
//         var data = {
//             "operation": "plus",
//             "cartid": id
//         }
//          fetch(global.url + "displayCartfrontend", {
//              method: "POST",
//              headers: {
//                  'Content-Type': 'application/json',
//                  'APPKEY': 'erp6129',
//                  'Authorization' : 'Bearer ' + token
//              },
//              body: JSON.stringify(data)
//          })
//          .then((response)=> response.json())
//          .then((responseJson)=>{
//              if(responseJson.error == false){
//                 setCartList(responseJson.singleCart);
//                 setComboCartList(responseJson.ComboCart)
//                 refresh("")
//              }
//              else{
//                  setOpen(true)
//                  setMessage(responseJson.message)
//              }

//          })
//          .catch((error)=>{
//              console.log(error)
//          })
//     }

//     const decrem = (id) => {
//         var token = JSON.parse(localStorage.getItem("token"))
//         var data = {
//             "operation": "minus",
//             "cartid": id
//         }
//          fetch(global.url + "displayCartfrontend", {
//              method: "POST",
//              headers: {
//                  'Content-Type': 'application/json',
//                  'APPKEY': 'erp6129',
//                  'Authorization' : 'Bearer ' + token
//              },
//              body: JSON.stringify(data)
//          })
//          .then((response)=> response.json())
//          .then((responseJson)=>{

//              if(responseJson.error == false){
//                 setCartList(responseJson.singleCart);
//                 setComboCartList(responseJson.ComboCart);
//                 refresh("")
//              }
//              else{
//                  setOpen(true)
//                  setMessage(responseJson.message)
//              }

//          })
//          .catch((error)=>{
//              console.log(error)
//          })
//     }

//     // const getCartData = () => {
//     //     // setLoader(true)
//     //     var token =JSON.parse(localStorage.getItem("token"))
//     //     fetch(global.url + "displayCartfrontend", {
//     //         method: "POST",
//     //          headers: {
//     //              'Content-Type': 'application/json',
//     //              'APPKEY': 'erp6129',
//     //              'Authorization' : 'Bearer ' + token
//     //          },
//     //     })
//     //     .then((response) => response.json())
//     //     .then((responseJson) => {
//     //         console.log(responseJson.ComboCart ,"combo cart")
//     //         // setLoader(false)
//     //       if(responseJson.error == false){
//     //         setCartList(responseJson.singleCart);
//     //         setComboCartList(responseJson.ComboCart)
//     //         setCartQty(responseJson.cart_count)
//     //         setCartTotal(responseJson.total)
//     //       }
//     //       else{
//     //         setCartList([]);
//     //         setComboCartList([]);
//     //         setCartQty(0)
//     //         setCartTotal(0)
//     //       }
//     //     })
//     //     .catch((error)=>{
//     //         console.log(error)
//     //     })
//     // }
//     const updateCartData = useCallback((data) => {
//         if (data.error === false) {
//           setCartList(data.singleCart);
//           setComboCartList(data.ComboCart);
//           setCartQty(data.cart_count);
//           setCartTotal(data.total);
//         } else {
//           setCartList([]);
//           setComboCartList([]);
//           setCartQty(0);
//           setCartTotal(0);
//         }
//       }, []);

//       const getCartData = useCallback(() => {
//         var token = JSON.parse(localStorage.getItem("token"));
//         fetch(global.url + "displayCartfrontend", {
//           method: "POST",
//           headers: {
//             'Content-Type': 'application/json',
//             'APPKEY': 'erp6129',
//             'Authorization': 'Bearer ' + token
//           },
//         })
//           .then((response) => response.json())
//           .then((responseJson) => {
//             console.log(responseJson, "combo cartfff");
//             updateCartData(responseJson);
//           })
//           .catch((error) => {
//             console.log(error);
//             updateCartData({ error: true });
//           });
//       }, [updateCartData]);

//       useEffect(() => {
//         getCartData()
//     //   },[cartList , comboCartList])
//       },[getCartData])

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') {
//           return;
//         }

//         setOpen(false);
//       };

//     const removeCart = (id,isCombo = false ) => {
//         var token = JSON.parse(localStorage.getItem("token"))
//         var data = {
//             cartid : id
//         }
//         if(isCombo){
//             data.iscombo = 1;
//         }

//         fetch(global.url + "deleteCartfrontend", {
//             method: "POST",
//             headers: {
//                 'Content-Type' : 'application/json',
//                 'APPKEY' : 'erp6129',
//                 'Authorization' : 'Bearer ' + token
//             },
//             body: JSON.stringify(data)
//         })
//         .then((response) => response.json())
//         .then((responseJson) => {
//           if(responseJson.error == false){
//             setOpen(true);
//             getCartData()
//             setMessage(responseJson.message)
//             refresh("")
//           }
//           else{
//             refresh("")
//             setOpen(true);
//             getCartData()
//             setMessage(responseJson.message)
//           }
//         })
//         .catch((error)=>{
//             console.log(error)
//         })
//     }

//     return(
//         <div>
//             <div className="cartTop" >
//                 <Container>
//                     {/* cart from server */}
//                     {logtoken ?
//                         <div>
//                             {loader ?
//                             //  <Row style={{marginTop: 20}}>
//                             //     <Col  xs={12} md={7} lg={8}>
//                             //         <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
//                             //         <Skeleton sx={{ height: 150, marginTop: 2 }} animation="wave" variant="rectangular" />
//                             //     </Col>
//                             //     <Col  xs={12} md={5} lg={4}>
//                             //         <Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />
//                             //     </Col>
//                             // </Row>
//                             <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
//                                 <div style={{marginTop: 50}}>
//                                     <Loader />
//                                 </div>
//                             </div>
//                             :
//                             <Row>
//                                 <Col xs={12} md={7} lg={8}>
//                                     <div className='cart-div2'>
//                                         <div style={{padding: 20}}>
//                                             <p style={{ fontSize: 18, marginBottom: 'unset',fontFamily: "'lato', sans-serif", fontWeight: 500}}>{cartQty} Item(s) in your bag</p>
//                                         </div>
//                                         {cartQty == 0 ?
//                                             <p style={{textAlign: 'center', fontSize: 19,fontFamily: "'lato', sans-serif", fontWeight: 500}}>Your Cart Is Empty</p>:
//                                         <div>
//                                         {
//                                             // loader ?
//                                             // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

//                                             cartList.map((item) => (
//                                             <div>
//                                                 <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                                 <div style={{padding: 20}}>
//                                                     <Row>
//                                                         <Col xs={12} md={6}>
//                                                             <div>
//                                                                 <Row>
//                                                                     <Col xs={12} lg={4}>
//                                                                         <div style={{display: 'flex', justifyContent: 'center'}}>
//                                                                             {
//                                                                                 item.image == null ?
//                                                                                 <img alt="img" src={img} style={{width: 120, height: 120}} /> :
//                                                                                 <img alt="img" src={global.images + "ecommerce_photos/" + item.image} style={{width: 120, height: 120}} />
//                                                                             }
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col xs={12} lg={8}>
//                                                                         <div className='cart-prodiv'>
//                                                                             <p style={{fontSize: 15, marginBottom: 'unset', marginTop: 15,fontFamily: "'lato', sans-serif", fontWeight: 400}}>{item.name}</p>
//                                                                             <p style={{ color: '#a9a9a9',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>{item.color}, {item.size}</p>
//                                                                         </div>
//                                                                     </Col>
//                                                                 </Row>
//                                                             </div>
//                                                         </Col>
//                                                         <Col xs={12} md={6}>
//                                                             <div>
//                                                                 <Row>
//                                                                     <Col xs={12} md={6}>
//                                                                         <div className='cart-qty'>
//                                                                             <div style={{display: 'flex'}}>
//                                                                                 <div onClick={() => decrem(item.cart_id)} className='cart-decr'>-</div>
//                                                                                 <p style={{marginBottom: 0, marginLeft:10, marginRight: 10, fontSize: 14, marginTop: 2}}>{item.quantity}</p>
//                                                                                 <div  onClick={() => increm(item.cart_id)} className='cart-decr'>+</div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col xs={12} md={6}>
//                                                                         <p style={{color: '#FF5722', fontSize: 18,  textAlign: 'center',  marginTop: 20,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {item.price}</p>
//                                                                         <a onClick={() =>removeCart(item.cart_id ,item.iscombo)}>
//                                                                             <p style={{fontSize: 14, textAlign: 'center', cursor: 'pointer',fontFamily: "'lato', sans-serif", fontWeight: 400}}><RiDeleteBin5Line  color="#000" size={17} style={{marginTop: -4}} /> Remove</p>
//                                                                         </a>
//                                                                     </Col>
//                                                                 </Row>
//                                                             </div>
//                                                         </Col>
//                                                     </Row>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                         {
//                                             // loader ?
//                                             // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

//                                             comboCartList.map((item) => (
//                                             <div>
//                                                 <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                                 <div style={{padding: 20}}>
//                                                     <Row>
//                                                         <Col xs={12} md={6}>
//                                                             <div>
//                                                                 <Row>
//                                                                     <Col xs={12} lg={4}>
//                                                                         <div style={{display: 'flex', justifyContent: 'center'}}>
//                                                                             {
//                                                                                 item.image == null ?
//                                                                                 <img alt="img" src={img} style={{width: 120, height: 120}} /> :
//                                                                                 <img alt="img" src={global.images + "combo_photos/" + item.image} style={{width: 120, height: 120}} />
//                                                                             }
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col xs={12} lg={8}>
//                                                                         <div className='cart-prodiv'>
//                                                                             <p style={{fontSize: 15, marginBottom: 'unset', marginTop: 15,fontFamily: "'lato', sans-serif", fontWeight: 400}}>{item.name}</p>
//                                                                             <p style={{fontSize: 13, color: '#a9a9a9',fontFamily: "'lato', sans-serif", fontWeight: 400}}>{item.color}, {item.size}</p>
//                                                                         </div>
//                                                                     </Col>
//                                                                 </Row>
//                                                             </div>
//                                                         </Col>
//                                                         <Col xs={12} md={6}>
//                                                             <div>
//                                                                 <Row>
//                                                                     <Col xs={12} md={6}>
//                                                                         <div className='cart-qty'>
//                                                                             <div style={{display: 'flex'}}>
//                                                                                 <div onClick={() => decrem(item.cart_id)} className='cart-decr'>-</div>
//                                                                                 <p style={{marginBottom: 0, marginLeft:10, marginRight: 10, fontSize: 14, marginTop: 2}}>{item.quantity}</p>
//                                                                                 <div  onClick={() => increm(item.cart_id)} className='cart-decr'>+</div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </Col>
//                                                                     <Col xs={12} md={6}>
//                                                                         <p style={{color: '#FF5722', fontSize: 18,  textAlign: 'center',  marginTop: 20,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {item.price}</p>
//                                                                         <a onClick={() =>removeCart(item.cart_id ,item.iscombo)}>
//                                                                             <p style={{fontSize: 14, textAlign: 'center', cursor: 'pointer',fontFamily: "'lato', sans-serif", fontWeight: 400}}><RiDeleteBin5Line  color="#000" size={17} style={{marginTop: -4}} /> Remove</p>
//                                                                         </a>
//                                                                     </Col>
//                                                                 </Row>
//                                                             </div>
//                                                         </Col>
//                                                     </Row>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                         </div>
//                                             }
//                                     </div>
//                                 </Col>
//                                 <Col xs={12} md={5} lg={4}>

//                                     <div className='cart-div2'>
//                                         <div style={{padding: 20}}>
//                                             <p style={{ fontSize: 18, marginBottom: 'unset', cursor: 'pointer', fontWeight: 600 ,fontFamily: "'lato', sans-serif"}}>Order Summary</p>
//                                         </div>
//                                         <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                         <div style={{padding: 20}}>
//                                             <div style={{display: 'flex', justifyContent: 'space-between'}}>
//                                                 <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}} >Subtotal ( {cartQty} Items )</p>
//                                                 <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {cartTotal}</p>
//                                             </div>
//                                             <div style={{display: 'flex', justifyContent: 'space-between'}}>
//                                                 <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Total shipping Charge</p>
//                                                 {cartTotal < 200 ?
//                                                     <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {shippingCharge}</p>:
//                                                     <p className="cart-checkoutpara" style={{color: '#00a085',fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Free</p>
//                                                 }

//                                                 {/* <p style={{fontSize: 13, color: '#00a085', marginTop: 4, fontWeight: 'bold'}}>QAR {shippingCharge}</p> */}
//                                             </div>
//                                             <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                             <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13}}>
//                                                 <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Amount Payable</p>
//                                                 {cartTotal < 200 ?
//                                                     <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {cartTotal + shippingCharge}</p>:
//                                                     <p className="cart-checkoutpara" style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {cartTotal}</p>
//                                                 }
//                                             </div>
//                                             <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                             {logtoken ?
//                                                 <button onClick={() => history.push('/checkout')} type="button" class="btn btn-primary" style={{backgroundColor: '#00a085', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Checkout</button>:
//                                                 <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#00a085', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Checkout</button>
//                                             }
//                                             <a onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
//                                                 <p style={{color: '#00a085', fontSize: 14, fontWeight: 'bold', marginTop: 20, textAlign: 'center',fontFamily: "'lato', sans-serif", fontWeight: 400}}>Continue Shopping</p>
//                                             </a>
//                                         </div>
//                                     </div>

//                                 </Col>
//                             </Row>
//                             }
//                         </div>
//                         :<Row>
//                             {/* cart from redux */}
//                             <Col xs={12} md={7} lg={8}>
//                                 <div className='cart-div2'>
//                                     <div style={{padding: 20}}>
//                                         <p style={{ fontSize: 18, marginBottom: 'unset',fontFamily: "'lato', sans-serif", fontWeight: 500}}>{cartCount} Item(s) in your bag</p>
//                                     </div>
//                                     {cartCount == 0 ?
//                                             <p style={{textAlign: 'center', fontSize: 19,fontFamily: "'lato', sans-serif", fontWeight: 500}}>Your Cart Is Empty</p>:
//                                     <div>
//                                         {cart.map((item) => (
//                                             <Cartitem key={item.id} item={item}/>
//                                         ))}
//                                     </div>}
//                                 </div>
//                             </Col>
//                             <Col xs={12} md={5} lg={4}>

//                                 <div className='cart-div2'>
//                                     <div style={{padding: 20}}>
//                                         <p style={{  marginBottom: 'unset',fontSize: 18,  cursor: 'pointer', fontWeight: 600 ,fontFamily: "'lato', sans-serif"}}>Order Summary</p>
//                                     </div>
//                                     <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                     <div style={{padding: 20}}>
//                                         <div style={{display: 'flex', justifyContent: 'space-between'}}>
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Subtotal ( {cartCount} Items )</p>
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {total}</p>
//                                         </div>
//                                         <div style={{display: 'flex', justifyContent: 'space-between'}}>
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Total shipping Charge</p>
//                                             {total < 200 ?
//                                                 <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {shippingCharge}</p>:
//                                                 <p className="cart-checkoutpara" style={{color: '#00a085',fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Free</p>
//                                             }
//                                         </div>
//                                         <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                         <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13}}>
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Amount Payable</p>
//                                             {total < 200 ?
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {total + shippingCharge}</p>:
//                                             <p className="cart-checkoutpara"  style={{fontSize: 14 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>QAR {total}</p>}
//                                         </div>
//                                         <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
//                                         {logtoken ?
//                                             <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#00a085', width: '100%' ,fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Checkout</button>:
//                                             <button onClick={() => history.push('/login')} type="button" class="btn btn-primary" style={{backgroundColor: '#00a085', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Checkout</button>
//                                         }
//                                         <a onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
//                                             <p style={{color: '#00a085', marginTop: 20, textAlign: 'center',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}>Continue Shopping</p>
//                                         </a>
//                                     </div>
//                                 </div>

//                             </Col>
//                         </Row>
//                     }
//                 </Container>
//                 <Snackbar
//                 anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
//                 open={open} autoHideDuration={3000} onClose={handleClose}>
//                     <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
//                     {message}
//                     </Alert>
//                 </Snackbar>
//             </div>
//         </div>
//     );
// }

// const mapStateToProps = (state) => {
//     return {
//         cart: state.shop.cart,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return {
//       refresh: (item) => dispatch(refresh(item))
//     };
//   };

// export default connect(mapStateToProps, mapDispatchToProps)(Cart);

import React, { useState, useEffect } from "react";
import "./Cart.css";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { refresh } from "../../redux/Shopping/shopping-actions";

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/Footer";
import { RiDeleteBin5Line } from "react-icons/ri";
import Cartitem from "./Cartitem";
import img from "../../Assets/Images/noimages.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../../Components/Loader/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Cart({ cart, refresh }) {
  console.log(cart, "998");
  let history = useHistory();
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [logtoken, setLogtoken] = useState(false);
  const [cartList, setCartList] = useState([]);
  const [comboCartList, setComboCartList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cartQty, setCartQty] = useState("");
  const [cartTotal, setCartTotal] = useState("");
  const [shippingCharge, setShippingcharge] = useState(10);
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  console.log(cartTotal,"cart total")
  const [cartData, setCartData] = useState({
    singleCart: [],
    comboCart: [],
    // total: 0,
    // cartCount: 0,
  });

  //   useEffect(() => {
  //     console.log("useEffect triggered");
  //     console.log("cartData:", cartData);
  //     if (logtoken) {
  //       if (!cartData.singleCart.length && !cartData.comboCart.length) {
  //         getCartData();
  //       }
  //     }
  //   }, [cartData.singleCart, cartData.comboCart, logtoken]);

  useEffect(() => {
    getCartData();
  }, []);

  // useEffect(() => {
  //     console.log('Calling getCartData');
  //     if(logtoken){
  //         getCartData();
  //     }

  // }, [logtoken]);

  useEffect(() => {
    let count = 0;
    let singleproductprice = 0;
    let comboproductprice = 0;
    let total = 0;
    cart.forEach((item) => {
      const currentItem = Array.isArray(item.id) ? item.id[0] : item.id;
      console.log(currentItem, "current item");
      if (currentItem.product_fetchiscombo == 1) {
        comboproductprice += item.qty * currentItem.product_comboprice;
        console.log(currentItem.product_comboprice, "combo price");
      } else if (currentItem.pb_salesrate) {
        singleproductprice += item.qty * currentItem.pb_salesrate;
        console.log(singleproductprice, "single product price");
      }

      console.log(item, "cart foreach item");
      count += item.qty;
    });
    total = comboproductprice + singleproductprice;
    console.log(total, "total price");
    setCartCount(count);
    setTotal(total);
  }, [cart, cartCount.toExponential]);

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    setLogtoken(logged);
    console.log("loader", loader);
  }, []);

  // useEffect(() => {
  //     console.log('Effect triggered');
  //     getCartData()
  // }, [cartList, comboCartList])

  const increm = (id) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      operation: "plus",
      cartid: id,
    };
    fetch(global.url + "displayCartfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          console.log(responseJson, "increm");
          // setCartList(responseJson.singleCart);
          // setComboCartList(responseJson.ComboCart);
          setCartData({
            singleCart: responseJson.singleCart,
            comboCart: responseJson.ComboCart,
          });
          setCartQty(responseJson.cart_count);
          setCartTotal(responseJson.total);
          refresh("");
        } else {
          setOpen(true);
          setMessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const decrem = (id) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      operation: "minus",
      cartid: id,
    };
    fetch(global.url + "displayCartfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          console.log(responseJson, "decrem");
          setLoader(false);

          // setCartList(responseJson.singleCart);
          // setComboCartList(responseJson.ComboCart);
          setCartData({
            singleCart: responseJson.singleCart,
            comboCart: responseJson.ComboCart,
          });
          setCartQty(responseJson.cart_count);
          setCartTotal(responseJson.total);
          refresh("");
        } else {
          setOpen(true);
          setMessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getCartData = () => {
  //     console.log('Fetching cart data');
  //     setLoader(true)
  //     var token = JSON.parse(localStorage.getItem("token"))
  //     fetch(global.url + "displayCartfrontend", {
  //         method: "POST",
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'APPKEY': 'erp6129',
  //             'Authorization': 'Bearer ' + token
  //         },
  //     })
  //         .then((response) => response.json())
  //         .then((responseJson) => {
  //             console.log('API response received:', responseJson)
  //             setLoader(false)
  //             if (responseJson.error == false) {
  //                 console.log('Updating state with API response');
  //                 setCartList(responseJson.singleCart);
  //                 setComboCartList(responseJson.ComboCart)
  //                 setCartQty(responseJson.cart_count)
  //                 setCartTotal(responseJson.total)
  //             }
  //             else {
  //                 console.log('API error. Setting state to default values.');
  //                 setCartList([]);
  //                 setComboCartList([]);
  //                 setCartQty(0)
  //                 setCartTotal(0)
  //             }
  //         })
  //         .catch((error) => {
  //             console.log('Error during API fetch:', error);
  //             console.log(error)
  //         })
  // }

  const getCartData = async () => {
    console.log("Calling getCartData");
    setLoader(true);
    var token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await fetch(global.url + "displayCartfrontend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APPKEY: "erp6129",
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP status code: ${response.status}`);
      }

      const responseJson = await response.json();
      console.log("API response received:", responseJson);

      if (responseJson.error == false) {
        setLoader(false);

        console.log("Updating state with API response");
        console.log(responseJson, "cart res");
        setCartData({
          singleCart: responseJson.singleCart,
          comboCart: responseJson.ComboCart,
        });
        setCartQty(responseJson.cart_count);
        setCartTotal(responseJson.total);
      } else {
        console.log("API error. Setting state to default values.");
        setLoader(false);
        setCartData({
          singleCart: [],
          comboCart: [],
          total: 0,
          cartCount: 0,
        });
      }
    } catch (error) {
      console.error("Error during API fetch:", error.message);
      setLoader(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const removeCart = (id) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      cartid: id,
    };

    fetch(global.url + "deleteCartfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          setOpen(true);
          getCartData();
          setMessage(responseJson.message);
          // refresh("");
          window.location.reload();
        } else {
          refresh("");
          setOpen(true);
          getCartData();
          setMessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="cartTop1" style={{ marginTop: "140px" }}>
        <Container>
          {/* cart from server */}
          {logtoken ? (
            <div>
              {loader ? (
                //  <Row style={{marginTop: 20}}>
                //     <Col  xs={12} md={7} lg={8}>
                //         <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
                //         <Skeleton sx={{ height: 150, marginTop: 2 }} animation="wave" variant="rectangular" />
                //     </Col>
                //     <Col  xs={12} md={5} lg={4}>
                //         <Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />
                //     </Col>
                // </Row>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: 50 }}>
                    <Loader />
                  </div>
                </div>
              ) : (
                <Row>
                  <Col xs={12} md={7} lg={8}>
                    <div className="cart-div2">
                      <div style={{ padding: 20 }} className="headsummary">
                        {/* <p style={{ fontSize: 18, marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{cartQty} Item(s) in your bag</p> */}
                        <p className="ordersummary">Order Summary</p>
                      </div>
                      {cartQty == 0 ? (
                        <p
                          className="empty"
                          style={{
                            textAlign: "center",
                            fontSize: 19,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 500,
                          }}
                        >
                          Your Cart Is Empty
                        </p>
                      ) : (
                        <div>
                          {
                            // loader ?
                            // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

                            cartData.singleCart.map((item, index) => (
                              // console.log(item,"singlecart data")
                              <div>
                                {/* <div className="divider-line" style={{ width: '100%', height: 1, backgroundColor: '#fffff' }}></div> */}
                                <div className="padding">
                                  <Row>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} lg={4}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {item.image == null ? (
                                                <img
                                                  alt="img"
                                                  src={img}
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  alt="img"
                                                  src={
                                                    global.images +
                                                     
                                                    "/ecommerce_photos/" +
                                                    item.image
                                                  }
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={12} lg={8}>
                                            <div className="cart-prodiv">
                                              <p
                                                className="item-name"
                                                // style={{
                                                //   fontSize: "19px",
                                                //   marginTop: 15,
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 500,
                                                //   color: "#000",
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {item.name}
                                              </p>
                                              <p
                                                className="item-size"
                                                // style={{
                                                //   color: "#5a5a5a",
                                                //   fontSize: "16px",
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 400,
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {item.size}
                                              </p>
                                              <p
                                                className="item-price"
                                                // style={{
                                                //   color: "#0b9547",
                                                //   fontSize: "19px",
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 600,
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                QAR {item.price}
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} md={6}>
                                            {/* <div className='cart-qty'>
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <div onClick={() => decrem(item.cart_id)} className='cart-decr'>-</div>
                                                                                                <p style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, fontSize: 14, marginTop: 2 }}>{item.quantity}</p>
                                                                                                <div onClick={() => increm(item.cart_id)} className='cart-decr'>+</div>
                                                                                            </div>
                                                                                        </div> */}
                                          </Col>
                                          <Col xs={12} md={6}>
                                            <div className="cart-qty">
                                              <div style={{ display: "flex" }}>
                                                <div
                                                  className="qty"
                                                  style={{
                                                    fontFamily:
                                                      "'inter', sans-serif",
                                                  }}
                                                >
                                                  QTY
                                                </div>
                                                <div
                                                  className="decr"
                                                  onClick={() =>
                                                    decrem(item.cart_id)
                                                  }
                                                  // className='cart-decr'
                                                >
                                                  -
                                                </div>
                                                <p
                                                  style={{
                                                    fontFamily:
                                                      "'inter', sans-serif",
                                                    marginLeft: 10,
                                                    marginRight: 20,
                                                    fontSize: 20,
                                                    color: "black",
                                                  }}
                                                >
                                                  {item.quantity}
                                                </p>

                                                <div
                                                  className="incr"
                                                  onClick={() =>
                                                    increm(item.cart_id)
                                                  }
                                                  //  className='cart-decr'
                                                >
                                                  +
                                                </div>
                                              </div>
                                            </div>
                                            <a
                                              onClick={() =>
                                                removeCart(item.cart_id)
                                              }
                                            >
                                              <p
                                                className="remove"
                                                style={{
                                                  fontSize: 14,
                                                  textAlign: "center",
                                                  cursor: "pointer",
                                                  fontFamily:
                                                    "'lato', sans-serif",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {/* <RiDeleteBin5Line color="#000" size={17} /> */}
                                                Remove item
                                              </p>
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  {index !== cartData.singleCart.length - 1 && (
                                    <div className="line"></div>
                                  )}
                                </div>
                              </div>
                            ))
                          }
                          {
                            // loader ?
                            // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

                            cartData.comboCart.map((item) => (
                              <div>
                                <div
                                  className="divider-line"
                                  style={{
                                    width: "100%",
                                    height: 1,
                                    backgroundColor: "#ffffff",
                                  }}
                                ></div>
                                <div style={{ padding: 20 }}>
                                  <Row>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} lg={4}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {item.image == null ? (
                                                <img
                                                  alt="img"
                                                  src={img}
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  alt="img"
                                                  src={
                                                    global.images +
                                                      
                                                    "/combo_photos/" +
                                                    item.image
                                                  }
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                    borderRadius: "15px",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={12} lg={8}>
                                            <div className="cart-prodiv">
                                              <p
                                                className="item-name"
                                                // style={{
                                                //   fontSize: "19px",
                                                //   marginTop: 15,
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 500,
                                                //   color: "#000",
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {item.name}
                                              </p>
                                              <p
                                                className="item-size"
                                                // style={{
                                                //   color: "#5a5a5a",
                                                //   fontSize: "16px",
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 400,
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {" "}
                                                {item.size}
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} md={6}>
                                            <div className="cart-qty">
                                              <div style={{ display: "flex" }}>
                                                <div
                                                  className="qty"
                                                  style={{
                                                    fontFamily:
                                                      "'inter', sans-serif",
                                                  }}
                                                >
                                                  QTY
                                                </div>

                                                <div
                                                  className="decr"
                                                  onClick={() =>
                                                    decrem(item.cart_id)
                                                  }
                                                >
                                                  -
                                                </div>
                                                <p
                                                  className="item-qty"
                                                  // style={{
                                                  //   fontFamily:
                                                  //     "'inter', sans-serif",
                                                  //   marginLeft: 10,
                                                  //   marginRight: 20,
                                                  //   fontSize: 20,
                                                  //   color: "black",
                                                  // }}
                                                >
                                                  {item.quantity}
                                                </p>
                                                <div
                                                  className="incr"
                                                  onClick={() =>
                                                    increm(item.cart_id)
                                                  }
                                                >
                                                  +
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={12} md={6}>
                                            <p
                                              className="item-price"
                                              // style={{
                                              //   color: "#0b9547",
                                              //   fontSize: "19px",
                                              //   fontFamily:
                                              //     "'inter', sans-serif",
                                              //   fontWeight: 600,
                                              //   lineHeight: "normal",
                                              //   fontStyle: "normal",
                                              // }}
                                            >
                                              QAR {item.price}
                                            </p>
                                            <a
                                              onClick={() =>
                                                removeCart(item.cart_id)
                                              }
                                            >
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  textAlign: "center",
                                                  cursor: "pointer",
                                                  fontFamily:
                                                    "'lato', sans-serif",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                <RiDeleteBin5Line
                                                  color="#000"
                                                  size={17}
                                                  style={{ marginTop: -4 }}
                                                />{" "}
                                                Remove item
                                              </p>
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={5} lg={4}>
                    <div className="cart-div2">
                      <div style={{ padding: 20 }}>
                        <p
                          className="item-pricedetails"
                          // style={{
                          //   fontSize: 18,
                          //   marginBottom: "unset",
                          //   cursor: "pointer",
                          //   fontWeight: 600,
                          //   fontFamily: "'inter', sans-serif",
                          // }}
                        >
                          Price Details
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "#d3d3d3",
                        }}
                      ></div>
                      <div style={{ padding: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            style={{
                              fontSize: 14,
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            Subtotal ( {cartQty} Items )
                          </p>
                          <p
                            className="cart-checkoutpara"
                            style={{
                              fontSize: 14,
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            QAR {cartTotal}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            style={{
                              fontSize: 14,
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            Total shipping Charge
                          </p>
                          {cartTotal < 200 ? (
                            <p
                              className="cart-checkoutpara"
                              style={{
                                fontSize: 14,
                                fontFamily: "'lato', sans-serif",
                                fontWeight: 400,
                              }}
                            >
                            { cartTotal == 0 || cartTotal == null ? `QAR 0` :  `QAR ${shippingCharge}`}
                             
                            </p>
                          ) : (
                            <p
                              className="cart-checkoutpara"
                              style={{
                                color: "#00a085",
                                fontSize: 14,
                                fontFamily: "'lato', sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              Free
                            </p>
                          )}

                          {/* <p style={{fontSize: 13, color: '#00a085', marginTop: 4, fontWeight: 'bold'}}>QAR {shippingCharge}</p> */}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 13,
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            style={{
                              fontSize: 14,
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                            }}
                          >
                            Amount Payable
                          </p>
                          {cartTotal < 200 ? (
                            <p
                              className="cart-checkoutpara"
                              style={{
                                fontSize: 14,
                                fontFamily: "'lato', sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              {cartTotal == 0 || cartTotal == null ? `QAR 0` : `QAR ${cartTotal + shippingCharge}`}
                            </p>
                          ) : (
                            <p
                              className="cart-checkoutpara"
                              style={{
                                fontSize: 14,
                                fontFamily: "'lato', sans-serif",
                                fontWeight: 400,
                              }}
                            >
                              { cartTotal == 0 || cartTotal == null  ? `QAR 0` :  `QAR ${cartTotal}`}
                              {/* QAR {cartTotal} */}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                        {logtoken ? (
                          <button
                            onClick={() => history.push("/checkout")}
                            type="button"
                            class="btn btn-primary"
                            style={{
                              backgroundColor: "#f9a719",
                              width: "100%",
                              fontSize: 13,
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400,
                              borderColor:'#f9a719'
                            }}
                          >
                            Checkout
                          </button>
                        ) : (
                          <button
                            onClick={() => history.push("/login")}
                            type="button"
                            className=" mob-checkoutbtn"
                          >
                            Checkout
                          </button>
                        )}
                        <a
                          onClick={() => history.push("/")}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            style={{
                              color: "#f9a719",
                              fontSize: 14,
                              fontWeight: "bold",
                              marginTop: 20,
                              textAlign: "center",
                              fontFamily: "'lato', sans-serif",
                              fontWeight: 400
                             
                            }}
                          >
                            Continue Shopping
                          </p>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <Row>
              {/* cart from redux */}
              <Col xs={12} md={7} lg={8}>
                <div className="cart-div2">
                  {/* ....................logtoken true.................. */}
                  <div style={{ padding: 20 }} className="headsummary">
                    {/* <p style={{ fontSize: 18, marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{cartQty} Item(s) in your bag</p> */}
                    <p
                      style={{ fontFamily: "'inter', sans-serif" }}
                      className="ordersummary"
                    >
                      Order Summary
                    </p>
                  </div>
                  {cartCount == 0 ? (
                    <p
                      className="empty"
                      style={{
                        textAlign: "center",
                        fontSize: 19,
                        fontFamily: "'inter', sans-serif",
                        fontWeight: 500,
                      }}
                    >
                      Your Cart Is Empty
                    </p>
                  ) : (
                    <div>
                      {cart.map((item) => (
                        <Cartitem key={item.id} item={item} />
                      ))}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <div className="cart-div2">
                  <div style={{ padding: 20 }}>
                    <p
                      style={{
                        marginBottom: "unset",
                        fontSize: 18,
                        cursor: "pointer",
                        fontWeight: 600,
                        fontFamily: "'inter', sans-serif",
                      }}
                    >
                      Price Details
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "#d3d3d3",
                    }}
                  ></div>
                  <div style={{ padding: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        style={{
                          fontSize: 14,
                          fontFamily: "'lato', sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Subtotal ( {cartCount} Items )
                      </p>
                      <p
                        className="cart-checkoutpara"
                        style={{
                          fontSize: 14,
                          fontFamily: "'lato', sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        QAR {total}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        style={{
                          fontSize: 14,
                          fontFamily: "'lato', sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Total shipping Charge
                      </p>
                      {total < 200 ? (
                        <p
                          className="cart-checkoutpara"
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                        >
                           { total == 0 || total == null ? `QAR 0` :  `QAR ${shippingCharge}`}
                        </p>
                      ) : (
                        <p
                          className="cart-checkoutpara"
                          style={{
                            color: "#00a085",
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          Free
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#d3d3d3",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 13,
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        style={{
                          fontSize: 14,
                          fontFamily: "'lato', sans-serif",
                          fontWeight: 400,
                        }}
                      >
                        Amount Payable
                      </p>
                      {total < 200 ? (
                        <p
                          className="cart-checkoutpara"
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          {total == 0 || total == null ? `QAR 0` : `QAR ${total + shippingCharge}`}
                        </p>
                      ) : (
                        <p
                          className="cart-checkoutpara"
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                        >
                          QAR {total}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#d3d3d3",
                      }}
                    ></div>
                    {logtoken ? (
                      <button
                        onClick={() => history.push("/checkout")}
                        type="button"
                        className="checkoutbtn"
                        // class="btn btn-primary "
                        // style={{
                        //   backgroundColor: "#00a085",
                        //   width: "100%",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Checkout
                      </button>
                    ) : (
                      <button
                        onClick={() => history.push("/login")}
                        type="button"
                        className="checkoutbtn"
                        // class="btn btn-primary"
                        // style={{
                        //   backgroundColor: "#00a085",
                        //   width: "100%",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Checkout
                      </button>
                    )}
                    <a
                      onClick={() => history.push("/")}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mob-continueshoppingbtn"
                        // style={{
                        //   color: "#00a085",
                        //   marginTop: 20,
                        //   textAlign: "center",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Continue Shopping
                      </p>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>

      {/* .................. */}
      <div className="mobcartTop">
        <Container>
          {/* cart from server */}
          {logtoken ? (
            <div>
              {loader ? (
                //  <Row style={{marginTop: 20}}>
                //     <Col  xs={12} md={7} lg={8}>
                //         <Skeleton sx={{ height: 150 }} animation="wave" variant="rectangular" />
                //         <Skeleton sx={{ height: 150, marginTop: 2 }} animation="wave" variant="rectangular" />
                //     </Col>
                //     <Col  xs={12} md={5} lg={4}>
                //         <Skeleton sx={{ height: 320 }} animation="wave" variant="rectangular" />
                //     </Col>
                // </Row>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: 50 }}>
                    <Loader />
                  </div>
                </div>
              ) : (
                <Row>
                  <Col>
                    <div className="cart-mob">
                      <div style={{ padding: 20 }} className="headsummary">
                        {/* <p style={{ fontSize: 18, marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{cartQty} Item(s) in your bag</p> */}
                        <p
                          style={{ fontFamily: "'inter', sans-serif" }}
                          className="ordersummary"
                        >
                          Order Summary
                        </p>
                      </div>
                      {cartQty == 0 ? (
                        <p
                          className="empty"
                          style={{
                            textAlign: "center",
                            fontSize: 19,
                            fontFamily: "'inter', sans-serif",
                            fontWeight: 500,
                          }}
                        >
                          Your Cart Is Empty
                        </p>
                      ) : (
                        <div>
                          {
                            // loader ?
                            // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

                            cartData.singleCart.map((item, index) => (
                              // console.log(item,"singlecart data")
                              <div>
                                {/* <div className="divider-line" style={{ width: '100%', height: 1, backgroundColor: '#fffff' }}></div> */}

                                <Row>
                                  <Col xs={4}>
                                    <div className="cart-prodiv">
                                      <p
                                        className="mob-name"
                                        // style={{
                                        //   fontSize: "15px",
                                        //   paddingTop: "8px",
                                        //   fontFamily: "'inter', sans-serif",
                                        //   fontWeight: 500,
                                        //   color: "#000",
                                        //   lineHeight: "normal",
                                        //   fontStyle: "normal",
                                        // }}
                                      >
                                        {item.name}
                                      </p>
                                      <p
                                        className="mob-size"
                                        // style={{
                                        //   color: "#5a5a5a",
                                        //   fontSize: "13px",
                                        //   fontFamily: "'inter', sans-serif",
                                        //   fontWeight: 400,
                                        //   lineHeight: "normal",
                                        //   fontStyle: "normal",
                                        //   paddingTop: "8px",
                                        // }}
                                      >
                                        {item.size}
                                      </p>
                                      <p
                                        className="mob-price"
                                        // style={{
                                        //   color: "#0b9547",
                                        //   fontSize: "15px",
                                        //   fontFamily: "'inter', sans-serif",
                                        //   fontWeight: 600,
                                        //   lineHeight: "normal",
                                        //   fontStyle: "normal",
                                        //   paddingTop: "8px",
                                        // }}
                                      >
                                        QAR {item.price}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col xs={4}>
                                    {/* <div className='cart-qty'>
                                                                                            <div style={{ display: 'flex' }}>
                                                                                                <div onClick={() => decrem(item.cart_id)} className='cart-decr'>-</div>
                                                                                                <p style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, fontSize: 14, marginTop: 2 }}>{item.quantity}</p>
                                                                                                <div onClick={() => increm(item.cart_id)} className='cart-decr'>+</div>
                                                                                            </div>
                                                                                        </div> */}
                                    <div className="qtycol">
                                      <div
                                        className="qty"
                                        style={{
                                          fontFamily: "'inter', sans-serif",
                                        }}
                                      >
                                        QTY
                                      </div>
                                      <div className="cart-qty">
                                        <div style={{ display: "flex" }}>
                                          <div
                                            className="decr"
                                            onClick={() => decrem(item.cart_id)}
                                            // className='cart-decr'
                                          >
                                            -
                                          </div>
                                          <p
                                            className="itemqty"
                                            style={{
                                              fontFamily: "'inter', sans-serif",
                                              marginLeft: 10,
                                              marginRight: 20,
                                              fontSize: 20,
                                              color: "black",
                                            }}
                                          >
                                            <span className="itemqty1">
                                              {item.quantity}
                                            </span>
                                          </p>

                                          <div
                                            className="incr"
                                            onClick={() => increm(item.cart_id)}
                                            //  className='cart-decr'
                                          >
                                            +
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={4}>
                                    <div
                                      className="imgalign"
                                      style={{
                                        paddingTop: "20px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      {item.image == null ? (
                                        <img
                                          alt="img"
                                          src={img}
                                          style={{ width: 85, height: 85 }}
                                        />
                                      ) : (
                                        <img
                                          alt="img"
                                          src={
                                            global.images +
                                           
                                            "/ecommerce_photos/" +
                                            item.image
                                          }
                                          style={{
                                            width: 85,
                                            height: 85,
                                            borderRadius: "17px",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <a onClick={() => removeCart(item.cart_id)}>
                                      {" "}
                                      <p
                                        className="remove"
                                        style={{
                                          fontSize: 13,
                                          textAlign: "center",
                                          cursor: "pointer",
                                          fontFamily: "'lato', sans-serif",
                                          fontWeight: 400,
                                          paddingTop: 10,
                                        }}
                                      >
                                        {/* <RiDeleteBin5Line color="#000" size={17} /> */}
                                        Remove item
                                      </p>
                                    </a>
                                  </Col>
                                </Row>
                                {index !== cartData.singleCart.length - 1 && (
                                  <div className="line"></div>
                                )}
                              </div>
                            ))
                          }
                          {
                            // loader ?
                            // <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> :

                            cartData.comboCart.map((item) => (
                              <div>
                                <div
                                  className="divider-line"
                                  style={{
                                    width: "100%",
                                    height: 1,
                                    backgroundColor: "#ffffff",
                                  }}
                                ></div>
                                <div style={{ padding: 20 }}>
                                  <Row>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} lg={4}>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {item.image == null ? (
                                                <img
                                                  alt="img"
                                                  src={img}
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  alt="img"
                                                  src={
                                                    global.images +
                                                  
                                                    "/combo_photos/" +
                                                    item.image
                                                  }
                                                  style={{
                                                    width: 120,
                                                    height: 120,
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </Col>
                                          <Col xs={12} lg={8}>
                                            <div className="cart-prodiv">
                                              <p
                                                className="item-name"
                                                // style={{
                                                //   fontSize: "19px",
                                                //   marginTop: 15,
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 500,
                                                //   color: "#000",
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {item.name}
                                              </p>
                                              <p
                                                className="item-size"
                                                // style={{
                                                //   color: "#5a5a5a",
                                                //   fontSize: "16px",
                                                //   fontFamily:
                                                //     "'inter', sans-serif",
                                                //   fontWeight: 400,
                                                //   lineHeight: "normal",
                                                //   fontStyle: "normal",
                                                // }}
                                              >
                                                {" "}
                                                {item.size}
                                              </p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <div>
                                        <Row>
                                          <Col xs={12} md={6}>
                                            <div className="cart-qty">
                                              <div style={{ display: "flex" }}>
                                                <div
                                                  className="qty"
                                                  style={{
                                                    fontFamily:
                                                      "'inter', sans-serif",
                                                  }}
                                                >
                                                  QTY
                                                </div>

                                                <div
                                                  className="decr"
                                                  onClick={() =>
                                                    decrem(item.cart_id)
                                                  }
                                                >
                                                  -
                                                </div>
                                                <p
                                                  className="item-qty"
                                                  // style={{
                                                  //   fontFamily:
                                                  //     "'inter', sans-serif",
                                                  //   marginLeft: 10,
                                                  //   marginRight: 20,
                                                  //   fontSize: 20,
                                                  //   color: "black",
                                                  // }}
                                                >
                                                  {item.quantity}
                                                </p>
                                                <div
                                                  className="incr"
                                                  onClick={() =>
                                                    increm(item.cart_id)
                                                  }
                                                >
                                                  +
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={12} md={6}>
                                            <p
                                              className="mob-price"
                                              // style={{
                                              //   color: "#0b9547",
                                              //   fontSize: "19px",
                                              //   fontFamily:
                                              //     "'inter', sans-serif",
                                              //   fontWeight: 600,
                                              //   lineHeight: "normal",
                                              //   fontStyle: "normal",
                                              // }}
                                            >
                                              QAR {item.price}
                                            </p>
                                            <a
                                              onClick={() =>
                                                removeCart(item.cart_id)
                                              }
                                            >
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  textAlign: "center",
                                                  cursor: "pointer",
                                                  fontFamily:
                                                    "'lato', sans-serif",
                                                  fontWeight: 400,
                                                }}
                                              >
                                                <RiDeleteBin5Line
                                                  color="#000"
                                                  size={17}
                                                  style={{ marginTop: -4 }}
                                                />{" "}
                                                Remove item
                                              </p>
                                            </a>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} md={5} lg={4}>
                    <div className="cart-div2">
                      <div style={{ padding: 20 }}>
                        <p
                          style={{
                            fontSize: 18,
                            marginBottom: "unset",
                            cursor: "pointer",
                            fontWeight: 600,
                            fontFamily: "'inter', sans-serif",
                          }}
                        >
                          Price Details
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "#d3d3d3",
                        }}
                      ></div>
                      <div style={{ padding: 20 }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            // style={{
                            //   fontSize: 14,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Subtotal ( {cartQty} Items )
                          </p>
                          <p
                            className="cart-checkoutpara"
                            // style={{
                            //   fontSize: 14,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                             { cartTotal == 0 || cartTotal == null  ? `QAR 0` :  `QAR ${cartTotal}`}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            // style={{
                            //   fontSize: 14,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Total shipping Charge
                          </p>
                          {cartTotal < 200 ? (
                            <p
                              className="cart-checkoutpara"
                              // style={{
                              //   fontSize: 14,
                              //   fontFamily: "'lato', sans-serif",
                              //   fontWeight: 400,
                              // }}
                            >
                              QAR {shippingCharge}
                            </p>
                          ) : (
                            <p
                              className="cart-checkoutfree"
                              // style={{
                              //   color: "#00a085",
                              //   fontSize: 14,
                              //   fontFamily: "'lato', sans-serif",
                              //   fontWeight: 400,
                              // }}
                            >
                              Free
                            </p>
                          )}

                          {/* <p style={{fontSize: 13, color: '#00a085', marginTop: 4, fontWeight: 'bold'}}>QAR {shippingCharge}</p> */}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 13,
                          }}
                        >
                          <p
                            className="cart-checkoutpara"
                            // style={{
                            //   fontSize: 14,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Amount Payable
                          </p>
                          {cartTotal < 200 ? (
                            <p
                              className="cart-checkoutpara"
                              // style={{
                              //   fontSize: 14,
                              //   fontFamily: "'lato', sans-serif",
                              //   fontWeight: 400,
                              // }}
                            >
                              QAR {cartTotal + shippingCharge}
                            </p>
                          ) : (
                            <p
                              className="cart-checkoutpara"
                              // style={{
                              //   fontSize: 14,
                              //   fontFamily: "'lato', sans-serif",
                              //   fontWeight: 400,
                              // }}
                            >
                              { cartTotal == 0 || cartTotal == null  ? `QAR 0` :  `QAR ${cartTotal}`}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                        {logtoken ? (
                          <button
                            onClick={() => history.push("/checkout")}
                            type="button"
                            className="mob-checkoutbtn"
                            // class="btn btn-primary"
                            // style={{
                            //   backgroundColor: "#00a085",
                            //   width: "100%",
                            //   fontSize: 13,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Checkout
                          </button>
                        ) : (
                          <button
                            onClick={() => history.push("/login")}
                            type="button"
                            className="mob-checkoutbtn"
                            // class="btn btn-primary"
                            // style={{
                            //   backgroundColor: "#00a085",
                            //   width: "100%",
                            //   fontSize: 13,
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Checkout
                          </button>
                        )}
                        <a
                          onClick={() => history.push("/")}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="mob-continueshoppingbtn"
                            // style={{
                            //   color: "#00a085",
                            //   fontSize: 14,
                            //   fontWeight: "bold",
                            //   marginTop: 20,
                            //   textAlign: "center",
                            //   fontFamily: "'lato', sans-serif",
                            //   fontWeight: 400,
                            // }}
                          >
                            Continue Shopping
                          </p>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <Row>
              {/* cart from redux */}
              <Col xs={12} md={7} lg={8}>
                <div className="cart-div2">
                  <div style={{ padding: 20 }} className="headsummary">
                    {/* <p style={{ fontSize: 18, marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{cartQty} Item(s) in your bag</p> */}
                    <p
                      style={{ fontFamily: "'inter', sans-serif" }}
                      className="ordersummary"
                    >
                      Order Summary
                    </p>
                  </div>
                  {cartCount == 0 ? (
                    <p
                      className="empty"
                      style={{
                        textAlign: "center",
                        fontSize: 19,
                        fontFamily: "'lato', sans-serif",
                        fontWeight: 500,
                      }}
                    >
                      Your Cart Is Empty
                    </p>
                  ) : (
                    <div>
                      {cart.map((item) => (
                        <Cartitem key={item.id} item={item} />
                      ))}
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={12} md={5} lg={4}>
                <div className="cart-div2">
                  <div style={{ padding: 20 }}>
                    <p
                      style={{
                        marginBottom: "unset",
                        fontSize: 18,
                        cursor: "pointer",
                        fontWeight: 600,
                        fontFamily: "'inter', sans-serif",
                      }}
                    >
                      Price Details
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "#d3d3d3",
                    }}
                  ></div>
                  <div style={{ padding: 20 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        // style={{
                        //   fontSize: 14,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Subtotal ( {cartCount} Items )
                      </p>
                      <p
                        className="cart-checkoutpara"
                        // style={{
                        //   fontSize: 14,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        QAR {total}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        // style={{
                        //   fontSize: 14,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Total shipping Charge
                      </p>
                      {total < 200 ? (
                        <p
                          className="cart-checkoutpara"
                          // style={{
                          //   fontSize: 14,
                          //   fontFamily: "'lato', sans-serif",
                          //   fontWeight: 400,
                          // }}
                        >
                          QAR {shippingCharge}
                        </p>
                      ) : (
                        <p
                          className="cart-checkoutpara"
                          // style={{
                          //   color: "#00a085",
                          //   fontSize: 14,
                          //   fontFamily: "'lato', sans-serif",
                          //   fontWeight: 400,
                          // }}
                        >
                          Free
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#d3d3d3",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 13,
                      }}
                    >
                      <p
                        className="cart-checkoutpara"
                        // style={{
                        //   fontSize: 14,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Amount Payable
                      </p>
                      {total < 200 ? (
                        <p
                          className="cart-checkoutpara"
                          // style={{
                          //   fontSize: 14,
                          //   fontFamily: "'lato', sans-serif",
                          //   fontWeight: 400,
                          // }}
                        >
                          QAR {total + shippingCharge}
                        </p>
                      ) : (
                        <p
                          className="cart-checkoutpara"
                          // style={{
                          //   fontSize: 14,
                          //   fontFamily: "'lato', sans-serif",
                          //   fontWeight: 400,
                          // }}
                        >
                          QAR {total}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        backgroundColor: "#d3d3d3",
                      }}
                    ></div>
                    {logtoken ? (
                      <button
                        onClick={() => history.push("/checkout")}
                        type="button"
                        className="mob-checkoutbtn"
                        // class="btn btn-primary"
                        // style={{
                        //   backgroundColor: "#00a085",
                        //   width: "100%",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Checkout
                      </button>
                    ) : (
                      <button
                        onClick={() => history.push("/login")}
                        type="button"
                        className="mob-checkoutbtn"
                        // class="btn btn-primary"
                        // style={{
                        //   backgroundColor: "#00a085",
                        //   width: "100%",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Checkout
                      </button>
                    )}
                    <a
                      onClick={() => history.push("/")}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="mob-continueshoppingbtn"
                        // style={{
                        //   color: "#00a085",
                        //   marginTop: 20,
                        //   textAlign: "center",
                        //   fontSize: 13,
                        //   fontFamily: "'lato', sans-serif",
                        //   fontWeight: 400,
                        // }}
                      >
                        Continue Shopping
                      </p>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refresh: (item) => dispatch(refresh(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
