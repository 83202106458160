import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import Sliders from "../../Components/Slider/Slider";
import Deals from "../../Components/Deals/Deals";
import NewArrival from "../../Components/NewArrival/Newarrival";
import Product from "../../Components/NewArrival/product";
import TopSelling from "../../Components/TopSelling/TopSelling";
import Skeleton from "@mui/material/Skeleton";
import Category from "../../Components/Category/category";
import Mobilecategory from "../../Components/Category/mobilecategory";
import Gridcategory from "../../Components/Category/gridcategory";
import img from "../../Assets/Images/noimages.png";

import Products from "./cateProducts";
import Loader from "../../Components/Loader/Loader";
import { TiArrowUnsorted } from "react-icons/ti";
import { LuFilter } from "react-icons/lu";
import { FaSortDown } from "react-icons/fa";

import InfiniteScroll from "react-infinite-scroll-component";

import ComboProducts from "../../Components/ComboProducts/ComboProducts";

import video from "../../Assets/video/skydunz.mp4";

// import AllProduct from './AllProduct';

// import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

function Home(props) {
  // const [timerDay, setTimerDay] = useState('00');
  // const [timerHour, setTimerHour] = useState('00');
  // const [timerMinute, setTimerMinute] = useState('00');
  // const [timerSecond, setTimerSecond] = useState('00');

  const [categorylist, setCategorylist] = useState([]);
  const [sliderlist, setSliderlist] = useState([]);
  const [sliderloading, setSliderloading] = useState(false);
  const [categoryloading, setCategoryloading] = useState(false);
  const [categoryproduct, setCategoryproduct] = useState([]);
  const [subcatlist, setSubcatlist] = useState([]);
  const [subCatLists, setSubCatLists] = useState([]);
  const [banner1, setBanner1] = useState("");
  const [banner2, setBanner2] = useState("");
  const [banner3, setBanner3] = useState("");
  const [banner1id, setBanner1id] = useState("");
  const [banner2id, setBanner2id] = useState("");
  const [banner3id, setBanner3id] = useState("");
  const [posterList, setPosterlist] = useState([]);
  const [arrival, setArrival] = useState([]);
  const [visible, SetVisible] = useState([]);
  const [topSelling, setTopSelling] = useState([]);

  const [smallposter, setSmallposter] = useState([]);
  // const [bigposter,setBigposter]= useState([]);
  const [usercompanyid, setUsercompanyid] = useState("");
  const [userbranchid, setUserbranchid] = useState("");
  const [usertype, setUsertype] = useState("");
  const [userId, setUserid] = React.useState("");

  const [deliveryTime, setDeliveryTime] = useState(0);
  const [comboDelivery, setComboDelivery] = useState(0);

  const [comboSliderLoading, setComboSliderLoading] = useState(false);
  const [arrivalSliderLoading, setArrivalSliderLoading] = useState(false);

  const [loading, setLoading] = useState(true);

  const [comboProducts, setComboProducts] = useState([]);
  const containerRef = useRef(null);
  const [condition, SetCondition] = useState(false);

  let interval = useRef();
  let history = useHistory();


  // let abc = localStorage.getItem("token")
  // console.log(abc,"123155")

  // const startTimer = () => {
  //     const countdownDate = new Date("Mar 30, 2022 00:00:00").getTime();
  //     interval = setInterval(() => {
  //         const now = new Date().getTime();
  //         const distance = countdownDate - now;

  //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24)/(1000 * 60 * 60)));
  //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //         const seconds = Math.floor((distance % (1000 * 60))/ 1000);

  //         if (distance < 0) {
  //             clearInterval(interval.current);
  //         } else {
  //             setTimerDay(days);
  //             setTimerHour(hours);
  //             setTimerMinute(minutes);
  //             setTimerSecond(seconds);
  //         }
  //     }, 1000);
  // }

  // useEffect(() => {
  //     startTimer();
  //     return() => {
  //         clearInterval(interval.current);
  //     }
  // },[])

  const getSlider = () => {
    setSliderloading(true);
    var data = {
      type: "slider",
    };
    fetch(global.url + "viewBSliderfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("Slider response", responseJson);
        setSliderloading(false);
        if (responseJson.error == false) {
          setSliderlist(responseJson.data);
        } else {
          setSliderlist([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPoster = () => {
    setSliderloading(true);
    var data = {
      type: "smallbanner",
    };
    fetch(global.url + "viewBSliderfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("Poster response", responseJson)
        setSliderloading(false);
        if (responseJson.error == false) {
          setPosterlist(responseJson.data);
        } else {
          setPosterlist([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBanner = () => {
    setSliderloading(true);
    var data = {
      type: "bigbanner",
    };
    fetch(global.url + "viewBSliderfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("Banner Respo response", responseJson.data[0])
        setSliderloading(false);
        if (responseJson.error == false) {
          setBanner1(responseJson.data[0].image);
          setBanner2(responseJson.data[1].image);
          setBanner3(responseJson.data[2].image);
          setBanner1id(responseJson.data[0].link_id);
          setBanner2id(responseJson.data[1].link_id);
          setBanner3id(responseJson.data[2].link_id);
        } else {
          setBanner1("");
          setBanner2("");
          setBanner3("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Fetch small posters
    fetch(global.url + "viewBSliderfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify({ type: "smallposter" }),
    })
      .then((response) => response.json())
      .then((responseJson) => setSmallposter(responseJson.data))
      .catch((error) => console.log(error));

    // Fetch big posters
    // fetch(global.url + 'viewBSliderfrontend', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'APPKEY': 'erp6129'
    //     },
    //     body: JSON.stringify({ type: 'bigposter' })
    // })
    // .then(response => response.json())
    // .then(responseJson => setBigposter(responseJson.data))
    // .catch(error => console.log(error));
  }, []);

  const smallposter1 = smallposter[0] || null;
  const smallposter2 = smallposter[1] || null;
  const smallposter3 = smallposter[2] || null;
  const smallposter4 = smallposter[3] || null;

  const getCategoryproducts = () => {
    setSliderloading(true);
    fetch(global.url + "displayCategoryProductfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("category product response", responseJson);
        if (responseJson.error == false) {
          setSliderloading(false);
          setCategoryproduct(responseJson.data);
        } else {
          setCategoryproduct([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategory = () => {
    setCategoryloading(true);
    fetch(global.url + "viewCategoryfrontend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("Category response", responseJson);
        setCategoryloading(false);
        if (responseJson.error == false) {
          setCategorylist(responseJson.data);
        } else {
          setCategorylist([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [page, setPage] = useState(1);
  const [limit, SetLimit] = useState("");
  const [filterCondition, setFilterCondition] = useState(false);
  const [getresponse, setGetreponse] = useState([]);

  // const responseArray = [];
  const [responseArray, setResponseArray] = useState([]);

  const getArrival = async (value) => {
    try {
      setArrivalSliderLoading(true);
      const container = containerRef.current;

      var data = {
        limit: 12,
        // page: page,
        deliverytime: value,
      };

      const response = await fetch(global.url + "ProductDelivaryTimeFilter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          APPKEY: "erp6129",
        },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();

      SetLimit(responseJson.data[0].count);

      if (responseJson.error === false) {
        console.log(responseJson, "get arrival reponse");
        SetCondition(true);

        // // for (var i = 0; i < responseJson.data.length; i++) {
        // //   console.log("iteration is excecuting")
        // //   responseArray.push(responseJson.data[i]);
        // // }
        // // setArrival((prev)=>[...prev,...responseArray])
        // setArrival((prev) => [...prev, ...responseJson.data]);
        // // setGetreponse((prev)=> [...prev,...responseJson.data]);
        // setGetreponse(arrival)

        // setPage((prev) => prev + 1);
        // container && (container.scrollTop = container.scrollHeight);
        // // filterFunction()
        setArrival(responseJson.data);
      } else {
        SetCondition(false);
        setArrivalSliderLoading(false);
        setArrival([]);
      }
    } catch (error) {
      SetCondition(false);
      console.error(error);
      console.log(error, "error 2");
    } finally {
      setArrivalSliderLoading(false);
    }
  };
  // console.log(responseArray, "a25")
  // console.log(getresponse, "a26")

  // const backButton = () => {
  //   SetCondition(true)
  //   setArrival(arrival)
  // }

  console.log(arrival, "arrival above the filter function");

  var filterFunction = (time) => {
    SetCondition(false);
    console.log(time, "time1");

    const filterResponse = getresponse.filter((data) => {
      console.log(data, "filter data");
      return data.deliverytime == time;
    });
    // setArrival((prev) => [...prev, ...filterResponse]);
    console.log(filterResponse, "filter response");
    setArrival(filterResponse);
    // deliveryClick(0)
  };

  const handleScroll = () => {
    const container = containerRef.current;

    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      !arrivalSliderLoading
    ) {
      setArrivalSliderLoading(true);
      getArrival(deliveryTime);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container && container.addEventListener("scroll", handleScroll);
    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [arrivalSliderLoading, page]);

  // const [page,setPage]=useState(1);

  // const getArrival = async (value) => {
  //   try {
  //     console.log(value, "selected value");
  //     setArrivalSliderLoading(true);

  //     var data = {
  //       deliverytime: value,
  //       limit:8,
  //       page:page
  //     };

  //     const response = await fetch(global.url + "ProductDelivaryTimeFilter", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         APPKEY: "erp6129",
  //       },
  //       body: JSON.stringify(data),
  //     });
  //     const responseJson = await response.json();
  //     console.log("Arrival response", responseJson);
  //     setArrivalSliderLoading(false);
  //     if (responseJson.error === false) {
  //       setArrival((prev)=>[...prev,...responseJson.data]);
  //       setLoading(false);
  //       // SetVisible(responseJson.data.slice(0, 12));
  //     } else {
  //       setArrival([]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getArrival();
  // }, [page]);

  // const handleInfiniteScroll = async () => {
  //   console.log(window.innerHeight, "innerheight");
  //   console.log(document.documentElement.scrollTop, "scroll top");
  //   console.log(document.documentElement.scrollHeight, "scroll height");
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       setPage((prev) => prev + 1);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handleInfiniteScroll);
  // }, []);

  // .....................................................

  // const loadMore = () => {
  //   if (arrivalSliderLoading) {
  //     return;
  //   }
  //   setArrivalSliderLoading(true);

  //   const startIndex = visible.length;
  //   const endIndex = startIndex + 12;

  //   const newProducts = arrival.slice(startIndex, endIndex);

  //   SetVisible((prev) => [...prev, ...newProducts]);

  //   // Move this line inside the callback of SetVisible to ensure it's executed after state is updated
  //   setArrivalSliderLoading(false);
  // };

  // const handleScroll =()=>{

  //   const scrollTop = document.documentElement.scrollTop;
  //   const windowHeight =window.innerHeight;
  //   const scrollHeight = document.documentElement.scrollHeight;

  //   if(scrollTop + windowHeight >= scrollHeight -200){
  //     loadMore();
  //   }
  // }

  // useEffect(() => {
  //   getArrival(deliveryTime);
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []); // The empty dependency array ensures this effect runs only once when the component mounts

  // useEffect(() => {
  //   const container = document.getElementById('your-container-id'); // Replace with your actual container ID
  //   container.addEventListener('scroll', handleScroll);
  //   return () => {
  //     container.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // ......................................................................................

  // useEffect(() => {
  //     getArrival(singleDelivery)
  // }, [singleDelivery])

  // const singleProductFilter = (selectedprd) => {
  //     setSingleDelivery(selectedprd)
  //     console.log("function is called", selectedprd, "selected time");
  // }

  const getComboProducts = (value) => {
    console.log(value, "selected time");
    setComboSliderLoading(true);
    const requestData = {
      limit: 18,
      deliverytime: value,
    };

    fetch(global.url + "ProductComboDelivaryTimeFilter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setComboSliderLoading(false);
        if (responseJson.error === false) {
          setComboProducts(responseJson.data);
        } else {
          setComboProducts([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //     getComboProducts(deliveryTime)
  // }, [deliveryTime])
  // const handleFilteredCombo = (selectedValue) => {
  //     setDeliveryTime(selectedValue)
  // }
  // useEffect(()=>{
  //     getSubcategorylist()
  // },[]);

  const getSubcategorylist = () => {
    setSliderloading(true);
    // var userdata = JSON.parse(localStorage.getItem("user"))
    // var branch = JSON.parse(localStorage.getItem("branch"))
    // var company = JSON.parse(localStorage.getItem("company"))

    var data = {
      type: "admin",
      companyid: 1,
    };
    fetch(global.url + "viewSubcategory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setSliderloading(false);
        if (responseJson.status == "false") {
          setSubCatLists(responseJson.data);
          // code used for avoiding repeating category names
          const uniqueCategories = new Set();
          const filteredData = responseJson.data.filter((subcategory) => {
            const categoryName = subcategory.category_name;
            if (!uniqueCategories.has(categoryName)) {
              uniqueCategories.add(categoryName);
              return true;
            }
            return false;
          });
          setSubcatlist(filteredData);
          // console.log(filteredData, "filtered data");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTopSelling = () => {
    setSliderloading(true);
    var data = {
      limit: 18,
    };
    fetch(global.url + "viewTopSalingProducts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //   console.log("Top selling response", responseJson.data)
        setSliderloading(false);
        if (responseJson.error == false) {
          setTopSelling(responseJson.data);
        } else {
          setTopSelling([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const matrixOrder = [
    [0, 0],
    [0, 1],
    [1, 0],
    [1, 1],
  ];
  useEffect(() => {
    getCategory();
    getSlider();
    getCategoryproducts();
    getBanner();
    getPoster();
    getArrival(deliveryTime);
    getTopSelling();
    getComboProducts(comboDelivery);
    getSubcategorylist();
    // getSmallposter()
    // getBigposter()
  }, []);

  const deliveryClick = (value) => {
    setArrivalSliderLoading(false);
    filterFunction(value);

    // setDeliveryTime(value);
    // setPage(1);
    // getArrival(value);
  };
  const deliveryClick1 = (value) => {
    setPage(value);
    getArrival(0);
    setArrival([]);
    // setArrivalSliderLoading(false);
    // getArrival(value)
  };

  const comboDeliveryClick = (value) => {
    getComboProducts(value);
    setComboDelivery(value);
  };

  console.log(arrival, " arr len");
  console.log(limit, "lim");

  return (
    <div>
    {sliderloading ? (
      // <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: 200 }}>
          <Loader />
        </div>
      </div>
    ) : (
      <div>
        <div className="mobilebannerbtop">
          {sliderloading ? (
            // <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: 50 }}>
                <Loader />
              </div>
            </div>
          ) : (
            <Sliders data={sliderlist} />
          )}
        </div>
        {subcatlist.length == 0 ? null : subcatlist[0].category_grid == 0 ? (
          <div>
            <Container className="marginofcontainer desktopcategory">
              <Category
                subcatlist={subcatlist}
                categoryloading={categoryloading}
              />
            </Container>
            <Container className="marginofcontainer mobilecategory">
              <Mobilecategory
                subcatlist={subcatlist}
                categoryloading={categoryloading}
              />
            </Container>
          </div>
        ) : (
          <Container>
            <Gridcategory
              subcatlist={subcatlist}
              categoryloading={categoryloading}
            />
          </Container>
        )}
        {/* moon */}

        <div className="totaldiv">
          <div className="video-banner">
            <video autoPlay muted loop playsInline id="video">
              <source src={video} type="video/mp4" />
            </video>
          </div>

          {/* <object data={video} width="320" height="240">
            <param name="autoplay" value="true"/>
              <embed src={video} width="320" height="240" autoplay="true" />
          </object> */}
          {/* <div >
            <iframe  src={`${video}?autoplay=1`} frameborder="0" allowfullscreen></iframe>
          </div> */}
{/* 
          <div>
            <a
              onClick={() =>
                // history.push(`/product/${banner1id}/subcategory`)
                history.push(`/newarrival`)
              }
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={global.images  + "/ecommerce_photos/" + banner1}
              />
            </a>
          </div> */}
          {/* desktop view */}
          <Container>
            <div style={{ marginTop: 20 }}>
              {/* {arrival&&arrival.length == 0 ? null : ( */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: 10,
                }}
              >
                {/* <div className='catborder'> */}
                <p
                  className="heading"
                  style={{ fontFamily: "'lato', sans-serif" }}
                >
                  NEW ARRIVAL
                </p>
                {/* </div> */}
                {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>New Arrival</h2> */}
                {/* <div
                  className="dropdown"
                  style={{ fontFamily: "'lato', sans-serif" }}
                >
                  <p
                    className="dropbtn"
                    style={{
                      color: "#000000",
                      fontSize: 20,
                      fontWeight: 600,
                      fontFamily: "'lato', sans-serif",
                    }}
                  >
                    Delivery Time
                    <FaSortDown style={{ marginTop: "-5px" }} />
                  </p>
                  <div className="dropdown-content">
                   
                    <a onClick={() => deliveryClick("24")}>24 hr delivery</a>
                    <a onClick={() => deliveryClick("48")}>48 hr delivery</a>
                    <a className="deliveryclick-font" onClick={() => deliveryClick1(1)}>BACK</a>
                  </div>
                </div> */}
              </div>
              {/* )
               } */}

              <div>
                {sliderloading ? (
                  <Row>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                    <Col xs={4} md={3} lg={3}>
                      <Skeleton
                        sx={{ height: 140, marginTop: 1 }}
                        animation="wave"
                        variant="rectangular"
                      />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </Col>
                  </Row>
                ) : // arrival.length <= (limit + 1) ?
                arrival.length > 0 ? (
                  <div
                    ref={containerRef}
                    // style={{
                    //   maxHeight: "400px",
                    //   overflowY: "auto", overflowX: 'hidden'
                    // }}
                  >
                    <Row>
                      {arrival &&
                        arrival.map((product) => (
                          <Col
                            xs={6}
                            md={4}
                            lg={3}
                            xl={2}
                            className="spacereduce"
                          >
                            <Product
                              key={product.id}
                              product={product}
                              className="productimg"
                            />
                          </Col>
                        ))}

                      {condition && arrivalSliderLoading && <Loader />}
                    </Row>
                  </div>
                ) : // <p>reached limit</p>
                null}
              </div>

              <div className="button-div">
                <button
                  className="load-more-btn"
                  onClick={() => history.push("/newarrival")}
                >
                  load more
                </button>
              </div>
            </div>
            <div style={{ marginTop: 50 }}>
              {/* <h2>Combo Products</h2> */}
              {comboProducts.length == 0 ? null : (
                <div
                  className="comboimg"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: 25,
                  }}
                >
                  <div className="catborder">
                    <p
                      className="heading"
                      style={{ fontFamily: "'lato', sans-serif" }}
                    >
                      COMBO PRODUCTS
                    </p>
                  </div>
                  {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>New Arrival</h2> */}
                  {/* <div style={{ color: '#501033', fontSize: 18 }} onClick={() => history.push("/cart")} variant="light see-all"><LuFilter /></div> */}
                  {/* <select style={{ color: '#501033' }} onChange={(e) => handleFilteredCombo(e.target.value)}>
                                                      <option value="">delivery time filteration</option>
                                                      <option value="24">24 hour delivery</option>
                                                      <option value="48">48 hour delivery</option>
                                                  </select> */}
                  {/* <div
                    className="dropdown"
                    style={{ fontFamily: "'lato', sans-serif" }}
                  >
                    <h3
                      className="dropbtn"
                      style={{
                        color: "#000000",
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: "'lato', sans-serif",
                      }}
                    >
                      Delivery Time
                      <FaSortDown style={{ marginTop: "-5px" }} />
                    </h3>
                    <div className="dropdown-content">

                      <a onClick={() => comboDeliveryClick(24)}>
                        24 hr delivery
                      </a>
                      <a onClick={() => comboDeliveryClick(48)}>
                        48 hr delivery
                      </a>
                      <a
                        className="deliveryclick-font"
                        onClick={() => comboDeliveryClick(0)}
                      >
                        BACK
                      </a>
                    </div>
                  </div> */}
                </div>
              )}
              <div>
                {comboProducts && comboProducts.length > 0 ? (
                  <Row>
                    {comboProducts.map((comboProduct) => (
                      <Col
                        key={comboProduct.products_id}
                        xs={6}
                        md={4}
                        lg={3}
                        xl={2}
                        className="spacereducer1"
                        style={{ marginTop: 10 }}
                      >
                        <a
                          onClick={() =>
                            history.push(
                              "/combodetails/" + comboProduct.products_id
                            )
                          }
                        >
                          <div className="main-image-containers">
                            <div className="image-wrapper">
                              <img
                                alt="img"
                                src={
                                  global.images +
                                 
                                  "/combo_photos/" +
                                  comboProduct.product_comboimage
                                }
                                className="imagehome"
                              />
                            </div>
                            <div
                              className="textfont"
                              style={{
                                marginTop: 10,
                                position: "relative",
                              }}
                            >
                              <p className="product-name">
                                {comboProduct.product_name}
                              </p>
                              <p
                                className="price"
                                style={{ marginBottom: "unset" }}
                              >
                                QAR {comboProduct.product_comboprice}
                              </p>
                            </div>
                          </div>
                        </a>
                      </Col>
                    ))}
                  </Row>
                ) : null}
              </div>
              <div className="button-div">
              {comboProducts && comboProducts.length > 0 ? (
                <button
                  className="load-more-btn"
                  onClick={() => history.push("/combo")}
                >
                  load more
                </button>
              ):null}
              </div>
            </div>

            <div className="deals">
              <div
                style={{
                  marginTop: 25,
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: 15,
                }}
              >
                {posterList.length == 0 ? null : (
                  <div className="catborder">
                    <p
                      className="heading"
                      style={{ fontFamily: "'lato', sans-serif" }}
                    >
                      SPECIAL DEALS
                    </p>
                  </div>
                )}
                {/* <h2 className='font' style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>Special Deals</h2> */}
              </div>
              <div>
                {sliderloading ? null : ( // </Row> //     </Col> //         <Skeleton sx={{ height: 220 }} animation="wave" variant="rectangular" /> //     <Col xs={12} md={4}> //     </Col> //         <Skeleton sx={{ height: 220 }} animation="wave" variant="rectangular" /> //     <Col xs={12} md={4}> // <Row>
                  <Deals data={posterList} />
                )}
              </div>
            </div>
          </Container>
          <div
            className="smallposter"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1px",
            }}
          >
            <div style={{ paddingLeft: 200 }}>
              <div className="sp1" style={{ textAlign: "center" }}>
                {smallposter1 && (
                  <a
                    onClick={() =>
                      history.push(
                        `/product/${smallposter1.link_id}/subcategory`
                      )
                    }
                  >
                    <div className="hover1 column  ">
                      <figure>
                        <img
                          src={
                            global.images +
                          
                            "/ecommerce_photos/" +
                            smallposter1.image
                          }
                          alt="Small Poster 1"
                          style={{ height: "80%", width: "90%" }}
                        />
                      </figure>
                    </div>
                  </a>
                )}

                <div
                  className="sp2"
                  style={{ paddingTop: 56, textAlign: "center" }}
                >
                  {smallposter2 && (
                    <a
                      onClick={() =>
                        history.push(
                          `/product/${smallposter2.link_id}/subcategory`
                        )
                      }
                    >
                      <div className="hover2 column  ">
                        <figure>
                          {" "}
                          <img
                            src={
                              global.images +
                            
                              "/ecommerce_photos/" +
                              smallposter2.image
                            }
                            alt="Small Poster 2"
                            style={{ height: "90%", width: "90%" }}
                          />
                        </figure>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div style={{ paddingRight: 180 }}>
              <div className="sp3" style={{ textAlign: "center" }}>
                {smallposter3 && (
                  <a
                    onClick={() =>
                      history.push(
                        `/product/${smallposter3.link_id}/subcategory`
                      )
                    }
                  >
                    <div className="hover3 column  ">
                      <figure>
                        {" "}
                        <img
                          src={
                            global.images +
                          
                            "/ecommerce_photos/" +
                            smallposter3.image
                          }
                          alt="Small Poster 3"
                          style={{ height: "80%", width: "80%" }}
                        />
                      </figure>
                    </div>
                  </a>
                )}

                <div
                  className="sp4"
                  style={{ paddingTop: 54, textAlign: "center" }}
                >
                  {smallposter4 && (
                    <a
                      onClick={() =>
                        history.push(
                          `/product/${smallposter4.link_id}/subcategory`
                        )
                      }
                    >
                      <div className="hover4 column  ">
                        <figure>
                          {" "}
                          <img
                            src={
                              global.images +
                             
                              "/ecommerce_photos/" +
                              smallposter4.image
                            }
                            alt="Small Poster 4"
                            style={{ height: "100%", width: "80%" }}
                          />
                        </figure>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/*  mobile view   */}

          <div className="smallposter-mobile">
            {smallposter1 && (
              <a
                onClick={() =>
                  history.push(`/product/${smallposter1.link_id}/subcategory`)
                }
              >
                <img
                  src={
                    global.images +
                   
                    "/ecommerce_photos/" +
                    smallposter1.image
                  }
                  alt="Small Poster 1"
                />
              </a>
            )}
            {smallposter2 && (
              <a
                onClick={() =>
                  history.push(`/product/${smallposter2.link_id}/subcategory`)
                }
              >
                <img
                  src={
                    global.images +
                   
                    "/ecommerce_photos/" +
                    smallposter2.image
                  }
                  alt="Small Poster 2"
                />
              </a>
            )}
            {smallposter4 && (
              <a
                onClick={() =>
                  history.push(`/product/${smallposter4.link_id}/subcategory`)
                }
              >
                <img
                  src={
                    global.images +
                   
                    "/ecommerce_photos/" +
                    smallposter4.image
                  }
                  alt="Small Poster 4"
                />
              </a>
            )}
            {smallposter3 && (
              <a
                onClick={() =>
                  history.push(`/product/${smallposter3.link_id}/subcategory`)
                }
              >
                <img
                  src={
                    global.images +
                   
                    "/ecommerce_photos/" +
                    smallposter3.image
                  }
                  alt="Small Poster 3"
                />
              </a>
            )}
          </div>

          {/* fixing the background image */}

          <div
            className="imgfix"
            style={{
              paddingTop: "33%",
              backgroundImage: `url(${global.images}/ecommerce_photos/${banner1})`,
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              marginTop: 150,
              marginBottom: 10,
              // //   height: '22%'
            }}
          >
            {banner1 === "" ? null : (
              <div style={{ position: "relative" }}>
                {sliderloading ? null : (
                  <a
                    onClick={() =>
                      history.push(`/product/${banner1id}/subcategory`)
                    }
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {/* Transparent content over the background image */}
                  </a>
                )}
              </div>
            )}
          </div>

          {/* <div className='imgfixmobile'
                              style={{
                                  paddingTop: '250px',
                                  backgroundImage: `url(${global.images}ecommerce_photos/${banner2})`,
                                  backgroundAttachment: 'fixed',
                                  backgroundSize: 'cover',
                                  marginTop: '50px',
                                  marginBottom: '-150px'
                                  // //   height: '22%' 

                              }}
                          >
                              {banner1 === '' ? null : (
                                  <div style={{ position: 'relative' }}>
                                      {sliderloading ? (

                                          null
                                      ) : (
                                          <a
                                              onClick={() => history.push(`/product/${banner1id}/subcategory`)}
                                              style={{
                                                  position: 'absolute',
                                                  top: 0,
                                                  left: 0,
                                                  width: '100%',
                                                  height: '100%',
                                              }}
                                          >
                                            
                                          </a>
                                      )}
                                  </div>
                              )}
                          </div> */}
          <div className="categoryimg">
            <Container>
              {/* <div style={{marginTop: 30}}>
                      <DayDeal />
                  </div> */}

              {sliderloading
                ? // ..... no need this code part -->
                  //     <Row>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //         <Col xs={6} md={3} lg={3}>
                  //             <Skeleton sx={{ height: 140, marginTop: 3 }} animation="wave" variant="rectangular" />
                  //             <Skeleton variant="text" />
                  //             <Skeleton variant="text" />
                  //         </Col>
                  //     </Row>
                  //     .....

                  null
                : categoryproduct.map((item) => {
                    return (
                      <div key={item.id} className="categorycol">
                        {item.products == [] ? null : (
                          <div>
                            {item.products.length == 0 ? null : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "60px",
                                }}
                              >
                                <div className="catborder">
                                  <p
                                    className="heading"
                                    style={{
                                      fontFamily: "'lato', sans-serif",
                                    }}
                                  >
                                    {item.category_name}
                                  </p>
                                </div>
                                {/* ...no need */}
                              </div>
                            )}

                            <div>
                              <Row>
                                {/* product spacing  */}
                                {item.products.map((product, k) => (
                                  <Col
                                    xs={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    className="spacereduce"
                                  >
                                    <div>
                                      <Products
                                        key={product.id}
                                        product={product}
                                      />
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                        )}
                        {item.products.length > 0 ? (
                          <div className="button-div">
                            <div
                              onClick={() =>
                                history.push(
                                  "/product/" + item.category_id + "/category"
                                )
                              }
                              // variant="light see-all"
                              className="load-more-btn"
                            >
                              Load more
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
            </Container>
          </div>
          {/* <div style={{ padding: 10, background: '#fffede', marginTop: 10 }}>
                              {banner2 == '' ? null :
                                  <div >
                                      {sliderloading ?
                                          // <Skeleton sx={{ height: 180 }} animation="wave" variant="rectangular" />
                                          null
                                          :
                                          // <a onClick={() => history.push('/product/' + banner2id + "/category")}>
                                          <img alt="img" src={global.images + "ecommerce_photos/" + banner2} className="bottom-banner" />
                                          // </a>
                                      }
                                  </div>
                              }
                          </div> */}

          {/* subcategory */}

          {/* <Container>
                              <div className='catborder'>
                                  <p style={{ color: '#501033', fontSize: 22, fontWeight: 600, marginBottom: 'unset', fontFamily: "'lato', sans-serif", textAlign: 'center' }}> SUB CATEGORIES</p>
                              </div>
                              <Row>
                                  {subcatlist.map((subcat) => (
                                      <Col key={subcat.subCategory_id} xs={4} md={3} lg={3}>
                                          <a onClick={() => history.push('/product/' + subcat.subCategory_id + '/subcategory')} >
                                              <div className='subcat' >
                                                  <img className='subcat-img' alt="subcategory" src={global.images + "ecommerce_photos/" + subcat.subCategory_image} />
                                              </div>
                                              <div>
                                                  <p className='product-name' style={{ textAlign: 'center', fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 600, marginBottom: 15 }}>{subcat.subCategory_name}</p>
                                              </div>
                                          </a>
                                      </Col>
                                  ))}
                              </Row>
                          </Container> */}
          <Container>
            <div
              // className="topselling"
              style={{ marginTop: 20 }}
            >
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {topSelling.length == 0 ? null : (
                  <div className="catborder">
                    <p
                      className="heading"
                      style={{ fontFamily: "'lato', sans-serif" }}
                    >
                      TOP SELLING PRODUCTS
                    </p>
                  </div>
                )}
                {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>Top Selling Products</h2> */}
                {/* <Button onClick={() => history.push('/product')} variant="light see-all">See All</Button> */}
              </div>
              <div>
                {sliderloading ? //     </Col> //         <Skeleton variant="text" /> //         <Skeleton variant="text" /> //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> //     <Col xs={6} md={3} lg={3}> //     </Col> //         <Skeleton variant="text" /> //         <Skeleton variant="text" /> //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" /> //     <Col xs={6} md={3} lg={3}> // <Row>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                // </Row>
                null : (
                  <TopSelling data={topSelling} />
                )}
              </div>
            </div>

            {/* <div>
                <a
                  onClick={() =>
                    history.push(`/product/${banner1id}/subcategory`)
                  }
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={global.images + "ecommerce_photos/" + banner1}
                  />
                </a>
              </div> */}
          </Container>
          <div
            style={{
              padding: 10,
              background: "#FFFFFF",
              // background: '#fffede',
              marginTop: 10,
            }}
          >
            {/* {banner3 == '' ? null :
                                  <div >
                                      {sliderloading ?
                                          // <Skeleton sx={{ height: 180 }} animation="wave" variant="rectangular" />
                                          null
                                          :
                                          // <a onClick={() => history.push('/product/' + banner3id + "/category")}>
                                          <img alt="img" src={global.images + "ecommerce_photos/" + banner3} className="bottom-banner" />
                                          // </a>
                                      }
                                  </div>
                              } */}
          </div>
        </div>
      </div>
    )}
  </div>
  );
}
export default Home;
