import React, { useEffect, useState, useRef } from "react";

import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory ,useParams} from "react-router-dom/cjs/react-router-dom.min";
import './subcategory.css'
import Loader from '../../Components/Loader/Loader'
import Skeleton from "@mui/material/Skeleton";
import Product from "../../Components/NewArrival/product";


function SubCategory() {

    const [subCatList, setSubCatList] = useState([]);
    const [loader, setLoader] = useState(false);
    const { categoryId } = useParams();
    const [loading, setLoading] = useState(false);
    const [subCatPrdlist,setSubCatPrdList] = useState([])
    const containerRef = useRef(null);
    let history = useHistory()
    

    useEffect(() => {
        getSubcategorylist(categoryId)
        console.log(categoryId,"categoryId")
    }, [categoryId])

    const getSubcategorylist = () => {
        console.log("getSubcategorylist function is called")
        setLoader(true)

        var data = {
            "type": "admin",
            "companyid": 1
        }
        fetch(global.url + "viewSubcategory", {
            method: "POST",
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoader(false)
                if (responseJson.status == "false") {
                   
                    if (responseJson.data && Array.isArray(responseJson.data)) {
                        // console.log(responseJson,"sub category response json");
                        const filteredSubCategories = responseJson.data.filter(
                            (subcategory) => subcategory.subCategory_category == categoryId
                        );
                        setSubCatList(filteredSubCategories);
                        
                        // console.log(filteredSubCategories,"subcategory list")
                    }
                }
                else{
                    console.log("sub category else condition")
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getArrival()
    }, [])

    const getArrival = () => {
        setLoading(true)
        var data = {
            
        }
        fetch(global.url + "ProductDelivaryTimeFilter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                APPKEY: "erp6129",
            },
            body: JSON.stringify(data)

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson.data, "app prd resp1");
                setLoading(false)
                if (responseJson.error === false) {
                    // setAllPrd(responseJson.data);
                    console.log(responseJson.data, "app prd resp");
                    if (responseJson.data && Array.isArray(responseJson.data)) {
                        // console.log(responseJson,"sub category response json");
                        const filteredSubCategories = responseJson.data.filter(
                            (subcategory) => subcategory.subCategory_category == categoryId 
                        );
                        setSubCatPrdList(filteredSubCategories);
                        
                        // console.log(filteredSubCategories,"subcategory list")
                    }
                } else {
                    console.log("else condition in sub category")
                }

            }).catch((error) => {
                console.error(error)
            });
    }
    console.log(subCatPrdlist,"prd listt")

    return (
        
        <div style={{marginBottom:'235px'}}>
            {loader ?
              <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: 200 }}>
                  <Loader />
              </div>
          </div>
        :
            <Container>
                <div className="catborders" >
                    <p style={{ color: '#00a085', fontSize: 22, fontWeight: 600, marginBottom: 50, fontFamily: "'poppins',san-serif", textAlign: 'center',paddingTop:'14px' }}>SUB CATEGORIES</p>
                </div>
                <Row style={{justifyContent:'center'}}>
                    {subCatList.map((subcat) => (
                        <Col key={subcat.subCategory_id} xs={6} md={2} lg={2}>
                            <a onClick={()=>history.push('/product/'+subcat.subCategory_id +'/subcategory')}>
                                <div className="subcats">
                                    <img className='subcat-imgs'  src={global.images + "/ecommerce_photos/" + subcat.subCategory_image} />
                                </div>
                                <div>
                                    <p className='product-name' style={{ textAlign: 'center', fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 600, marginBottom: 15 }}>{subcat.subCategory_name}</p>
                                </div>
                            </a>
                        </Col>
                    ))}

                </Row>
                {loading ? <Loader /> :

                <div>
                    <Container>
                        <div style={{ marginTop: 20 }}>
                            
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: 10,
                                }}
                            >
                               
                                <p
                                    className="heading"
                                    style={{ fontFamily: "'lato', sans-serif" }}
                                >
                                    Products
                                </p>
                               
                               
                            </div>
                            {/* )
                 } */}

                            <div>
                                {loading ? (
                                    <Row>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                    </Row>
                                ) : (
                                    // arrival.length <= (limit + 1) ?
                                    subCatPrdlist.length > 0 ?

                                        (
                                            <div
                                                ref={containerRef}
                                            // style={{
                                            //   maxHeight: "400px",
                                            //   overflowY: "auto", overflowX: 'hidden'
                                            // }}
                                            >
                                                <Row>
                                                    {
                                                        subCatPrdlist && subCatPrdlist.map((product) => (
                                                            <Col xs={6} md={2} lg={2} className="spacereduce">
                                                                <Product
                                                                    key={product.id}
                                                                    product={product}
                                                                    className="productimg"
                                                                />
                                                            </Col>
                                                        ))}

                                                </Row>
                                            </div>
                                        ) : (
                                            null
                                        )
                                )}
                            </div>

                        </div>
                    </Container>
                </div>
            }
            </Container>
            
}
        </div>
    )
}
export default SubCategory;