import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  FaUserAlt,
  FaListAlt,
  FaLessThanEqual,
  FaGalacticSenate,
} from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import { MdDeliveryDining, MdLogout } from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../../Components/Loader/Loader";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { connect } from "react-redux";

const data = [{}, {}];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Profile({ cart }) {
  let history = useHistory();
  const vertical = "top";
  const horizontal = "center";

  const [logtoken, setLogtoken] = useState("");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrormessage] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [loader, setLoader] = React.useState(false);
  const [show, setShow] = useState(false);

  const [buldingNo, setBildingNo] = useState("");
  const [zone, setZone] = useState("");
  const [landmark, setLandmark] = useState("");
  const [remarks, setRemarks] = useState("");
  const [pincode, setPincode] = useState("");
  const [streat, setStreat] = useState("");
  const [namea, setNamea] = useState("");
  const [mobilea, setMobilea] = useState("");
  const [area, setArea] = React.useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [areashow, setAreashow] = React.useState(false);
  const [arealist, setArealist] = React.useState([]);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    setLogtoken(logged);
    getAddress();
    getPersonalInfo();
  }, []);

  const areaChange = (e) => {
    setArea(e.target.value);
  };

  const setFields = (e) => {
    setZone(e);
    switch (e) {
      case "1":
        setArea("Doha - Al Jasra");
        break;
      case "2":
        setArea("Doha - Al Bidda");
        break;
      case "3":
        setArea("Doha - Mushaireb");
        break;
      case "4":
        setArea("Doha - Mushaireb");
        break;
      case "5":
        setArea("Doha -Fereej Al Asmakh");
        break;
      case "6":
        setArea("Doha - Old Al Ghanim");
        break;
      case "7":
        setArea("Doha - Al Souq (Souq Waqif)");
        break;
      case "12":
        setAreashow(true);
        var array = [
          { name: "Bidda" },
          { name: "Rumaila" },
          { name: "Wadi Al Sail" },
        ];
        setArealist(array);
        break;

      case "13":
        setArea("Doha - Mushaireb");
        break;
      case "14":
        setArea("Doha - Fereej Abdel Aziz");
        break;
      case "15":
        setArea("Doha - Doha Al Jadeeda");
        break;
      case "16":
        setArea("Doha - Old Al Ghanim");
        break;
      case "17":
        setAreashow(true);
        var array = [{ name: "Doha - Al Ruffaa" }, { name: "Al Hitmi" }];
        setArealist(array);
        break;

      case "18":
        setArea("Doha - Al Mirqab");
        break;
      case "19":
        setArea("Doha - Doha Port");
        break;
      case "20":
        setArea("Doha - Wadi Al Sail");
        break;
      case "21":
        setArea("Doha - Al Rumaila");
        break;
      case "22":
        setArea("Bin Mahmoud");
        break;
      case "23":
        setArea("Bin Mahmoud");
        break;
      case "24":
        setAreashow(true);
        var array = [
          { name: "Doha" },
          { name: "Rawdat Al Khail" },
          { name: "Al Muntazah" },
        ];
        setArealist(array);
        break;
      case "25":
        setAreashow(true);
        var array = [
          { name: "Doha" },
          { name: "Mansoura" },
          { name: "Bin Dirhem" },
        ];
        setArealist(array);
        break;

      case "26":
        setArea("Doha - Najma");
        break;
      case "27":
        setArea("Doha - Umm Ghuwailina");
        break;
      case "28":
        setAreashow(true);
        var array = [
          { name: "Doha" },
          { name: "Al Khulaifat" },
          { name: "Ras Bu Abboud" },
        ];
        setArealist(array);
        break;
      case "29":
        setArea("Doha - Ras Bu Abboud");
        break;
      case "30":
        setArea("Duhail");
        break;
      case "31":
        setArea("Umm Lekhba");
        break;
      case "32":
        setAreashow(true);
        var array = [
          { name: "Madinat Khalifa North" },
          { name: "Dahl Al Hamam" },
        ];
        setArealist(array);
        break;
      case "33":
        setArea("Markhiya");
        break;
      case "34":
        setArea("Madinat Khalifa South");
        break;
      case "35":
        setArea("Fereej Kulaib");
        break;
      case "36":
        setArea("Al Messila");
        break;
      case "37":
        setAreashow(true);
        var array = [
          { name: "New Al Hitmi" },
          { name: "Bin Omran" },
          { name: "Hamad Medical City" },
        ];
        setArealist(array);
        break;

      case "38":
        setArea("Al Sadd");
        break;
      case "39":
        setAreashow(true);
        var array = [
          { name: "Al Nasr" },
          { name: "Al Sadd" },
          { name: "Al Mirqab Al Jadeed" },
        ];
        setArealist(array);
        break;
      case "40":
        setArea("New Slata");
        break;
      case "41":
        setArea("Nuaija");
        break;
      case "42":
        setArea("Hilal");
        break;
      case "43":
        setAreashow(true);
        var array = [
          { name: "Maamoura" },
          { name: "Nuaija" },
          { name: "Fereej Al Ali" },
          { name: "Fereej Al Asiri" },
          { name: "New Fereej Al Khulaifat" },
        ];
        setArealist(array);
        break;
      case "44":
        setArea("Nuaija");
        break;
      case "45":
        setArea("Old Airport");
        break;
      case "46":
        setArea("Thumama");
        break;
      case "47":
        setArea("Thumama");
        break;
      case "48":
        setArea("Doha International Airport");
        break;
      case "49":
        setAreashow(true);
        var array = [
          { name: "Hamad International Airport" },
          { name: "Banana Island" },
          { name: "Ras Bu Fontas" },
        ];
        setArealist(array);
        break;
      case "50":
        setArea("Thumama");
        break;
      case "51":
        setAreashow(true);
        var array = [
          { name: "Bani Hajer" },
          { name: "Al Seej" },
          { name: "Al Themaid" },
          { name: "Rawdat Al Jahhaniya" },
          { name: "Rawdat Egdaim" },
          { name: "Shariya" },
          { name: "Gharaffat Al Rayan" },
          { name: "Izghawa" },
        ];
        setArealist(array);
        break;
      case "52":
        setAreashow(true);
        var array = [
          { name: "Luqta" },
          { name: "Old Al Rayyan" },
          { name: "Al Shaqab" },
          { name: "Lebday" },
          { name: "Education City" },
        ];
        setArealist(array);
        break;
      case "53":
        setAreashow(true);
        var array = [
          { name: "New Al Rayyan" },
          { name: "Muaither" },
          { name: "Wajba" },
          { name: "Rawdat Abal Heeran" },
          { name: "Umm Al Juwashen" },
        ];
        setArealist(array);
        break;
      case "54":
        setAreashow(true);
        var array = [
          { name: "Baaya" },
          { name: "Muraikh" },
          { name: "Mehairja" },
          { name: "Fereej Al Amir" },
          { name: "Luaib" },
          { name: "Aspire Zone" },
          { name: "Fereej Al Soudan" },
        ];
        setArealist(array);
        break;
      case "55":
        setAreashow(true);
        var array = [
          { name: "Fereej Al Soudan" },
          { name: "Al Waab" },
          { name: "Al Aziziya" },
          { name: "Al Ghanim Al Jadeed" },
          { name: "Al Mearad" },
          { name: "Al Sailiya" },
          { name: "Bu Sidra" },
          { name: "Fereej Al Manaseer" },
          { name: "Murra" },
          { name: "Muaither" },
          { name: "Umm Al Seneem" },
        ];
        setArealist(array);
        break;
      case "56":
        setAreashow(true);
        var array = [
          { name: "Bu Hamour" },
          { name: "Ain Khaled" },
          { name: "Mesaimeer" },
          { name: "Maamoura" },
          { name: "Barwa City" },
          { name: "Umm Al Seneem" },
        ];
        setArealist(array);
        break;
      case "57":
        setArea("Industrial Area");
        break;
      case "58":
        setArea("Wholesale Market");
        break;
      // case '59':
      //     setArea("Doha - Mushaireb")
      //     break;
      case "60":
        setArea("Dafna");
        break;
      case "61":
        setAreashow(true);
        var array = [{ name: "Dafna" }, { name: "Al Gassar" }];
        setArealist(array);
        break;
      case "62":
        setArea("Lekhwair");
        break;
      case "63":
        setArea("Onaiza");
        break;
      case "64":
        setArea("Lejbailat");
        break;
      case "65":
        setArea("Onaiza");
        break;
      case "66":
        setAreashow(true);
        var array = [
          { name: "Legtaifiya" },
          { name: "Onaiza" },
          { name: "The Pearl" },
          { name: "Al Gassar" },
          { name: "Katara" },
        ];
        setArealist(array);
        break;
      case "67":
        setArea("Hazm Al Markhiya");
        break;
      case "68":
        setAreashow(true);
        var array = [
          { name: "Jelaiah" },
          { name: "Al Tarfa" },
          { name: "Jeryan Nejaima" },
        ];
        setArealist(array);
        break;
      case "69":
        setAreashow(true);
        var array = [
          { name: "Al Daayen" },
          { name: "Al Egla" },
          { name: "Lusail" },
          { name: "Wadi Al Banat" },
        ];
        setArealist(array);
        break;
      case "70":
        setAreashow(true);
        var array = [
          { name: "Al Daayen" },
          { name: "Leabaib" },
          { name: "Al Ebb" },
          { name: "Al Kheesa" },
          { name: "Rawdat Al Hamama" },
          { name: "Jeryan Jenaihat" },
          { name: "Al Sakhama" },
          { name: "Jery Al Matrooshi" },
          { name: "Semaisma" },
          { name: "Tenbek" },
          { name: "Umm Garn" },
          { name: "Al Jeryan" },
          { name: "Al Kheesa" },
        ];
        setArealist(array);
        break;
      case "71":
        setAreashow(true);
        var array = [
          { name: "Umm Slal" },
          { name: "Al Froosh" },
          { name: "Al Kharaitiyat" },
          { name: "Bu Fessela" },
          { name: "Bu Garn" },
          { name: "Bu Glaila" },
          { name: "Izghawa" },
          { name: "Umm Al Amad" },
          { name: "Umm Ebairiya" },
          { name: "Umm Slal Ali" },
          { name: "Umm Slal Mohammed" },
        ];
        setArealist(array);
        break;
      case "72":
        setArea("Doha - Al Jasra");
        break;
      case "73":
        setArea("Doha - Al Bidda");
        break;
      case "74":
        setAreashow(true);
        var array = [{ name: "Al Khor" }, { name: "Al Thakira" }];
        setArealist(array);
        break;
      case "75":
        setAreashow(true);
        var array = [
          { name: "Al Khor" },
          { name: "Al Thakira" },
          { name: "Ras Laffan" },
        ];
        setArealist(array);
        break;
      case "76":
        setArea("Al Khor");
        break;
      // case '77':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '78':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '79':
      //     setArea("Doha - Mushaireb")
      //     break;
      case "80":
        setArea("Sheehaniya");
        break;
      case "81":
        setAreashow(true);
        var array = [
          { name: "Mebaireek" },
          { name: "New Industrial Area" },
          { name: "Mekaines" },
        ];
        setArealist(array);
        break;
      case "82":
        setArea("Sheehaniya");
        break;
      case "83":
        setArea("Doha - Al Bidda");
        break;
      // case '84':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '85':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '86':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '87':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '88':
      //     setArea("Doha - Mushaireb")
      //     break;
      // case '89':
      //     setArea("Doha - Mushaireb")
      //     break;
      case "90":
        setArea("Wakra");
        break;
      case "91":
        setAreashow(true);
        var array = [
          { name: "Wakra" },
          { name: "Mashaf" },
          { name: "Al Wukair" },
          { name: "Muaither Al Wukair" },
          { name: "Wadi Aba Seleel" },
        ];
        setArealist(array);
        break;
      case "92":
        setAreashow(true);
        var array = [
          { name: "Wakra" },
          { name: "Mesaieed" },
          { name: "Mesaieed Free Zone" },
        ];
        setArealist(array);
        break;
      case "93":
        setAreashow(true);
        var array = [
          { name: "Al Wakra" },
          { name: "Mesaieed Industrial Area" },
          { name: "Sealine" },
        ];
        setArealist(array);
        break;
      default:
        setAreashow(false);
        setArea("");
        break;
    }
  };

  const logoutClick = () => {
    setLoader(true);
    var token = JSON.parse(localStorage.getItem("token"));
    fetch(global.url + "logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setLoader(false);
        console.log("logout", responseJson);
        if (responseJson.error == false) {
          localStorage.setItem("isAuthenticatedfront", false);
          localStorage.removeItem("token");
          localStorage.removeItem("userName");
          localStorage.removeItem("persist:root");
          localStorage.removeItem("userdata");
          history.push("/");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addressClick = () => {
    if (!streat || !remarks || !zone || !buldingNo ){
      alert("please fill  address fields")
      return
    }
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      street: streat,
      remarks: remarks,
      zone: zone,
      area: area,
      buildingno: buldingNo,
      name: namea,
      mobile: mobilea,
    };
    console.log("data address", data);
    fetch(global.url + "EditfetchUserData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("address upload", responseJson);
        if (responseJson.error == false) {
          setOpen(true);
          setSuccessMessage(responseJson.message);
          alert("Address Added !");
          history.push("/checkout");
        } else {
          setOpen(true);
          setErrormessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAddress = () => {
    var token = JSON.parse(localStorage.getItem("token"));
    console.log("token", token);
    var data = {
      showData: true,
    };
    fetch(global.url + "EditfetchUserData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("address response", responseJson);
        if (responseJson.error == false) {
          var data1 = responseJson.data;
          var data = data1.user_address;
          setBildingNo(data.buildingno);
          setZone(data.zone);
          setRemarks(data.remarks);
          setStreat(data.street);
          setNamea(data.name);
          setMobilea(data.mobile);
          setArea(data.area);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPersonalInfo = () => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      showData: true,
    };

    fetch(global.url + "editProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("profile response", responseJson);
        if (responseJson.error == false) {
          var data1 = responseJson.data;
          var data = data1.user_data;
          setUsername(data.user_name);
          setEmail(data.user_email);
          setMobile(data.user_phone);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const personalClick = () => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      name: username,
      email: email,
      phone: mobile,
    };

    fetch(global.url + "editProfile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("profile response", responseJson)
        if (responseJson.error == false) {
          setOpen(true);
          setSuccessMessage(responseJson.message);
        } else {
          setOpen(true);
          setErrormessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePassword = () => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    fetch(global.url + "changePwd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log("profile response", responseJson)
        if (responseJson.error == false) {
          setOpen(true);
          setSuccessMessage(responseJson.message);
          setShow(false);
        } else {
          setOpen(true);
          setErrormessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={{ fontFamily: "'lato', sans-serif", marginTop: "140px" }}>
      <div className="cartTop">
        <Container>
          <div>
            <p
              style={{
                fontSize: 23,
                fontWeight: 500,
                fontFamily: "'lato', sans-serif",
                fontWeight: 400,
              }}
            >
              My Profile
            </p>
          </div>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <div className="profilebox">
                <a>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p
                        style={{
                          fontSize: 14,
                          cursor: "pointer",
                          fontWeight: 500,
                          fontFamily: "'lato', sans-serif",
                        }}
                      >
                        <FaUserAlt
                          color="#000"
                          size={13}
                          style={{ marginRight: 7, marginTop: -2 }}
                        />{" "}
                        My Profile
                      </p>
                    </div>
                    <div
                      style={{
                        height: 53,
                        width: 5,
                        backgroundColor: "#00a085",
                      }}
                    ></div>
                  </div>
                </a>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#d9d9d9",
                  }}
                ></div>
                <a onClick={() => history.push("/order")}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p
                        style={{
                          fontSize: 14,
                          cursor: "pointer",
                          fontWeight: 500,
                          fontFamily: "'lato', sans-serif",
                        }}
                      >
                        <FaListAlt
                          color="#000"
                          size={15}
                          style={{ marginRight: 5, marginTop: -2 }}
                        />{" "}
                        My Orders
                      </p>
                    </div>
                    <div
                      style={{
                        height: 53,
                        width: 5,
                        backgroundColor: "#d9d9d9",
                      }}
                    ></div>
                  </div>
                </a>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#d9d9d9",
                  }}
                ></div>
                <a onClick={() => history.push("/wishlists")}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ padding: 15, paddingBottom: 1 }}>
                      <p
                        style={{
                          fontSize: 14,
                          cursor: "pointer",
                          fontWeight: 500,
                          fontFamily: "'lato', sans-serif",
                        }}
                      >
                        <AiFillHeart
                          color="#000"
                          size={15}
                          style={{ marginRight: 5, marginTop: -2 }}
                        />{" "}
                        My Wishlist
                      </p>
                    </div>
                    <div
                      style={{
                        height: 53,
                        width: 5,
                        backgroundColor: "#d9d9d9",
                      }}
                    ></div>
                  </div>
                </a>
                <div
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#d9d9d9",
                  }}
                ></div>
                {logtoken ? (
                  <a onClick={logoutClick}>
                    {loader ? (
                      <Loader />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ padding: 15, paddingBottom: 1 }}>
                          <p
                            style={{
                              fontSize: 14,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontFamily: "'lato', sans-serif",
                            }}
                          >
                            <MdLogout
                              color="#000"
                              size={16}
                              style={{
                                marginRight: 5,
                                marginLeft: 3,
                                marginTop: -2,
                              }}
                            />{" "}
                            Logout
                          </p>
                        </div>
                        <div
                          style={{
                            height: 53,
                            width: 5,
                            backgroundColor: "#d9d9d9",
                          }}
                        ></div>
                      </div>
                    )}
                  </a>
                ) : (
                  <a onClick={() => history.push("/login")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ padding: 15, paddingBottom: 1 }}>
                        <p
                          style={{
                            fontSize: 14,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                        >
                          <FiLogIn
                            color="#000"
                            size={16}
                            style={{ marginRight: 7, marginTop: -2 }}
                          />{" "}
                          Login
                        </p>
                      </div>
                      <div
                        style={{
                          height: 53,
                          width: 5,
                          backgroundColor: "#d9d9d9",
                        }}
                      ></div>
                    </div>
                  </a>
                )}
              </div>
            </Col>
            <Col xs={12} md={8} lg={9}>
              <div className="my-profilediv">
                <div>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Personal Information
                  </p>
                </div>
                <div>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                          id="outlined-basic"
                          label="Username"
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          style={{
                            fontSize: 14,
                            fontFamily: "'lato', sans-serif",
                            fontWeight: 400,
                          }}
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          id="outlined-basic"
                          label="Mobile Number"
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <Button
                        onClick={() => setShow(true)}
                        className="change-password"
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                  <div style={{ marginTop: 20 }}>
                    <Button onClick={personalClick} className="update-profile">
                      Update Profile
                    </Button>
                  </div>
                </div>
                <div style={{ marginTop: 30 }}>
                  <p
                    style={{
                      fontSize: 18,
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 600,
                    }}
                  >
                    Address Information
                  </p>
                </div>
                <div>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          onChange={(e) => setNamea(e.target.value)}
                          value={namea}
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          onChange={(e) => setMobilea(e.target.value)}
                          value={mobilea}
                          id="outlined-basic"
                          label="Mobile"
                          variant="outlined"
                          size="small"
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          onChange={(e) => setBildingNo(e.target.value)}
                          value={buldingNo}
                          id="outlined-basic"
                          label="Building No."
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          onChange={(e) => setStreat(e.target.value)}
                          value={streat}
                          id="outlined-basic"
                          label="Street"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormControl sx={{ width: "100%", mt: 2 }}>
                        <TextField
                          autoComplete="off"
                          onChange={(e) => setFields(e.target.value)}
                          label="Zone"
                          value={zone}
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Col>
                    {areashow ? (
                      <Col xs={12} md={6}>
                        <FormControl sx={{ width: "100%", mt: 2 }}>
                          <InputLabel
                            style={{
                              marginTop: -7,
                              fontSize: 14,
                              cursor: "pointer",
                              fontWeight: 500,
                              fontFamily: "'lato', sans-serif",
                            }}
                            id="demo-simple-select-helper-label"
                          >
                            Area
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={area}
                            label="Age"
                            onChange={areaChange}
                            size="small"
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {arealist.map((row, index) => {
                              return (
                                <MenuItem value={row.name}>{row.name}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Col>
                    ) : (
                      <Col xs={12} md={6}>
                        <FormControl sx={{ width: "100%", mt: 2 }}>
                          <TextField
                            value={area}
                            onChange={(e) => setArea(e.target.value)}
                            label="Area"
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'blue',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'blue',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'blue',
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Col>
                    )}
                    <Col xs={12} md={6}>
                      <FormControl sx={{ mt: 2, width: "100%" }}>
                        <TextField
                          onChange={(e) => setRemarks(e.target.value)}
                          value={remarks}
                          id="outlined-basic"
                          label="Remarks"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            sx: {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'blue',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <div style={{ marginTop: 20 }}>
                    <Button onClick={addressClick} className="update-address">
                      Update Address
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>
        )}
        <Modal
          size="md"
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              style={{
                fontSize: 18,
                fontFamily: "'lato', sans-serif",
                fontWeight: 600,
              }}
            >
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <TextField
                    style={{
                      fontSize: 18,
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 400,
                    }}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    id="outlined-basic"
                    label="Current Password"
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Col>
              <Col xs={12}>
                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <TextField
                    style={{
                      fontSize: 18,
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 400,
                    }}
                    onChange={(e) => setNewPassword(e.target.value)}
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Col>
              <Col xs={12}>
                <FormControl sx={{ mt: 2, width: "100%" }}>
                  <TextField
                    style={{
                      fontSize: 18,
                      fontFamily: "'lato', sans-serif",
                      fontWeight: 400,
                    }}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    id="outlined-basic"
                    label="Confirm Password"
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Col>
              <Col xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={() => setShow(false)}
                    variant="danger"
                    style={{
                      boxShadow: "none",
                      border: "none",
                      marginTop: 20,
                      fontSize: 14,
                      cursor: "pointer",
                      fontWeight: 500,
                      fontFamily: "'lato', sans-serif",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={updatePassword} className="save-password">
                    Save Password
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps)(Profile);
