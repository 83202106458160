import React, { useState, useEffect } from "react";
import './Checkout.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import img from '../../Assets/Images/noimages.png';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../Components/Loader/Loader'

import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Checkout() {
    let history = useHistory();
    const [zone, setZone] = useState('');
    const [streat, setStreat] = useState('');
    const [remarks, setRemarks] = useState('');
    const [remarks1, setRemarks1] = useState('');

    const [state, setState] = useState('');
    const [area, setArea] = useState('');
    const [buldingNo, setBildingNo] = useState('');
    const [mobile, setMobile] = useState('');
    const [cartList, setCartList] = useState([])
    const [comboCartList, setComboCartList] = useState([]);

    const [zone1, setZone1] = useState('');
    const [streat1, setStreat1] = useState('');
    const [buldingNo1, setBildingNo1] = useState('');

    const [open, setOpen] = React.useState(false);
    const [cartQty, setCartQty] = useState('');
    const [cartTotal, setCartTotal] = useState('');
    const [total, setTotal] = useState(0);
    const [shippingCharge, setShippingcharge] = useState(10);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [namea, setNamea] = useState('');
    const [mobilea, setMobilea] = useState('');

    useEffect(() => {
        getAddress();
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    useEffect(() => {
        getCartData()
    }, [])

    // const getCartData = () => {
    //     setLoader(true)
    //     var token = JSON.parse(localStorage.getItem("token"))
    //     fetch(global.url + "displayCartfrontend", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'APPKEY': 'erp6129',
    //             'Authorization': 'Bearer ' + token
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             console.log("cart item", responseJson)
    //             setLoader(false)
    //             if (responseJson.error == false) {
    //                 setCartList(responseJson.singleCart);
    //                 console.log(responseJson.singleCart, "single cart");
    //                 setComboCartList(responseJson.ComboCart);
    //                 console.log(responseJson.ComboCart, "combo cart");
    //                 setCartQty(responseJson.cart_count)
    //                 setCartTotal(responseJson.total)

    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }
    const getCartData = async () => {
        try {
            setLoader(true);
            var token = JSON.parse(localStorage.getItem("token"));

            const response = await fetch(global.url + "displayCartfrontend", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129',
                    'Authorization': 'Bearer ' + token
                },
            });

            const responseJson = await response.json();
            console.log("cart item", responseJson);
            setLoader(false);

            if (responseJson.error === false) {
                setCartList(responseJson.singleCart);
                console.log(responseJson.singleCart, "single cart");
                setComboCartList(responseJson.ComboCart);
                console.log(responseJson.ComboCart, "combo cart");
                setCartQty(responseJson.cart_count);
                setCartTotal(responseJson.total);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const addressClick = () => {
        if (!streat || !zone || !buldingNo) {
            alert("please fill all address fields")
            return
        }
        var token = JSON.parse(localStorage.getItem("token"));
        var data = {
            street: streat,
            remarks: remarks,
            zone: zone,
            area: area,
            buildingno: buldingNo,
            name: namea,
            mobile: mobilea,
        };
        console.log("data address", data);
        fetch(global.url + "EditfetchUserData", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                APPKEY: "erp6129",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("address upload", responseJson);
                if (responseJson.error == false) {
                   
                    alert("Address Added !");
                    history.push("/checkout");
                    getAddress()
                } else {
                    
                    getAddress()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAddress = () => {
        var token = JSON.parse(localStorage.getItem("token"))
        var data = {
            "showData": true
        }
        fetch(global.url + "EditfetchUserData", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'APPKEY': 'erp6129',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("address response :", responseJson)
                if (responseJson.error == false) {
                    // if(!responseJson.data.user_address || responseJson.data.user_address==null){
                    //     alert("Please Fill All Address Details")
                    //     history.push('/profile')
                    // }
                    console.log(responseJson)
                    var data1 = responseJson.data
                    var data = data1.user_address
                    console.log(data, "sdfsf5dsfsd")
                    setBildingNo(data.buildingno)
                    setZone(data.zone)
                    setRemarks(data.remarks)
                    setStreat(data.street)
                    setNamea(data.name)
                    setMobilea(data.mobile)
                    setArea(data.area)

                    setBildingNo1(data.buildingno)
                    setZone1(data.zone)
                    setStreat1(data.street)
                    setRemarks1(data.remarks)
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onProceed = () => {
        if (buldingNo1 == null || streat1 == null || remarks1 == null || zone1 == null ) {
            
            alert("Please Fill All Address Details")
            // history.push('/profile')
            return

        }
        else {
            var token = JSON.parse(localStorage.getItem("token"))
            var data = {
                "delivery_charge": shippingCharge,
                "salesorder_subtotal": cartTotal,
                "salesorder_total": cartTotal + shippingCharge,
                "ordertype": 1
            }
            console.log('data :', data)
            fetch(global.url + "finalCheckoutfrontend", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("finalCheckout responseJson", responseJson)
                    if (responseJson.error == false) {
                        setMessage(responseJson.message)
                        setOpen(true)
                        history.push('/success')
                        // window.location.reload()
                    }
                    else {
                        setOpen(true)
                        // alert("Please Fill Address Details")
                        alert(responseJson.message)
                        history.push('/profile')
                        // setMessage(responseJson.message)
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    return (
        <div className="cartTop">
            <div className="checkout-maindiv">
                <Container>
                    {loader ?
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: 50 }}>
                                <Loader />
                            </div>
                        </div>
                        :
                        <Row>
                            <Col xs={12} md={7} lg={8}>
                                <div className='checkout-div' style={{ padding: 20 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <p style={{ fontSize: 16, marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500, paddingBottom: 10 }}>Delivery Address</p>

                                    </div>
                                    <div >
                                        <Row>
                                            <Col md={12} lg={5} xs={12}>
                                                {
                                                    loader ?
                                                        <Skeleton variant="text" /> :
                                                        <div style={{ fontFamily: "'lato', sans-serif", fontWeight: 500 }}>
                                                            <p style={{ fontSize: 13 }}>{!namea ? null : `Name: ${namea}`}</p>
                                                            <p style={{ fontSize: 13 }}>{!mobilea ? null : `Mobile: ${mobilea}`}</p>
                                                            <p style={{ fontSize: 13 }}><span >Building No.: {buldingNo1}</span></p>
                                                            <p style={{ fontSize: 13 }}><span >Street : {streat1}</span></p>
                                                            <p style={{ fontSize: 13 }}><span >Zone: {zone1}</span></p>
                                                            <p style={{ fontSize: 13 }}><span >{!area ? null : `Area: ${area}`}</span></p>
                                                            <p style={{ fontSize: 13 }}><span >Remarks: {remarks1}</span></p>
                                                            <a>
                                                                <p onClick={() => history.push('/profile')} style={{ fontSize: 14, cursor: 'pointer', marginBottom: 'unset', fontFamily: "'lato', sans-serif", fontWeight: 500 }}><span style={{border:'1px solid #198754',color:'#198754',borderRadius:'4px',padding:'2px'}}>Change</span></p>
                                                            </a>
                                                        </div>
                                                }
                                            </Col>
                                            <Col md={12} lg={7} xs={12}>
                                                <div style={{ border: '3px solid #000', borderRadius: '10px', padding: '5px', height: 'fit-content', fontFamily: "'lato',san-serif", width: 'auto' }}>
                                                    <div >
                                                        <Row style={{ width: '100%', position: 'relative', margin: '0px', marginBottom: '10px' }}>

                                                            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#000', borderRadius: '10px', border: '6px solid #fffff' }}>
                                                                <p style={{ marginBottom: '10px' }}>Remarks</p>
                                                                <input value={remarks} onChange={(e)=>setRemarks(e.target.value)} style={{ fontSize: '15px', textAlign: "center", color: '#000', width: '100%' }}></input>
                                                            </Col>
                                                            <div style={{ position: 'absolute', display: 'flex', justifyContent: 'flex-end', flexGrow: '1', gap: '5px' }}>
                                                                {/* <button style={{ border: '2px solid #990f2fd9', color: '#990f2fd9', borderRadius: '5px', fontSize: '10px' }}>
                                                                    
                                                                    add</button> */}
                                                                <button onClick={addressClick} style={{ border: '2px solid #990f2fd9', backgroundColor: '#990f2fd9', color: '#fff', borderRadius: '5px', fontSize: '10px' }}>save</button>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div style={{ border: '10px solid #1f34c9', borderRadius: '21px', height: 'fit-content' }}>
                                                        <div >

                                                            <div >
                                                                <Row style={{ borderRadius: '10px', border: '6px solid #ffff', width: '100%', position: 'relative', margin: '0px', backgroundColor: '#1f34c9' }}>
                                                                    <Col md={12} xs={12} lg={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#1f34c9', color: '#ffff', borderRadius: '10px', border: '6px solid #fffff' }}>
                                                                        <p style={{ marginBottom: '0px' }}>Building No.</p>
                                                                        <input  value={buldingNo} onChange={(e) => setBildingNo(e.target.value)} style={{ fontSize: '25px', textAlign: "center", border: 'none', outline: 'none', color: '#ffff', backgroundColor: '#1f34c9', width: '-webkit-fill-available' }} />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div >
                                                                <Row style={{ borderRadius: '10px', border: '6px solid #ffff', margin: '0px', backgroundColor: '#1f34c9', color: '#ffff' }}>
                                                                    <Col md={3} xs={3} lg={3} style={{ display: 'flex', flexDirection: 'column', borderRight: '3px solid #ffff' }}>
                                                                        <p style={{ margin: '0px' }}>Zone</p>
                                                                        <input value={zone} onChange={(e) => setZone(e.target.value)} style={{ border: 'none', outline: 'none', color: '#ffff', backgroundColor: '#1f34c9' }}></input>
                                                                    </Col>
                                                                    <Col md={9} xs={9} lg={9} style={{ display: 'flex', flexDirection: 'column', borderLeft: '3px solid #ffff' }}>
                                                                        <p style={{ margin: '0px' }}>Street</p>
                                                                        <input value={streat} onChange={(e) => setStreat(e.target.value)} style={{ border: 'none', outline: 'none', color: '#ffff', backgroundColor: '#1f34c9' }}></input>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </div>
                                <div className='checkout-div' style={{ padding: 20 }}>
                                    {
                                        cartList.map((item, k) => {
                                            return (
                                                <Row style={{ marginTop: 10 }}>
                                                    <Col xs={12} lg={4}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                item.image == null ?
                                                                    <img alt="img" src={img} style={{ width: 120, height: 120 }} /> :
                                                                    <img alt="img" src={global.images + "/ecommerce_photos/" + item.image} style={{ width: 120, height: 120 }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} lg={8}>
                                                        <div className='checkout-prodiv' style={{ fontFamily: "'lato', sans-serif", fontWeight: 500 }}>
                                                            <p
                                                                // style={{ fontSize: 15, marginBottom: 'unset', marginTop: 15 }}
                                                                className="item-cname"
                                                            >{item.name}</p>
                                                            <p className="item-csize"
                                                            //  style={{ fontSize: 13, color: '#a9a9a9' }}
                                                            >{item.color}, {item.size}</p>
                                                            <p className="item-csize"
                                                            // style={{ fontSize: 13, color: '#a9a9a9' }}
                                                            >QTY - {item.quantity}</p>
                                                            <p className="item-cprice"
                                                            //  style={{ color: '#FF5722', fontSize: 16, marginTop: 20 }}
                                                            >QAR {item.price}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }


                                    {
                                        comboCartList.map((item, k) => {
                                            return (
                                                <Row style={{ marginTop: 10 }}>
                                                    <Col xs={12} lg={4}>
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            {
                                                                item.image == null ?
                                                                    <img alt="img" src={img} style={{ width: 120, height: 120 }} /> :
                                                                    <img alt="img" src={global.images + "/combo_photos/" + item.image} style={{ width: 120, height: 120 }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} lg={8}>
                                                        <div className='checkout-prodiv' style={{ fontFamily: "'lato', sans-serif", fontWeight: 500 }}>
                                                            <p className="item-cname"
                                                            // style={{ fontSize: 15, marginBottom: 'unset', marginTop: 15 }}
                                                            >{item.name}</p>
                                                            <p className="item-csize"
                                                            // style={{ fontSize: 13, color: '#a9a9a9' }}
                                                            >{item.color}, {item.size}</p>
                                                            <p className="item-csize"
                                                            //  style={{ fontSize: 13, color: '#a9a9a9' }}
                                                            >QTY - {item.quantity}</p>
                                                            <p className="item-cprice"
                                                            // style={{ color: '#FF5722', fontSize: 16,  marginTop: 20 }}
                                                            >QAR {item.price}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            <Col xs={12} md={5} lg={4}>
                                <div className='checkout-div'>
                                    <div style={{ padding: 20 }}>
                                        <p style={{ marginBottom: 'unset', fontSize: 18, fontWeight: 600, fontFamily: "'lato', sans-serif" }}>Order Summary</p>
                                    </div>
                                    <div style={{ width: '100%', height: 1, backgroundColor: '#d3d3d3' }}></div>
                                    <div style={{ padding: 20 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p className="check-checkoutpara"   >Subtotal ( {cartQty} Items )</p>
                                            <p className="check-checkoutpara"   >QAR {cartTotal}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <p className="check-checkoutpara"   >Total shipping Charge</p>
                                            {cartTotal < 200 ?
                                                <p className="check-checkoutpara"   >QAR {shippingCharge}</p> :
                                                <p className="check-checkoutfree" >Free</p>
                                            }
                                        </div>
                                        <div style={{ width: '100%', height: 1, backgroundColor: '#d3d3d3' }}></div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 13 }}>
                                            <p className="check-checkoutpara"   >Amount Payable</p>
                                            {cartTotal < 200 ?
                                                <p className="check-checkoutpara"   >QAR {cartTotal + shippingCharge}</p> :
                                                <p className="check-checkoutpara"   >QAR {cartTotal}</p>
                                            }
                                        </div>
                                        <div style={{ width: '100%', height: 1, backgroundColor: '#d3d3d3' }}></div>
                                        <button onClick={onProceed} type="button"
                                            class="btn btn-primary" style={{ backgroundColor: '#1f2e6c', width: '100%', fontSize: 13, fontFamily: "'lato', sans-serif", fontWeight: 400 }}
                                        >Proceed</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}

export default Checkout;