import React, { useEffect, useLayoutEffect } from "react";

function ImageDirectory() {
    // const globalValue = global.url

    useEffect(() => {
        // localStorage.removeItem("ApiId");
        apiValue()
        localStorage.getItem("ApiId")
    }, [])

    async function apiValue() {
        if (localStorage.getItem("ApiId")) {
            // return localStorage.getItem("ApiId");
          return localStorage.getItem("ApiId");
        } else {
            const response = await fetch(global.url + 'selectpath', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'APPKEY': 'erp6129'
                },
            });
            const responseJson = await response.json();
            if (responseJson.error === false) {
                localStorage.setItem("ApiId", responseJson.data.master_id);
                return localStorage.getItem("ApiId");
            } else {
                console.error(responseJson.message);
            }
        }
    }
    
    return (
        <div>
        </div>
    )
}
export default ImageDirectory;