import React from "react";
import AppNavigator from "./router.js";
import ImageDirectory from "./directory.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

function App() {
  //  global.appKey ="erp6129";

  // global.url = "http://localhost/erp_old/public/api/";
  // global.images = "http://localhost/erp_old/public/";

  // global.url = "https://ecommerceb.galaxyhomescentre.com/public/api/";
  // global.images = "https://ecommerceb.galaxyhomescentre.com/public/";

  // global.url = "https://ecommerceb.exouzia.in/public/api/";
  // global.images = "https://ecommerceb.exouzia.in/public/";

  // global.url = "https://ecommerceb.exouzia.in/public/api/";
  // global.images = "https://ecommerceb.exouzia.in/public/" + global.imageKey;

  console.log(global.imageKey, "global img");

  // global.url = "https://badar.qa/core/public/api/";
  // global.images = "https://badar.qa/core/public";

  
  global.url = "https://nasshopy.com/core/public/api/";
  global.images = "https://nasshopy.com/core/public";

  //  global.url = "https://snap.qa/core/public/api/";
  // global.images = "https://snap.qa/core/public/" + global.imageKey;

  global.imageKey = localStorage.getItem("ApiId");

  // global.url = " https://ecommerceb.nasiyb.com/public/api/";
  // global.images = "https://ecommerceb.nasiyb.com/public/";

  // global.url = "https://snap.qa/core/public/api/";
  // global.images = "https://snap.qa/core/public/";

  // global.url = "https://skydunz.com/core/public/api/";
  // global.images = "https://skydunz.com/core/public/";

  // global.url = "https://badar.qa/core/public/api/";
  // global.images = "https://badar.qa/core/public/";

  // global.url = "https://websitesnap.nasiyb.com/core/public/api/";
  // global.images = "https://websitesnap.nasiyb.com/core/public/";

  // global.url = "http://192.168.18.79:88/erp/public/api/";
  // global.images = "http://192.168.18.79:88/erp/public/";

  // global.url = "http://fetcherp.nascaffe.com/erp/public/api/";
  // global.images = "http://fetcherp.nascaffe.com/erp/public/";

  // global.url = "http://erpdemo.fetch.qa/erp/public/api/";
  // global.images = "http://erpdemo.fetch.qa/erp/public/";

  // global.url = "https://erp.fetch.qa/erp/public/api/";
  // global.images = "https://erp.fetch.qa/erp/public/";

  return (
    <div className="App">
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
      </div>
      {/* <ImageDirectory /> */}
      <AppNavigator />
    </div>
  );
}

export default App;
