import './NewArrival.css';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import {
  addToCart, adjustItemQty,
  refresh
} from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import img from '../../Assets/Images/noimages.png';
import { LiaCartArrowDownSolid } from "react-icons/lia";
import { IoMdHeartEmpty } from "react-icons/io";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  console.log(ref, "ref1")
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const data = [{}, {}, {}, {}, {}, {}, {}, {}]

function Products({ product, addToCart, cart, refresh, adjustQty }) {
  console.log(addToCart, "addtocart1")
  console.log(cart, "cart 1")
  console.log(refresh, "refresh 2")
  console.log(product, "prd");
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [logtoken, setLogtoken] = useState('');
  const [message, setMessage] = useState('');
  const [cartView, setCartview] = React.useState(false)
  const [cartCount, setCartCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [cartList, setCartList] = useState([])
  const [comboCartList, setComboCartList] = useState([]);
  const [details, setDetails] = useState({});
  const [cartQty, setCartQty] = useState('');
  const [cartTotal, setCartTotal] = useState('');
  const [isFilled, setIsFilled] = useState(false);
  const [wishlistData,setWishListData] = useState([]);


  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const toClick = (product) => {
  //   console.log("product", product)
  //   addToCart(product)
  //   setOpen(true)
  //   viewCart()
  // }

  const closeviewCart = () => {
    setCartview(false)
  }

  const contHome = () => {
    history.push('/')
    setCartview(false)
  }

  useEffect(() => {
    let count = 0;
    var total = 0;
    cart.forEach((item) => {
      count += item.qty;
      total += item.qty * item.id.pb_salesrate;
    })
    setCartCount(count);
    setTotal(total);
    console.log('cart', cart)

  }, [cart, cartCount.toExponential]);

  // const AddCart = (id) => {
  //   console.log("id", id)
  //   var token = JSON.parse(localStorage.getItem("token"))
  //   var data = {
  //     "productid": id,
  //     "quantity": 1,
  //     "iscombo": 0

  //   }
  //   console.log("dataarr", data)
  //   fetch(global.url + "addCartfrontend", {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'APPKEY': 'erp6129',
  //       'Authorization': 'Bearer ' + token
  //     },
  //     body: JSON.stringify(data)
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       viewCart()
  //       getCartData()
  //       if (responseJson.error == false) {
  //         setOpen(true)
  //         setMessage(responseJson.message)
  //         refresh("")
  //       }
  //       else {
  //         setOpen(true)
  //         setMessage(responseJson.message)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  //   const getCartData = () => {
  //     var token = JSON.parse(localStorage.getItem("token"))
  //     fetch(global.url + "displayCartfrontend", {
  //         method: "POST",
  //          headers: {
  //              'Content-Type': 'application/json',
  //              'APPKEY': 'erp6129',
  //              'Authorization' : 'Bearer ' + token
  //          },
  //     })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log("responseJson cart123", responseJson);
  //       if(responseJson.error == false){
  //         setCartList(responseJson.singleCart);
  //         console.log(responseJson.singleCart,"single cart");
  //         setComboCartList(responseJson.ComboCart);
  //         console.log(responseJson.ComboCart,"combo cart");
  //         setCartQty(responseJson.cart_count);
  //         setCartTotal(responseJson.total);
  //       } else {
  //         setCartList([]);
  //         setComboCartList([])
  //         setCartQty(0);
  //         setCartTotal(0);
  //       }
  //     })
  //     .catch((error)=>{
  //         console.error(error)
  //     })
  // }

  const getCartData = async () => {
    try {
      // Retrieve token from localStorage
      var token = JSON.parse(localStorage.getItem("token"));

      // Make a POST request to fetch cart data
      const response = await fetch(global.url + "displayCartfrontend", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'APPKEY': 'erp6129',
          'Authorization': 'Bearer ' + token
        },
      });

      // Parse response as JSON
      const responseJson = await response.json();

      console.log("responseJson cart123", responseJson);

      // If there is no error in the response, update cart data
      if (responseJson.error == false) {
        setCartList(responseJson.singleCart);
        console.log(responseJson.singleCart, "single cart");
        setComboCartList(responseJson.ComboCart);
        console.log(responseJson.ComboCart, "combo cart");
        setCartQty(responseJson.cart_count);
        setCartTotal(responseJson.total);
      } else {
        // If there is an error, reset cart-related states
        setCartList([]);
        setComboCartList([]);
        setCartQty(0);
        setCartTotal(0);
      }
    } catch (error) {
      console.error(error); // Log any errors that occur during the request
    }
  }

  const AddCart = (batch1) => {
    var token = JSON.parse(localStorage.getItem("token"))
    var data = {
      "productid": batch1,
      "quantity": 1,
      "iscombo": 0
    }
    fetch(global.url + "addCartfrontend", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error == false) {
          console.log(responseJson, "rsp addcart")
          setOpen(true)
          setMessage("Added To Cart")
          refresh("")
          viewCart()
          getCartData()
        } else {
          refresh("")
          setOpen(true)
          setMessage(responseJson.message)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const toClick = (data1, batchids) => {
    console.log("add to cart called by toclick function")
    addToCart(data1);
    adjustQty(batchids, 1);
    setMessage("Added to Cart")
    setOpen(true)
    viewCart()
  }

  // const getData = (prodctId1, batch1) => {

  //   if (logtoken == true) {
  //     console.log("add to cart called through api")
  //     var user = JSON.parse(localStorage.getItem("userdata"))
  //     console.log('user', user)
  //     var data = {
  //       'product_id': prodctId1,
  //       'batch_id': batch1,
  //       'user_id': user.user_id
  //     }
  //     console.log("getData", data)
  //     fetch(global.url + "viewProductLatest", {
  //       method: "POST",
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'APPKEY': 'erp6129'
  //       },
  //       body: JSON.stringify(data)
  //     }).then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log('productdetails 121', responseJson)
  //         if (responseJson.error == false) {
  //           AddCart(batch1)
  //           setDetails(responseJson.data.details)
  //           console.log(responseJson.data.details, "r d if")
  //         } else {
  //           setDetails([])
  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error, "errror");
  //       })
  //   } else {
  //     var data = {
  //       'product_id': prodctId1,
  //       'batch_id': batch1,
  //     }
  //     console.log("getData", data)
  //     fetch(global.url + "viewProductLatest", {
  //       method: "POST",
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'APPKEY': 'erp6129'
  //       },
  //       body: JSON.stringify(data)
  //     }).then((response) => response.json())
  //       .then((responseJson) => {
  //         console.log('productdetails 123', responseJson)
  //         if (responseJson.error == false) {
  //           toClick(responseJson.data.details, batch1)
  //           setDetails(responseJson.data.details)
  //           console.log(responseJson.data.details, "r d else")
  //         } else {
  //           setDetails([])
  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error)
  //       })
  //   }

  // }




  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"))
    setLogtoken(logged)
    getCartData()
  }, [])

  useEffect(() => {
    setMessage("")
  }, [cart])

  const viewCart = () => {
    setCartview(true)
  }

  

  const handleIconClick = (id, isFilled) => {
    wishlistClick(id, isFilled);
    setIsFilled(!isFilled);
  };

  const wishlistClick = (id, isFilled) => {
    var token = JSON.parse(localStorage.getItem("token"));
    var data = {
      batch_id: id,
      mode: isFilled ? 'delete' : 'add',
    };

    fetch(global.url + "AddtowishlistOrDelete", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'APPKEY': 'erp6129',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.error === false) {
          console.log(responseJson, "add to wishlist")

        } else {
          console.log(responseJson, "not in wish list")
        }
        console.log("order cancel response", responseJson);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWishList = () => {
   
    var token = JSON.parse(localStorage.getItem("token"))
     fetch(global.url + "viewMyWishLists", {
         method: "POST",
         headers: {
             'Content-Type': 'application/json',
             'APPKEY': 'erp6129',
             'Authorization' : 'Bearer ' + token 
         },
     
     })
     .then((response)=> response.json())
     .then((responseJson)=>{
        console.log('wishlist3654156', responseJson)
        
         if(responseJson.error == false){
            // setWishlist(responseJson.data);
            console.log(responseJson.data,"sdfsdfsdfsdf");
            setWishListData(responseJson.data)
         }
         else{
            // setWishlist([])
         }
         
     })
     .catch((error)=>{
         console.log(error)
     })
}
useEffect(()=>{
  getWishList()
},[])

  return (
    <div className='newarrivaldiv' style={{ fontFamily: "'lato', sans-serif" }}>
      <div className="main-image-container">
        {/* <a onClick={() => history.push('/details/' + product.productid + '/' + product.batchid)}> */}
          <Slider {...settings}>
            {product.images.length === 0 ? (
              <div>
                <img alt="img" src={img} />
              </div>
            ) : (
              product.images.map((productImage) => (
                <div key={productImage.id}>
                  <img
                    alt="img"
                    src={global.images + "/ecommerce_photos/" + productImage.pei_image}
                    className="imagehome"
                  />
                </div>
              ))
            )}
          </Slider>

          <div style={{ marginTop: 10, position: 'relative' }}>
            <p className='product-name' style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 600 }}>{product.name}</p>
            <Row >
              <Col><p className='price' style={{ marginBottom: 'unset' }}>{product.currency}  {product.pb_salesrate} </p></Col>
              {/* {
              logtoken ? 
              <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => AddCart( product.batchid)} /></Col>
              :
              <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} /></Col>
            } */}
              {/* <Col>  <LiaCartArrowDownSolid style={{ height: '20px', width: '20px', cursor: 'pointer' }} onClick={() => getData(product.productid, product.batchid)} /></Col> */}
              {/* <Col style={{ display: 'flex', justifyContent: 'end', fontSize: '20px' }}>
                {product.wishlist == true ? (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={()=>handleIconClick(product.batchid, true)}
                  />
                ) : (
                  <IoMdHeartEmpty
                    style={{ cursor: 'pointer' }}
                    onClick={()=>handleIconClick(product.batchid, false)}
                  />
                )}
              </Col> */}
            </Row>

            {/* <p style={{fontSize: 12}}><span style={{textDecorationLine: 'line-through', color: '#5c5c5c'}}>{product.currency} {product.pb_salesrate}</span></p> */}
            {/* <div style={{marginTop: 25}}>
                        {logtoken ?
                          <Button onClick={() => AddCart(product.pb_id)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>
                          :<Button onClick={() => toClick(product)} variant="warning home-cartbtn" className="btn-warning">Add To Cart</Button>}
                      </div> */}

          </div>
        {/* </a> */}
      </div>
      {cartView ?
        <div className="cart-content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <p style={{ fontSize: 16, marginTop: 1, fontFamily: "'lato', sans-serif", fontWeight: 600 }}>Cart</p>
            </div>
            <a onClick={closeviewCart}>
              <AiOutlineClose color="#000" size={18} />
            </a>
          </div>
          {logtoken ?
            <div className='innerdiv' style={{ fontFamily: "'lato', sans-serif" }}>
              {cartList.length === 0 && comboCartList.length === 0 ? <p style={{ fontSize: 14, fontWeight: 400, textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                <div>
                  <div>
                    {cartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                item.image == null ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + "/ecommerce_photos/" + item.image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p className='product-name' style={{ fontSize: 11, fontFamily: "'lato', sans-serif", fontWeight: 500 }}>{item.name}</p>
                              <p style={{ fontSize: 11, fontWeight: 500, color: '#000', marginBottom: 'unset', }}>{item.quantity} x {item.price} {item.currency}</p>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                    {comboCartList.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              {
                                item.image == null ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + "/combo_photos/" + item.image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div>
                              <p className='product-name' style={{ fontSize: 11 }}>{item.name}</p>
                              {/* <p style={{fontSize: 11, color: '#000', marginBottom: 'unset', fontWeight: 'bold'}}>{item.quantity} x {item.price} {item.currency}</p> */}
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div> :
            <div className='innerdiv' style={{ fontFamily: "'lato', sans-serif" }}>
              {cart.length == 0 ? <p style={{ fontSize: 14, fontWeight: 500, textAlign: 'center', marginTop: 30 }}>No Cart Added</p> :
                <div>
                  <div>
                    {cart.map((item, index) => (
                      <div>
                        <Row>
                          <Col xs={3}>
                            <div style={{ justifyContent: 'center' }}>
                              {
                                item.id.images.length == 0 ?
                                  <img alt="img" src={img} style={{ width: 80, height: 80 }} /> :
                                  <img alt="img" src={global.images + "/ecommerce_photos/" + item.id.images[0].pei_image} style={{ width: 80, height: 80 }} />
                              }
                            </div>
                          </Col>
                          <Col xs={9}>
                            <div style={{ fontWeight: 500, fontFamily: "'lato', sans-serif" }}>
                              <p className='product-name' style={{ fontSize: 11, fontWeight: 500 }}>{item.id.name}</p>
                              <p style={{ fontSize: 12, color: '#000', marginBottom: 'unset', fontWeight: 500 }}>{item.qty} x {item.id.pb_salesrate} {item.id.currency}</p>
                            </div>
                          </Col>
                        </Row>
                        <div style={{ backgroundColor: '#d3d3d3', width: '100%', height: 1 }}></div>
                      </div>
                    ))}
                  </div>

                </div>
              }
            </div>
          }
          {logtoken ?
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontWeight: 500, fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>Sub total</p>
                <p style={{ fontWeight: 500, fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>QAR {cartTotal}</p>
              </div>
              <Row>
                <Col xs={6}>
                  <Button onClick={() => history.push('/checkout')} variant="primary" className='checkoutbtn'
                  //  style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Checkout</Button>
                </Col>
                <Col xs={6}>
                  <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Continue Shopping</Button>
                </Col>
              </Row>
            </div> :
            <div style={{ marginTop: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>Sub total</p>
                <p style={{ fontSize: 14, fontFamily: "'lato', sans-serif", fontWeight: 400 }}>QAR {total}</p>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <Button onClick={() => history.push('/login')} variant="primary" className='checkoutbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Checkout</Button>
                </Col>
                <Col xs={12} md={6}>
                  <Button onClick={() => contHome()} variant="light" className='continueshoppingbtn'
                  // style={{ width: '100%', borderColor: '#00a085', backgroundColor: '#00a085', color: '#fff', marginTop: 10, boxShadow: 'none', width: '100%',fontSize: 13 ,fontFamily: "'lato', sans-serif", fontWeight: 400}}
                  >Continue Shopping</Button>
                </Col>
              </Row>
            </div>
          }
        </div> : null}


      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => dispatch(addToCart(item)),
    adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
    refresh: (item) => dispatch(refresh(item))
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);