import React from 'react';
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import {Container, Row, Col, Button } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


function Category(props){
    let history = useHistory();

  
    return(
        <Row>
            <div  style={{fontFamily: "'lato', sans-serif"}}>
                    <div class="luxury-experiences__grid">
                        {
                            props.subcatlist.map((item ,index) => {
                                return(
                                    index == 12 ? null: item.category_name === null ? null :
                                    <a target="_blank" onClick={() => history.push('/category/' + item.subCategory_category )} class="luxury-experiences__grid-item img-hover-zoom" style={{cursor: 'pointer'}}>
                                            <img alt="img" class="lazy-image luxury-experiences__grid-image lazy-image-loaded" sizes="100vw" src={global.images +'/ecommerce_photos/' + item.category_image}/>
                                            <div class="luxury-experiences__grid-item-title">{item.category_name}</div>
                                    </a>
                                )}
                            )
                        }   
                    </div>
                </div>
        </Row>
    );
}
export default Category;