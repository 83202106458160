import * as actionTypes from "./shopping-types";
import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE
} from './shopping-actions';

const INITIAL_STATE = {
  products: [],
  cart: [],
  currentItem: null,
  refreshitem: false
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART:
      console.log("Action payload:", action.payload);
      const item = action.payload;
      const isExistingItem = state.cart.findIndex((cartItem) => {
        if (cartItem.id.batchid && item.id.batchid) {
          // For single products
          return cartItem.id.batchid === item.id.batchid;
        } else if (cartItem.id.products_id && item.id.products_id) {
          // For combo products with products_id
          return cartItem.id.products_id === item.id.products_id;
        }
    
        return false;
      });
    
      console.log("isExistingItem index:", isExistingItem);
    
      return {
        ...state,
        cart: isExistingItem !== -1
          ? state.cart.map((cartItem, index) =>
              index === isExistingItem
                ? { ...cartItem, qty: cartItem.qty + 1 } 
                : cartItem
            )
          : [...state.cart, { ...item, qty: 1 }],
      };



      case actionTypes.REMOVE_FROM_CART:
        return {
          ...state,
          cart: state.cart.filter((item) => {
            if (Array.isArray(item.id)) {
              // For combo products
              console.log(item.id,"delete cart prd")
              console.log(action.payload,"action.payload delete")
              return item.id[0].products_id !== action.payload.id[0].products_id;
              
            } else {
              // For single products
              return item.id.batchid !== action.payload.id.batchid;
            }
          }),
        };
      

    case actionTypes.REFRESH:
      console.log("refresh")
      return {
        ...state,
        refreshitem: state.refreshitem == false ? true : false,
      };

    case actionTypes.ADJUST_ITEM_QTY:
      // console.log("item.id.batchid")
      console.log("action.payload.qty", action.payload.qty)
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id.batchid === action.payload.id
            ? { ...item, qty: +action.payload.qty }
            : item
        ),
      };

    case actionTypes.LOAD_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };

    case FETCH_PRODUCTS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      console.log("ok")
      return {
        ...state,
        products: action.payload.products
      };


    default:
      return state;
  }
};

export default shopReducer;
