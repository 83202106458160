import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import logo from "../../Assets/Images/snap qatar logo  1.png";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../../Components/Loader/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Forgot() {
  let history = useHistory();
  const vertical = "top";
  const horizontal = "center";
  const [email, setEmail] = useState("");

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrormessage] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const onSubmit = () => {
    setLoader(true);
    var data = {
      emailid: email,
    };
    console.log("data", data);
    fetch(global.url + "forgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("response signup", responseJson);
        setLoader(false);
        if (responseJson.error == false) {
          history.push("/login");
          setOpen(true);
          setErrormessage(responseJson.message);
        } else {
          setOpen(true);
          setErrormessage(responseJson.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div
      className="App"
      style={{ fontFamily: "'lato', sans-serif", marginTop: "140px" }}
    >
      <Container fluid>
        <Row>
          <Col xs={12} style={{ marginTop: 100 }}>
            <div className="login-box">
              <div className="box-inside">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  <div
                    className="login-company-logo"
                    //  alt="img" style={{height:'25%',width:'25%'}} src={logo}
                  ></div>
                </div>
                <p className="loginpara" style={{ fontSize: 20 }}>
                  Forgot Password
                </p>
                <div style={{ marginTop: 30 }}>
                  <Form>
                    <Form.Group className="mb-1" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        className="shadow12"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Enter Your Email id"
                        style={{ fontSize: 13 }}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div style={{ marginTop: 50 }}>
                  {loader ? (
                    <Loader />
                  ) : (
                    <Button
                      onClick={onSubmit}
                      variant="primary"
                      style={{
                        boxShadow: "none",
                        border: "none",
                        backgroundColor: "#00a085",
                        width: "100%",
                        fontSize: 14,
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
                <div style={{ marginTop: 10 }}>
                  <a onClick={() => history.push("/login")}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        fontWeight: 500,
                        color: "#00a085",
                        cursor: "pointer",
                      }}
                    >
                      Back to Login
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Forgot;
