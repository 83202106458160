import React, {Component, useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from '../Header/header.js';
import Footer from '../Footer/Footer';
import Sidebar from './sidebar';

const DashboardLayout = ({children, ...rest}) => {
  const [toggle, setToggle]  = useState(false)

  const handleCallback = () => {
    setToggle(!toggle)
  }

    return(
        <div>
          <Sidebar toggle={toggle}/>
            <Header parentCallback = {handleCallback}/>
                <div className='layout' 
                // style={{marginTop: '175px', marginBottom: 50}}
                >{children}</div>
            <Footer />
        </div>
    )
};

const CommonLayoutRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => (
         
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout> 
        )}
      />
    );
  };
export default CommonLayoutRoute;