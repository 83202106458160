import React, { useEffect, useState, useRef } from "react";
import Loader from "../../Components/Loader/Loader";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { FaSortDown } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import Product from "../../Components/NewArrival/product";

function AllPrd() {

    const [allPrd, setAllPrd] = useState([]);
    const [loading, setLoading] = useState(false);
    const containerRef = useRef(null);
    let history = useHistory();

    const deliveryClick = (value) => {
        getArrival(value);

    }

    useEffect(() => {
        getArrival()
    }, [])

    const getArrival = (value) => {
        setLoading(true)
        var data = {
            deliverytime: value
        }
        fetch(global.url + "ProductDelivaryTimeFilter", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                APPKEY: "erp6129",
            },
            body: JSON.stringify(data)

        })
            .then((response) => response.json())
            .then((responseJson) => {
                setLoading(false)
                if (responseJson.error === false) {
                    setAllPrd(responseJson.data);
                    console.log(responseJson.data, "app prd resp");
                } else {
                    setAllPrd([])
                }

            }).catch((error) => {
                console.error(error)
            });
    }

    return (
        <div style={{backgroundColor:'#f3f3f3'}}>

            {loading ? <Loader /> :

                <div>
                    <Container>
                        <div style={{ marginTop: 20 }}>
                            {/* {arrival&&arrival.length == 0 ? null : ( */}
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: 10,
                                }}
                            >
                                {/* <div className='catborder'> */}
                                <p
                                    className="heading"
                                    style={{ fontFamily: "'lato', sans-serif" }}
                                >
                                    NEW ARRIVAL
                                </p>
                                {/* </div> */}
                                {/* <h2 style={{color: '#000', fontSize: 28, fontWeight: 'bold'}}>New Arrival</h2> */}
                                <div
                                    className="dropdown"
                                    style={{ fontFamily: "'lato', sans-serif" }}
                                >
                                    <p
                                        className="dropbtn"
                                        style={{
                                            color: "#000000",
                                            fontSize: 20,
                                            fontWeight: 600,
                                            fontFamily: "'lato', sans-serif",
                                        }}
                                    >
                                        Delivery Time
                                        <FaSortDown style={{ marginTop: "-5px" }} />
                                    </p>
                                    <div className="dropdown-content">
                                        {/* <a value="">Link 1</a> */}

                                        <a onClick={() => deliveryClick(24)}>24 hr delivery</a>
                                        <a onClick={() => deliveryClick(48)}>48 hr delivery</a>
                                        <a className="deliveryclick-font" onClick={() => deliveryClick(0)}>BACK</a>
                                    </div>
                                </div>
                            </div>
                            {/* )
                 } */}

                            <div>
                                {loading ? (
                                    <Row>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                        <Col xs={4} md={3} lg={3}>
                                            <Skeleton
                                                sx={{ height: 140, marginTop: 1 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <Skeleton variant="text" />
                                            <Skeleton variant="text" />
                                        </Col>
                                    </Row>
                                ) : (
                                    // arrival.length <= (limit + 1) ?
                                    allPrd.length > 0 ?

                                        (
                                            <div
                                                ref={containerRef}
                                            // style={{
                                            //   maxHeight: "400px",
                                            //   overflowY: "auto", overflowX: 'hidden'
                                            // }}
                                            >
                                                <Row>
                                                    {
                                                        allPrd && allPrd.map((product) => (
                                                            <Col xs={6} md={2} lg={2} className="spacereduce">
                                                                <Product
                                                                    key={product.id}
                                                                    product={product}
                                                                    className="productimg"
                                                                />
                                                            </Col>
                                                        ))}

                                                </Row>
                                            </div>
                                        ) : (
                                            null
                                        )
                                )}
                            </div>

                        </div>
                    </Container>
                </div>
            }
        </div>
    )
}
export default AllPrd;