import { combineReducers } from "redux";
import shoppingReducer from "./Shopping/shopping-reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["shop"],
};


const rootReducer = combineReducers({
    shop: shoppingReducer,
  });

  export default persistReducer(persistConfig, rootReducer);
