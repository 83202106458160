// import './NewArrival.css';
// import React, {useEffect, useState} from 'react';
// import {Container, Row, Col, Button } from "react-bootstrap";
// import Slider from "react-slick";
// import { slice, concat } from 'lodash';
// import { AiOutlineReload } from "react-icons/ai";
// // import product1 from '../../Assets/Images/products/shoe1.png';
// // import product2 from '../../Assets/Images/products/shoe2.png';
// // import product3 from '../../Assets/Images/products/shoe3.png';
// import {useHistory} from 'react-router-dom';
// import {
//     addToCart,
//   } from "../../redux/Shopping/shopping-actions";
// import { connect } from "react-redux";

// import Product from './product';

// function NewArrival(props){

//     return(
//         <div>
//             <Row>
//                 {props.data.map((product) => (
//                         <Col xs={6} md={3} lg={3} className="spacereduce">
//                             <Product  key={product.id} product={product} className="productimg" />
//                         </Col>
//                     ))}
//             </Row>
//         </div>
//     );
//    }

// export default NewArrival;

import "./NewArrival.css";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { slice, concat } from "lodash";
import { AiOutlineReload } from "react-icons/ai";
// import product1 from '../../Assets/Images/products/shoe1.png';
// import product2 from '../../Assets/Images/products/shoe2.png';
// import product3 from '../../Assets/Images/products/shoe3.png';
import { useHistory } from "react-router-dom";
import { addToCart } from "../../redux/Shopping/shopping-actions";
import { connect } from "react-redux";
import Loader from "../../Components/Loader/Loader";

import Product from "./product";
import InfiniteScroll from "react-infinite-scroll-component";

function NewArrival(props) {
  
  const allPrd = props.data;

  console.log(allPrd, "all prd");

  const limit = props.data.length;
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [displayed, setDisplayed] = useState([]);
  
  useEffect(() => {
    if (props.data) {
      setDisplayed(props.data.slice(0, 8));
    }
  }, [props.data]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      fetchMore();
    }
  };

  const fetchMore = () => {
    setLoading(true);
    setTimeout(() => {
      const nextIndex = Math.min(displayed.length + 8, limit);
      setDisplayed(allPrd.slice(0, nextIndex));
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  console.log(displayed, "arrival data");

  return (

    <div
      ref={containerRef}
      id="scroll"
      style={{ height: "400px", overflowY: "scroll" }}
    >
      <InfiniteScroll
        dataLength={displayed.length}
        next={fetchMore}
        hasMore={displayed.length < limit && !loading}
        scrollableTarget="scroll"
        // loader={<Loader/>}
      >
        <Row>
          {displayed.map((product) => (
            // console.log(product, "55555")
            <Col xs={6} md={3} lg={3} className="spacereduce">
              <Product
                key={product.id}
                product={product}
                className="productimg"
              />
            </Col>
          ))}
          {loading && <Loader />}
        </Row>
      </InfiniteScroll>
    </div>
  );
}

export default NewArrival;
