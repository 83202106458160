import { initializeApp } from 'firebase/app';
import { getAuth  } from 'firebase/auth';

const firebaseConfig  = {
    apiKey: "AIzaSyDt53xQiomrYeYAL6r9RyvQeyze82evDNk",
    authDomain: "fetch-d1606.firebaseapp.com",
    projectId: "fetch-d1606",
    storageBucket: "fetch-d1606.appspot.com",
    messagingSenderId: "885091656775",
    appId: "1:885091656775:web:1a856bfcc88a3b750fbd62"
}

const app = initializeApp(firebaseConfig);
export  const authentication = getAuth(app);


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth  } from 'firebase/auth';
// firebase.auth().settings.appVerificationDisabledForTesting = true;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyD43VpGv9wcEHufVbMXas8S8sjqfOXh8pc",
//   authDomain: "albadar-otp-demo.firebaseapp.com",
//   projectId: "albadar-otp-demo",
//   storageBucket: "albadar-otp-demo.appspot.com",
//   messagingSenderId: "312272609880",
//   appId: "1:312272609880:web:e28acef9121a0b99a9a02d"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export  const authentication = getAuth(app);