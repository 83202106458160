import React, { useState, useEffect } from "react";
import "./header.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FaMobileAlt, FaListAlt } from "react-icons/fa";
import { FiSearch, FiLogIn } from "react-icons/fi";
import { MdDeliveryDining } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { BsArrowReturnRight } from "react-icons/bs";
// import logo from "../../Assets/Images/snap qatar logo  1.png";
import cart from "../../Assets/Images/cart.svg";
import Searchic from "../../Assets/Images/searchic.svg";
import { useHistory, useLocation } from "react-router-dom";
import profile from "../../Assets/Images/user.svg";
import Carticocomponent from "./Carticocomponent";
import Categorymenu from "./categorymenu";
import vector from "../../Assets/Images/Vector 6.svg";
import delivery from "../../Assets/Images/Group 232.svg";
import customer from "../../Assets/Images/Group 250 1 (1).svg";
import mobilemenu from "../../Assets/Images/Group 268.svg";
import mobileuser from "../../Assets/Images/user-circle.svg";
import { LuUserCircle } from "react-icons/lu";

function Header(props) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [userData, setUserData] = useState();
  const [logtoken, setLogtoken] = useState("");
  const [username, setUsername] = useState("");
  const [hidden, setHidden] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const [mobsearch, setMobsearch] = useState(false);

  const [catmenu, setCatmenu] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleSearch = (event) => {
    const searchWord = event.target.value;
    console.log("searchWord", searchWord);
    setWordEntered(searchWord);
    var data = {
      search_product: searchWord,
      limit: 15,
    };
    fetch(global.url + "searchProducts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("search response : ", responseJson);
        if (responseJson.error == false) {
          setFilteredData(responseJson.data);
        } else {
          setFilteredData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const submenuOpen = () => {
    setCatmenu(!catmenu);
  };

  useEffect(() => {
    setCatmenu(false);
  }, [location.pathname]);

  useEffect(() => {
    var logged = JSON.parse(localStorage.getItem("isAuthenticatedfront"));
    var username = JSON.parse(localStorage.getItem("userName"));
    setLogtoken(logged);
    setUsername(username);
    console.log(username, "username");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const listClick = (value) => {
    console.log("Clicked value:", value);
    setFilteredData([]);
    setWordEntered("");
    const productId = value.products_id;
    const pbid = value.pb_id;
    history.push(`/details/${productId}/${pbid}`);
  };

  const onMobileclick = () => {
    props.parentCallback();
  };

  const hideHeader = () => {
    if (window.scrollY > lastScrollY) {
      setHidden(false);
    } else {
      setHidden(true);
    }
    if (window.scrollY > 10) {
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", hideHeader);
    return () => {
      window.removeEventListener("scroll", hideHeader);
    };
  }, [lastScrollY]);

  return (
    <div className={hidden ? "header" : "header hidden"}>
      <div className="header1">
        <Container>
          <Row>
            <Col md={3}>
              <a style={{ display: "flex" }}>
                <FaMobileAlt
                  color="#fad30c"
                  size={16}
                  style={{ marginTop: 3, marginRight: 10 }}
                />
                <p className="header1-para">Download The App Now</p>
              </a>
            </Col>
            <Col md={3}>
              <a style={{ display: "flex" }}>
                <div
                  className="delivery"
                  // src={delivery}
                  // style={{
                  //   marginTop: 1,
                  //   marginRight: 10,
                  //   width: 20,
                  //   height: 20,
                  // }}
                ></div>
                {/* <MdDeliveryDining color="#fad30c" size={22} style={{marginTop: 1, marginRight: 10 }}/> */}
                <p className="header1-para">
                  Free Home Delivery Starting At Above 99 QAR
                </p>
              </a>
            </Col>
            <Col md={3}>
              <button className="premium-btn">premium</button>
            </Col>

            <Col md={3}>
              <a style={{ display: "flex", float: "right" }}>
                <div
                  className="customercare"

                  // src={customer}
                  // style={{
                  //   marginTop: 1,
                  //   marginRight: 10,
                  //   width: 20,
                  //   height: 20,
                  // }}
                ></div>
                {/* <RiCustomerService2Fill color="#fad30c" size={20} style={{marginTop: 2, marginRight: 10}}/> */}
                <p className="header1-para">24/7 Customer Care</p>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="header2">
        <Container>
          <Row>
            <Col xs={5} lg={3} className="logocol">
              <div style={{}}>
                {/* <div>
                                    <div style={{position: 'relative', marginTop: 10, cursor: 'pointer'}} onClick={() => submenuOpen()}>
                                        <a>
                                            <HiMenuAlt1 color="#286bc7" size={28} style={{marginTop: 2, marginRight: 10}}/>
                                        </a>
                                    </div>
                                    <Categorymenu catmenu = {catmenu}/>
                                </div> */}
                <FiMenu
                  onClick={() => onMobileclick()}
                  className="desktop-display"
                  style={{
                    width: 25,
                    height: 25,
                    color: "#501033",
                    marginRight: 8,
                    marginTop: 22,
                    cursor: "pointer",
                  }}
                />

                <a
                  // className="company-logo-div"
                  href="/"
                  style={{ cursor: "pointer" }}
                >
                  <div className="company-logo"></div>
                </a>
              </div>
            </Col>
            <Col
              className="searchbar"
              xs={{ span: 12, order: 3 }}
              lg={{ span: 5, order: 2 }}
            >
              <div style={{ position: "relative" }}>
                <div className="searchdiv">
                  {/* <Form>
                                        <Form.Group className="mb-3 mt-4" >
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                             style={{borderColor: '#501033', fontWeight:500,fontSize:15, fontFamily: "'lato', sans-serif"}} />
                                        </Form.Group>
                                    </Form> */}
                  <input
                    className="mb-3 mt-3"
                    type="text"
                    onChange={handleSearch}
                    value={wordEntered}
                    placeholder="What are you looking for?"
                    style={{
                      borderColor: "#ff9d1c",
                      fontWeight: 400,
                      borderRadius: "15px 15px 15px 0px",
                      fontSize: 17,
                      fontFamily: "'lato', sans-serif",
                      width: "100%",
                      height: "43px",
                      borderWidth: "1px",
                      textIndent: "10px",
                      color: "#000",
                    }}
                  ></input>
                  {filteredData.length === 0 ? (
                    <div
                      style={{
                        backgroundColor: "#f9a719",
                        position: "absolute",
                        top: "16px",
                        right: "0px",
                        padding: "8px",
                        borderRadius: "0px 15px 15px 0px",
                      }}
                    >
                      {" "}
                      <span style={{ textAlign: "center", padding: 5 }}>
                        <FiSearch color="#fff" size={25} />
                      </span>{" "}
                    </div>
                  ) : (
                    <AiOutlineClose
                      onClick={clearInput}
                      color="#000"
                      size={17}
                      style={{ position: "absolute", top: 29, right: 10 }}
                    />
                  )}
                </div>
                {filteredData.length == 0 ? null : (
                  <div className="dataResult">
                    {filteredData.map((value, key) => {
                      return (
                        <a
                          onClick={() => listClick(value)}
                          className="searchItem"
                          target="_blank"
                        >
                          <div style={{ marginTop: 10, display: "flex" }}>
                            <BsArrowReturnRight
                              color="#000"
                              size={14}
                              style={{ marginLeft: 20, marginTop: 2 }}
                            />
                            <p style={{ fontSize: 12, marginLeft: 10 }}>
                              {value.product_name}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#d3d3d3",
                            }}
                          ></div>
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={{ span: 7, order: 2 }} lg={{ span: 4, order: 3 }}>
              <div
                className="responsive-cart"
                style={{ display: "flex", float: "right" }}
              >
                {logtoken ? (
                  <a style={{ cursor: "pointer" }} href="/profile">
                    <div className="profileicon-div">
                      <div
                        //  
                        // src={profile}
                       
                      >
                        <LuUserCircle  className="profileicon" />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a style={{ cursor: "pointer" }} href="/login">
                    <div className="profileicon-div">
                      <div
                        //  
                        // src={profile}
                        
                      ><LuUserCircle className="profileicon" /></div>
                    </div>
                  </a>
                )}
                <div>
                  {logtoken ? (
                    <a
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      className="mobile-display"
                    >
                      <p
                        style={{
                          marginTop: 30,
                          color: "#000",
                          fontFamily: "'lato', sans-serif",
                          fontSize: "20px",
                          fontWeight: 600,
                          textDecoration: "none",
                          paddingLeft: "24px",
                        }}
                      >
                        {username}
                      </p>
                    </a>
                  ) : (
                    <a
                      onClick={() => history.push("/login")}
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      className="mobile-display"
                    >
                      <p
                        className="textfont"
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: "37px",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: 600,
                          paddingLeft: "24px",
                        }}
                      >
                        Sign In/Sign Up
                        <img
                           
                          src={vector}
                          style={{ color: "#000" }}
                        />
                      </p>
                    </a>
                  )}
                </div>
                <Carticocomponent />

                {/* mobile search bar */}
                {/* <div className='desktop-display'>
                                    <a style={{cursor: 'pointer'}} onClick={() => setMobsearch(!mobsearch)}>
                                        <div>
                                            <img   src={Searchic} className="profileicon" />
                                        </div>
                                    </a>
                                </div> */}
              </div>
            </Col>
          </Row>
          {/* <div className={mobsearch ?  'mobsearchdiv1' : 'mobsearchdiv'}>
                            <div className='searchdiv1'>
                                <Form style={{height: '100%'}}>
                                    <Form.Group className="mb-3" style={{height: '100%'}}>
                                        <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                        className="header-textinput" style={{borderColor: '#501033', height: '100%' ,fontWeight:500,fontSize:13, fontFamily: "'lato', sans-serif"}} />
                                    </Form.Group>
                                </Form>
                                
                                    <AiOutlineClose onClick={() => {setMobsearch(!mobsearch) 
                                    setFilteredData([])
                                     setWordEntered("")}} color="#000" size={17} style={{position: 'absolute', top: 16, right: 10, width:20, height:20}}/>
                            </div>
                            {filteredData.length == 0 ? null:
                                <div className="dataResult">
                                {filteredData.map((value, key) => {
                                    return (
                                    <a onClick={() => listClick(value)} className="searchItem" target="_blank">
                                        <div style={{marginTop:10, display: 'flex'}}>
                                            <BsArrowReturnRight color="#000" size={14} style={{marginLeft: 20, marginTop: 2}}/>
                                            <p style={{fontSize: 12, marginLeft: 10}}>{value.product_name}</p>
                                        </div>
                                        <div style={{width: '100%', height: 1, backgroundColor: '#d3d3d3'}}></div>
                                    </a>
                                    );
                                })}
                                </div>
                            }
                        </div> */}
        </Container>
      </div>
      <div className="mobhead">
        <Row>
          <Col xs={1}>
            <div>
              <div
                onClick={() => onMobileclick()}
                className="menu"
                // src={mobilemenu}
              ></div>
            </div>
          </Col>
          <Col xs={7}>
            <a
              href="/"
              style={{ cursor: "pointer" }}
              // className="company-logo-div"
            >
              <div className="company-logo"></div>
            </a>
          </Col>
          <Col xs={1}></Col>
          <Col xs={3}>
            {/* <img src={mobileuser} style={{height:'40%',width:'40%'}}  /> */}

            <div style={{ display: "flex", float: "right" }}>
              {logtoken ? (
                <a style={{ cursor: "pointer" }} href="/profile">
                  <div>
                    <div
                      
                       
                      // src={mobileuser} style={{ width: '30px', height: '30px' }}
                    ><LuUserCircle className="profileicon" /></div>
                  </div>
                </a>
              ) : (
                <a style={{ cursor: "pointer" }} href="/login">
                  <div>
                    <div
                     
                      //   src={mobileuser} style={{ width: '30px', height: '30px' }}
                    ><LuUserCircle  className="profileicon" /></div>
                  </div>
                </a>
              )}
              <div>
                {logtoken ? (
                  <a style={{ cursor: "pointer", textDecoration: "none" }}>
                    <p
                      style={{
                        color: "#000",
                        fontFamily: "'lato', sans-serif",
                        fontSize: "12px",
                        // fontWeight: 600,
                        textDecoration: "none",
                        paddingLeft: "10px",
                        marginTop: "3px",
                      }}
                    >
                      {username}
                    </p>
                  </a>
                ) : (
                  <a
                    onClick={() => history.push("/login")}
                    style={{
                      width: 20,
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    <p
                      className="textfont1"
                      //   style={{
                      //     color: "#000",
                      //     fontFamily: "'lato', sans-serif",
                      //     fontSize: "12px",
                      //     // fontWeight: 600,
                      //     textDecoration: "none",
                      //     paddingLeft: "10px",
                      //   }}
                    >
                      Sign In/Sign Up
                      <img   src={vector} />
                    </p>
                  </a>
                )}
              </div>
              <Carticocomponent />

              {/* mobile search bar */}
              {/* <div className='desktop-display'>
                                    <a style={{cursor: 'pointer'}} onClick={() => setMobsearch(!mobsearch)}>
                                        <div>
                                            <img   src={Searchic} className="profileicon" />
                                        </div>
                                    </a>
                                </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            className="msearchbar"
            xs={{ span: 12, order: 3 }}
            //   lg={{ span: 5, order: 2 }}
          >
            <div style={{ position: "relative" }}>
              <div>
                {/* <Form>
                                        <Form.Group className="mb-3 mt-4" >
                                            <Form.Control type="text" onChange={handleSearch} value={wordEntered} placeholder="What are you looking for?" 
                                             style={{borderColor: '#501033', fontWeight:500,fontSize:15, fontFamily: "'lato', sans-serif"}} />
                                        </Form.Group>
                                    </Form> */}
                <input
                  // className="mb-3 mt-3"
                  type="text"
                  onChange={handleSearch}
                  value={wordEntered}
                  placeholder="search"
                  style={{
                    borderColor: "#f9a719",
                    fontWeight: 400,
                    fontSize: 13,
                    fontFamily: "'lato', sans-serif",
                    width: "100%",
                    height: "35px",
                    borderWidth: "1px",
                    textIndent: "10px",
                    color: "#000",
                  }}
                ></input>
                {filteredData.length === 0 ? (
                  <div
                    style={{
                      backgroundColor: "#f9a719",
                      position: "absolute",
                      top: "0px",
                      right: "0px",
                      padding: "4px",
                    }}
                  >
                    {" "}
                    <span style={{ textAlign: "center", padding: 5 }}>
                      <FiSearch color="#fff" size={25} />
                    </span>{" "}
                  </div>
                ) : (
                  <AiOutlineClose
                    onClick={clearInput}
                    color="#000"
                    size={17}
                    style={{ position: "absolute", top: 29, right: 10 }}
                  />
                )}
              </div>
              {filteredData.length == 0 ? null : (
                <div className="dataResult">
                  {filteredData.map((value, key) => {
                    return (
                      <a
                        onClick={() => listClick(value)}
                        className="searchItem"
                        target="_blank"
                      >
                        <div style={{ marginTop: 10, display: "flex" }}>
                          <BsArrowReturnRight
                            color="#000"
                            size={14}
                            style={{ marginLeft: 20, marginTop: 2 }}
                          />
                          <p style={{ fontSize: 12, marginLeft: 10 }}>
                            {value.product_name}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: 1,
                            backgroundColor: "#d3d3d3",
                          }}
                        ></div>
                      </a>
                    );
                  })}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Header;
