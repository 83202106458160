import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { slice, concat } from "lodash";
// import './Product.css';
import { AiOutlineReload } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import img from "../../Assets/Images/noimages.png";
import combolist from "./combolist";
import Loader from "../../Components/Loader/Loader";
import Button from "@mui/material/Button";
import Combolist from "./combolist";

function Comboproduct(props) {
  let history = useHistory();
  const id = useParams();
  const LENGTH = 30;
  const DATA = [...Array(LENGTH).keys()];
  const LIMIT = 18;

  const [list, setList] = useState(slice(DATA, 0, LIMIT));
  const [index, setIndex] = useState(LIMIT);

  const [source, setSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [input, setInput] = useState(0);
  const [count, setCount] = useState("");

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // autoplay: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // console.log("productlistid", id)
    getCproduct();
  }, [id, input]);

  const getCproduct = (id) => {
    console.log("id", id);
    setLoader(true);
    var data = {
      // "type": id.type,
      // "id": id.id,
      product_id: id,
    };
    fetch(global.url + "viewproductCombo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        APPKEY: "erp6129",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("Productlist response", responseJson);
        setLoader(false);
        if (responseJson.error == false) {
          setSource(responseJson.data);
          setCount(responseJson.count);
        } else {
          setSource([]);
          setCount("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const increment = () => {
    setInput((preCount) => preCount + 1);
  };
  const decrement = () => {
    setInput((preCount) => preCount - 1);
  };

  return (
    <div style={{ fontFamily: "'lato', sans-serif" }}>
      <Container>
        <div className="product-maindiv">
          <div style={{ marginTop: 40 }}>
            <h2
              className="pro-relapro"
              style={{ color: "#000", fontSize: 28, fontWeight: 500 }}
            >
              combo Products
            </h2>
            <div style={{ marginTop: 20 }}>
              {loader ? (
                // <Row>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>
                //     <Col xs={6} md={3} lg={3}>
                //         <Skeleton sx={{ height: 140, marginTop: 1 }} animation="wave" variant="rectangular" />
                //         <Skeleton variant="text" />
                //         <Skeleton variant="text" />
                //     </Col>

                // </Row>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: 50 }}>
                    <Loader />
                  </div>
                </div>
              ) : (
                <Row>
                  {source.map((product, index) =>
                    product.images.length == 0 ? null : (
                      <Col
                        xs={6}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{ marginTop: 10 }}
                        className="spacereduce"
                      >
                        <div>
                          <Combolist key={product.id} product={product} />
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              )}
            </div>
          </div>
          {/* {count <= 30 ? null :
                        <div>
                            {input == 0 ? 
                            <div style={{marginTop: 15}}>
                                <Button onClick={increment} variant='contained' style={{float: 'right', height: 20, fontSize: 12, paddingTop: 10}}>Next</Button>
                            </div> :
                            <div style={{marginTop: 15, display: 'flex', justifyContent: 'space-between'}}>
                                <Button onClick={decrement} variant='light' style={{borderColor: "#000", height: 20, fontSize: 12, paddingTop: 10, backgroundColor: '#e7e7e7'}} >Previous</Button>
                                <Button onClick={increment} variant='contained' style={{ height: 20, fontSize: 12, paddingTop: 10}}>Next</Button>
                            </div>}
                        </div>} */}
        </div>
      </Container>
    </div>
  );
}

export default Comboproduct;
