import React, { useEffect } from 'react';
import done from '../../Assets/gif/order-confirmed.gif';
import {Container, Row, Col, Button, Form } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import logo from '../../Assets/Images/snap qatar logo  1.png';

function Summary() {
    let history = useHistory();
    
    return(
        <div style={{height:'100vh', marginBottom: 30 ,backgroundColor:'#fff'}}>
             <div className="header2" style={{backgroundColor:'#ffffff'}}>
                <Container>
                    <Row>
                        <Col xs={5} lg={3}>
                            <div style={{marginTop:30 ,marginLeft:-30,marginBottom:15}}>
                                <a href="/" style={{cursor: 'pointer'}}>
                                    <div className='company-logo'
                                    // style={{height:'30%',width:'30%',paddingBottom:'5%'}} alt="img" src={logo}  className='mainLogo'
                                    ></div> 
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container style={{backgroundColor:'#ffff'}}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <a href='/'>
                    <img src={done} style={{width: 400, height: 400}} />
                    </a>
                   
                </div>
                {/* <p style={{textAlign: "center", fontSize: 20,  marginTop: 20 ,fontFamily: "'lato', sans-serif", fontWeight: 600}}>Your Order Confirmed !!!</p> */}
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <button onClick={() =>  history.push('/')} type="button" className='backtohome' >Back To Home</button>
                </div>
            </Container>
        </div>
    );
}

export default Summary;