import * as actionTypes from "./shopping-types";

const initialState = {
  cart: [],
};
export const addToCart = (itemID) => {
  console.log(itemID,"itemID shopping actions")
  return {
    type: actionTypes.ADD_TO_CART,
    payload: {
      id: itemID,
    },
  };
};

export const refresh = (itemID) => {
  return {
    type: actionTypes.REFRESH,
    payload: {
      id: itemID,
    },
  };
};

export const removeFromCart = (itemID) => {
  return {
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      id: itemID,
    },
  };
};

export const adjustItemQty = (itemID, qty) => {
  return {
    type: actionTypes.ADJUST_ITEM_QTY,
    payload: {
      id: itemID,
      qty,
    },
  };
};

export const loadCurrentItem = (item) => {
  return {
    type: actionTypes.LOAD_CURRENT_ITEM,
    payload: item,
  };
};

// export function fetchProducts() {
//   return dispatch => {
//     return fetch("https://jsonplaceholder.typicode.com/users")
//       .then(handleErrors)
//       .then(res => res.json())
//       .then(json => {
//         console.log("ss",json)
//         dispatch(fetchProductsSuccess(json));
//         return json;
//       })
//       .catch(error => console.log(error));
//   };
// }

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';


// export const fetchProductsSuccess = products => ({
//   type: FETCH_PRODUCTS_SUCCESS,
//   payload: { products }
// });
