// import React from "react";
// import Slider from "react-slick";
// import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import { useHistory, useParams } from "react-router-dom";
// import Skeleton from "@mui/material/Skeleton";
// import "./category.css";
// import SubCategory from "../subcategory/subcategory";
// import { useState } from "react";

// function Category(props) {
//   let history = useHistory();


//   //    const [subcat,setSubCat] =useState([])

//   //    const getSubcategorylist =()=>{

//   //     var data ={
//   //         "type":"admin",
//   //         "companyid":1
//   //     }
//   //     fetch(global.url+"viewsubacategory",{
//   //         method:"POST",
//   //         headers:{
//   //             'content-type':'application/json'
//   //         },
//   //         body: JSON.stringify(data)
//   //     })
//   //     .then((response)=>response.JSON())
//   //     .then((responseJson)=>{
//   //         if (responseJson.status == "false") {
//   //             setSubCat(responseJson.data)
//   //         }
//   //     })
//   //     .catch((error)=>{
//   //         console.log(error)
//   //     })
//   //    }

//   return (
//     <Row>
//       {
//         props.categoryloading ? (
//           <Row>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 20 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//             <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
//               <div>
//                 <div style={{ display: "flex", justifyContent: "center" }}>
//                   <Skeleton
//                     sx={{ width: 130, height: 120 }}
//                     animation="wave"
//                     variant="circular"
//                   />
//                 </div>
//                 <Skeleton variant="text" />
//               </div>
//             </Col>
//           </Row>
//         ) : Array.isArray(props.subcatlist) && props.subcatlist.length > 0 ? (
//           props.subcatlist.map((item) => (
//             item.category_name === null ? null :
//               <Col xs={3} md={3} lg={2} style={{ marginTop: 25 }}>
//                 <div className="catBox">
//                   <a
//                     onClick={() =>
//                       history.push("/category/" + item.subCategory_category)
//                     }
//                     style={{ cursor: "pointer" }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                       className="cat-imgview"
//                     >
//                       <img
//                         alt="img"
//                         src={
//                           global.images + "/ecommerce_photos/" + item.category_image
//                         }
//                         style={{ width: "80%", height: "100%" }}
//                       />
//                     </div>
//                     <p
//                       style={{
//                         textAlign: "center",
//                         marginTop: 14,
//                         fontSize: 18,
//                         fontWeight: 600,
//                         marginBottom: "unset",
//                         fontFamily: "'lato', sans-serif",
//                         textTransform: "capitalize",
//                       }}
//                     >
//                       {item.category_name}
//                     </p>
//                   </a>
//                 </div>
//               </Col>
//           ))
//         ) : null
//         //     (
//         //     // <p>No subcategories found</p>
//         // )
//       }
//     </Row>
//   );
// }
// export default Category;

import React from "react";
import Slider from "react-slick";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import "./category.css";
import SubCategory from "../subcategory/subcategory";
import { useState } from "react";

function Category(props) {
  let history = useHistory();
  //    const [subcat,setSubCat] =useState([])

  //    const getSubcategorylist =()=>{

  //     var data ={
  //         "type":"admin",
  //         "companyid":1
  //     }
  //     fetch(global.url+"viewsubacategory",{
  //         method:"POST",
  //         headers:{
  //             'content-type':'application/json'
  //         },
  //         body: JSON.stringify(data)
  //     })
  //     .then((response)=>response.JSON())
  //     .then((responseJson)=>{
  //         if (responseJson.status == "false") {
  //             setSubCat(responseJson.data)
  //         }
  //     })
  //     .catch((error)=>{
  //         console.log(error)
  //     })
  //    }

  return (
    <Row>
      {
        props.categoryloading ? (
          <Row>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 20 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col xs={6} md={3} lg={3} style={{ marginTop: 5 }}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Skeleton
                    sx={{ width: 130, height: 120 }}
                    animation="wave"
                    variant="circular"
                  />
                </div>
                <Skeleton variant="text" />
              </div>
            </Col>
          </Row>
        ) : Array.isArray(props.subcatlist) && props.subcatlist.length > 0 ? (
          props.subcatlist.map((item) => (
            item.subCategory_blocked == 0 ? (
              <Col xs={3} md={3} lg={2} style={{ marginTop: 25 }}>
                <div className="catBox">
                  <a
                    onClick={() =>
                      history.push("/category/" + item.subCategory_category)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="cat-imgview"
                    >
                      <img

                        src={
                          global.images +
                          "ecommerce_photos/" +
                          item.category_image
                        }
                        style={{ width: "80%", height: '116px' }}
                      />
                    </div>
                    <div style={{ height: 50 }}>
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: 14,
                          fontSize: 16,
                          fontWeight: 600,
                          marginBottom: "unset",
                          fontFamily: "'lato', sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.category_name}
                      </p>
                    </div>
                  </a>
                </div>
              </Col>
            ) : null

          ))
        ) : null
        //     (
        //     // <p>No subcategories found</p>
        // )
      }
    </Row>
  );
}
export default Category;
