// ComboProducts.js

import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Cproducts from './cproducts';


function ComboProducts(props) {
  const { data } = props;

  return (
    <div>
      <Row>
        {data.map((combo) => (
          <Col key={combo.id} xs={6} md={3} lg={3} className="spacereduce">
        
            <Cproducts  key={combo.id} combo={combo} className="productimg" />

          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ComboProducts;
